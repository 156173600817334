define("ui/models/clusterroletemplatebinding", ["exports", "@rancher/ember-api-store/models/resource", "@rancher/ember-api-store/utils/denormalize", "ui/utils/constants", "ui/mixins/principal-reference"], function (_exports, _resource, _denormalize, _constants, _principalReference) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resource.default.extend(_principalReference.default, {
    type: 'clusterRoleTemplateBinding',
    canEdit: false,
    cluster: (0, _denormalize.reference)('clusterId'),
    roleTemplate: (0, _denormalize.reference)('roleTemplateId'),
    user: (0, _denormalize.reference)('userId', 'user'),
    isCustom: Ember.computed('roleTemplateId', function () {
      return !_constants.default.BASIC_ROLE_TEMPLATE_ROLES.includes(this.roleTemplateId);
    }),
    principalId: Ember.computed('userPrincipalId', 'groupPrincipalId', function () {
      return this.groupPrincipalId || this.userPrincipalId || null;
    }),
    canRemove: Ember.computed('links.remove', 'name', function () {
      return !!Ember.get(this, 'links.remove') && this.name !== 'creator';
    })
  });

  _exports.default = _default;
});