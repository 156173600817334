define("ui/authenticated/cluster/cis/scan/detail/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    modalService: Ember.inject.service('modal'),
    router: Ember.inject.service(),
    scope: Ember.inject.service(),
    growl: Ember.inject.service(),
    intl: Ember.inject.service(),
    securityScanConfig: Ember.inject.service(),
    cisHelpers: Ember.inject.service(),
    tableHeaders: [{
      name: 'collapse',
      width: 40
    }, {
      name: 'state',
      sort: ['sortableState', 'sortableId'],
      translationKey: 'cis.scan.detail.table.state',
      width: 100
    }, {
      name: 'id',
      sort: ['sortableId'],
      translationKey: 'cis.scan.detail.table.number',
      width: 100
    }, {
      name: 'description',
      sort: ['description', 'sortableId'],
      translationKey: 'cis.scan.detail.table.description'
    }],
    sortBy: 'state',
    runningClusterScans: Ember.computed.filterBy('clusterScans', 'isRunning', true),
    actions: {
      async runScan() {
        Ember.get(this, 'scope.currentCluster').send('runCISScan', {
          onRun: () => this.router.replaceWith('authenticated.cluster.cis/scan')
        });
      },

      download() {
        Ember.get(this, 'model.scan').send('download');
      },

      async delete() {
        await this.modalService.toggleModal('confirm-delete', {
          escToClose: true,
          resources: [Ember.get(this, 'model.scan')],
          onDeleteFinished: () => this.router.replaceWith('authenticated.cluster.cis/scan')
        });
      },

      clearSearchText() {
        Ember.set(this, 'searchText', '');
      }

    },
    tests: Ember.computed('model.nodes', 'model.scan.report.results', 'securityScanConfig.skipList', function () {
      const results = Ember.get(this, 'model.scan.report.results');

      if (!results) {
        return [];
      }

      const tests = results.reduce((agg, result) => [...agg, ...result.checks], []);
      return tests.map(test => {
        const state = this.getCheckState(test);
        const nodeNames = Ember.get(this, 'model.scan').getNodeNamesFromNodeType(test.node_type);
        const checkNodes = test.nodes || [];
        const nodes = nodeNames.map(nodeName => {
          const node = Ember.get(this, 'model.nodes').findBy('nodeName', nodeName);
          const nodeId = node ? node.id : null;
          return {
            state: this.getNodeState(test, nodeName, checkNodes),
            nodeId,
            name: nodeName
          };
        });
        return {
          state,
          sortableState: this.getSortableState(state),
          id: test.id,
          sortableId: this.cisHelpers.createSortableId(test.id),
          description: test.description,
          remediation: state === 'Fail' ? test.remediation : null,
          nodes,
          toggleSkip: () => {
            this.toggleSkip(test.id);
          },
          skipList: Ember.get(this, 'securityScanConfig.skipList'),
          _availableActions: []
        };
      });
    }),
    clusterScans: Ember.computed('model.clusterScans.[]', 'scope.currentCluster.id', function () {
      return Ember.get(this, 'model.clusterScans').filterBy('clusterId', Ember.get(this, 'scope.currentCluster.id'));
    }),

    toggleSkip(testId) {
      this.securityScanConfig.validateSecurityScanConfig();
      const isSkipped = Ember.get(this, 'securityScanConfig.skipList').indexOf(testId) !== -1;
      const fn = isSkipped ? this.unskip : this.skip;
      fn.call(this, testId);
    },

    skip(testId) {
      const newSkipList = [...Ember.get(this, 'securityScanConfig.skipList'), testId];
      this.securityScanConfig.editSkipList(newSkipList);
    },

    unskip(testId) {
      const newSkipList = Ember.get(this, 'securityScanConfig.skipList').filter(skippedTestId => skippedTestId !== testId);
      this.securityScanConfig.editSkipList(newSkipList);
    },

    getCheckState(check) {
      switch (check.state) {
        case 'pass':
          return 'Pass';

        case 'skip':
          return 'Skipped';

        case 'notApplicable':
          return 'N/A';

        default:
          return 'Fail';
      }
    },

    getSortableState(state) {
      if (state === 'Fail') {
        return 0;
      }

      if (state === 'Skipped') {
        return 1;
      }

      if (state === 'Pass') {
        return 2;
      }

      return 3;
    },

    /**
     * Per the API.
     * When check state is pass Pass.
     * When check state is fail Fail.
     * When check state is skip Skip.
     * When check state is mixed fail if nodeName is in checkNodes otherwise pass
     */
    getNodeState(check, nodeName, checkNodes) {
      if (check.state === 'pass') {
        return 'Pass';
      }

      if (check.state === 'fail') {
        return 'Fail';
      }

      if (check.state === 'skip') {
        return 'Skipped';
      }

      if (check.state === 'notApplicable') {
        return 'N/A';
      }

      return checkNodes.includes(nodeName) ? 'Fail' : 'Pass';
    }

  });

  _exports.default = _default;
});