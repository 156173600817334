define("ui/authenticated/project/config-maps/detail/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      const all = this.modelFor('authenticated.project.config-maps');
      const configMaps = all.configMaps.findBy('id', params.config_map_id);

      if (configMaps) {
        return configMaps;
      }

      return this.store.find('configMap', params.config_map_id);
    }

  });

  _exports.default = _default;
});