define("ui/components/pod-dots/component", ["exports", "ember-cli-pagination/computed/paged-array", "ui/utils/search-text", "ui/components/pod-dots/template"], function (_exports, _pagedArray, _searchText, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.searchFields = void 0;
  const searchFields = ['displayName', 'id:prefix', 'displayState', 'image', 'displayIp:ip'];
  _exports.searchFields = searchFields;

  var _default = Ember.Component.extend({
    layout: _template.default,
    pod: null,
    pods: null,
    searchText: null,
    groupByPod: false,
    paging: true,
    sort: ['sortStr'],
    searchFields,
    tagName: '',
    page: 1,
    perPage: 120,
    pageCountChanged: Ember.observer('indexFrom', 'filtered.length', function () {
      // Go to the last page if we end up past the last page
      let from = this.indexFrom;
      let last = this.get('filtered.length');
      var perPage = this.perPage;

      if (this.page > 1 && from > last) {
        let page = Math.ceil(last / perPage);
        this.set('page', page);
      }
    }),
    // Ignore the setting because these are tiny dots.
    filtered: Ember.computed('pod', 'pods.[]', 'searchFields', 'searchText', function () {
      let out = [];
      const pod = this.pod;
      const pods = this.pods;

      if (pods) {
        out.pushObjects(pods.slice());
      }

      if (pod) {
        out.pushObject(pod);
      }

      const {
        matches
      } = (0, _searchText.filter)(out, this.searchText, this.searchFields);
      return matches;
    }),
    pagedContent: (0, _pagedArray.default)('filtered', {
      page: Ember.computed.alias('parent.page'),
      perPage: Ember.computed.alias('parent.perPage')
    }),
    indexFrom: Ember.computed('page', 'perPage', function () {
      var current = this.page;
      var perPage = this.perPage;
      return Math.max(0, 1 + perPage * (current - 1));
    }),
    indexTo: Ember.computed('indexFrom', 'perPage', 'filtered.length', function () {
      return Math.min(this.get('filtered.length'), this.indexFrom + this.perPage - 1);
    })
  });

  _exports.default = _default;
});