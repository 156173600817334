define("ui/models/user", ["exports", "@rancher/ember-api-store/utils/denormalize", "@rancher/ember-api-store/models/resource", "identicon.js"], function (_exports, _denormalize, _resource, _identicon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resource.default.extend({
    router: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    access: Ember.inject.service(),
    growl: Ember.inject.service(),
    globalRoleBindings: (0, _denormalize.hasMany)('id', 'globalRoleBinding', 'userId'),
    clusterRoleBindings: (0, _denormalize.hasMany)('id', 'clusterRoleTemplateBinding', 'userId'),
    projectRoleBindings: (0, _denormalize.hasMany)('id', 'projectRoleTemplateBinding', 'userId'),
    combinedState: Ember.computed('enabled', 'state', function () {
      if (this.enabled === false) {
        return 'inactive';
      } else {
        return this.state;
      }
    }),
    displayName: Ember.computed('name', 'username', 'id', function () {
      let name = this.name;

      if (name) {
        return name;
      }

      name = this.username;

      if (name) {
        return name;
      }

      return `(${this.id})`;
    }),
    avatarSrc: Ember.computed('id', function () {
      return `data:image/png;base64,${new _identicon.default(AWS.util.crypto.md5(this.id || 'Unknown', 'hex'), 80, 0.01).toString()}`;
    }),
    hasAdmin: Ember.computed('globalRoleBindings.[]', function () {
      return this.globalRoleBindings.findBy('globalRole.isAdmin', true);
    }),
    hasCustom: Ember.computed('globalRoleBindings.[]', function () {
      return this.globalRoleBindings.findBy('globalRole.isCustom', true);
    }),
    hasUser: Ember.computed('globalRoleBindings.[]', function () {
      return this.globalRoleBindings.findBy('globalRole.isUser', true);
    }),
    hasBase: Ember.computed('globalRoleBindings.[]', function () {
      return this.globalRoleBindings.findBy('globalRole.isBase', true);
    }),
    isMe: Ember.computed('access.principal.id', 'id', function () {
      return Ember.get(this, 'access.principal.id') === this.id;
    }),
    availableActions: Ember.computed('access.providers.[]', 'actionLinks', 'enabled', function () {
      const on = this.enabled !== false;
      const {
        access
      } = this;
      const a = this.actionLinks || {};
      return [{
        label: 'action.refreshAuthProviderAccess.label',
        icon: 'icon icon-refresh',
        action: 'refreshAuthProviderAccess',
        bulkable: false,
        enabled: isRefreshAuthProviderAccessAvailable()
      }, {
        label: 'action.activate',
        icon: 'icon icon-play',
        action: 'activate',
        bulkable: true,
        enabled: !on
      }, {
        label: 'action.deactivate',
        icon: 'icon icon-pause',
        action: 'deactivate',
        bulkable: true,
        enabled: on
      }];

      function isRefreshAuthProviderAccessAvailable() {
        if (on && Ember.get(access, 'providers') && Ember.get(access, 'providers.length') > 1 && a.refreshauthprovideraccess) {
          return true;
        } else {
          return false;
        }
      }
    }),
    actions: {
      deactivate() {
        Ember.run.next(() => {
          Ember.set(this, 'enabled', false);
          this.save().catch(err => {
            Ember.set(this, 'enabled', true);
            this.growl.fromError('Error deactivating user', err);
          });
        });
      },

      activate() {
        Ember.run.next(() => {
          Ember.set(this, 'enabled', true);
          this.save().catch(err => {
            Ember.set(this, 'enabled', false);
            this.growl.fromError('Error activating user', err);
          });
        });
      },

      edit() {
        this.router.transitionTo('global-admin.security.accounts.edit', this.id);
      },

      setPassword(password) {
        this.doAction('setpassword', {
          newPassword: password
        });
      },

      refreshAuthProviderAccess() {
        this.doAction('refreshauthprovideraccess').then(() => {
          const successTitle = this.intl.t('action.refreshAuthProviderAccess.success.title');
          const successMessage = this.intl.t('action.refreshAuthProviderAccess.success.message');
          this.growl.success(successTitle, successMessage);
        }).catch(err => {
          this.growl.fromError('Error refreshing user user auth tokens', err);
        });
      }

    }
  });

  _exports.default = _default;
});