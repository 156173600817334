define("ui/authenticated/project/route", ["exports", "ui/mixins/preload", "ui/utils/constants"], function (_exports, _preload, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const VALID_ROUTES = ['apps-tab', 'catalog-tab.index', 'authenticated.project.security.members.index', 'authenticated.project.ns', 'authenticated.project.certificates', 'authenticated.project.secrets', 'authenticated.project.config-maps', 'authenticated.project.registries', 'authenticated.project.alert', 'authenticated.project.logging', 'authenticated.project.pipeline.settings', 'authenticated.project.monitoring.project-setting', 'authenticated.project.istio.project-istio.graph', 'authenticated.project.istio.project-istio.metrics', 'authenticated.project.istio.project-istio.rules', 'authenticated.project.istio.project-istio.destination-rules', 'authenticated.project.istio.project-istio.virtual-services', 'authenticated.project.istio.project-istio.gateways', 'authenticated.project.hpa', 'authenticated.project.pipeline.pipelines'];

  var _default = Ember.Route.extend(_preload.default, {
    access: Ember.inject.service(),
    scope: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    settings: Ember.inject.service(),
    shortcuts: {
      'g': 'toggleGrouping'
    },

    model(params, transition) {
      const isPopup = this.controllerFor('application').get('isPopup');
      return this.globalStore.find('project', params.project_id).then(project => {
        const hideLocalCluster = Ember.get(this.settings, 'shouldHideLocalCluster');

        if (hideLocalCluster && Ember.get(project, 'clusterId') === 'local') {
          return this.replaceWith('authenticated');
        }

        return this.scope.startSwitchToProject(project, !isPopup).then(() => Ember.RSVP.all([this.loadSchemas('clusterStore'), this.loadSchemas('store')]).then(() => {
          const out = Ember.Object.create({
            project
          });

          if (isPopup) {
            return out;
          } else {
            return Ember.RSVP.all([this.preload('namespace', 'clusterStore'), this.preload('storageClass', 'clusterStore'), this.preload('persistentVolume', 'clusterStore'), this.preload('pod'), this.preload('workload'), this.preload('secret'), this.preload('service'), this.preload('configmap'), this.preload('namespacedSecret'), this.preload('persistentVolumeClaim')]).then(() => out);
          }
        }));
      }).catch(err => this.loadingError(err, transition));
    },

    afterModel(model) {
      return this.scope.finishSwitchToProject(Ember.get(model, 'project'));
    },

    redirect(model, transition) {
      let route = this.get(`session.${_constants.default.SESSION.PROJECT_ROUTE}`);

      if (Ember.get(transition, 'targetName') === 'authenticated.project.index' && VALID_ROUTES.includes(route)) {
        this.replaceWith(route);
      }
    },

    actions: {
      toggleGrouping() {
        let choices = ['none', 'node', 'workload', 'namespace'];
        let cur = this.get('controller.group');
        let neu = choices[(choices.indexOf(cur) + 1) % choices.length];
        Ember.run.next(() => {
          this.set('controller.group', neu);
        });
      },

      importYaml() {
        this.modalService.toggleModal('modal-import', {
          escToClose: true,
          mode: 'project',
          projectId: Ember.get(this, 'scope.currentProject.id')
        });
      }

    }
  });

  _exports.default = _default;
});