define("ui/ingress/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      const store = this.store;
      return Ember.RSVP.hash({
        ingress: store.find('ingress', params.ingress_id),
        certificates: store.findAll('certificate'),
        namespacedCertificates: store.findAll('namespacedcertificate')
      });
    }

  });

  _exports.default = _default;
});