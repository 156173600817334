define("ui/authenticated/cluster/backups/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    scope: Ember.inject.service(),
    sortBy: 'created',
    currentClusterId: null,
    headers: [{
      name: 'state',
      sort: ['sortState', 'displayName'],
      searchField: 'displayState',
      translationKey: 'generic.state',
      width: 120
    }, {
      name: 'target',
      translationKey: 'backupsPage.table.target.label'
    }, {
      name: 'name',
      sort: ['name', 'id'],
      translationKey: 'backupsPage.table.name'
    }, {
      classNames: 'text-right pr-20',
      name: 'created',
      sort: ['created', 'name', 'id'],
      searchField: false,
      translationKey: 'backupsPage.table.created'
    }],
    actions: {
      snapshotNow() {
        Ember.get(this, 'scope.currentCluster').doAction('backupEtcd');
      }

    },
    rows: Ember.computed('model.[]', function () {
      let {
        currentClusterId
      } = this;
      return this.model.filterBy('clusterId', currentClusterId);
    })
  });

  _exports.default = _default;
});