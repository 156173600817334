define("ui/components/node-group/component", ["exports", "ui/components/node-group/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    scope: Ember.inject.service(),
    layout: _template.default,
    fullColspan: null,
    afterName: 0,
    showState: false,
    afterState: 0,
    alignState: 'text-center',
    showActions: true,
    nodes: null,
    nodeId: null,
    tagName: '',

    didReceiveAttrs() {
      const nodes = this.nodes;
      const nodeId = this.nodeId;

      if (nodes && nodeId) {
        const clusterId = Ember.get(this, 'scope.currentCluster.id');
        const targetNode = nodes.find(n => n.id === nodeId && n.clusterId === clusterId);
        Ember.set(this, 'model', targetNode);
      }
    }

  });

  _exports.default = _default;
});