define("ui/authenticated/cluster/edit/route", ["exports", "shared/utils/load-script"], function (_exports, _loadScript) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    access: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    clusterStore: Ember.inject.service(),
    releaseVersions: Ember.inject.service(),
    clusterTemplateService: Ember.inject.service('clusterTemplates'),
    roleTemplateService: Ember.inject.service('roleTemplate'),

    model() {
      const globalStore = this.globalStore;
      const cluster = this.modelFor('authenticated.cluster');
      let modelOut = {
        originalCluster: cluster,
        cluster: cluster.clone(),
        cloudCredentials: globalStore.findAll('cloudcredential'),
        kontainerDrivers: globalStore.findAll('kontainerDriver'),
        nodeTemplates: globalStore.findAll('nodeTemplate'),
        nodeDrivers: globalStore.findAll('nodeDriver'),
        psacs: globalStore.findAll('podSecurityAdmissionConfigurationTemplate'),
        roleTemplates: this.roleTemplateService.get('allFilteredRoleTemplates'),
        users: globalStore.findAll('user'),
        clusterRoleTemplateBinding: globalStore.findAll('clusterRoleTemplateBinding'),
        me: Ember.get(this, 'access.principal')
      };

      if (cluster.driver === 'k3s' || cluster.driver === 'rke2') {
        this.releaseVersions.getAllVersions(cluster.driver);
      }

      if (!Ember.isEmpty(cluster.clusterTemplateRevisionId)) {
        Ember.setProperties(modelOut, {
          clusterTemplateRevisions: globalStore.findAll('clustertemplaterevision'),
          clusterTemplates: globalStore.findAll('clustertemplate')
        });
      }

      return Ember.RSVP.hash(modelOut);
    },

    afterModel(model, transition) {
      let {
        clusterTemplateRevisions = null,
        cluster
      } = model;
      let {
        clusterTemplateRevisionId
      } = cluster;

      if (clusterTemplateRevisionId) {
        if (clusterTemplateRevisions) {
          let ctr = null;
          const {
            queryParams
          } = transition.to;

          if (queryParams && queryParams.clusterTemplateRevision) {
            clusterTemplateRevisionId = queryParams.clusterTemplateRevision;
            Ember.set(cluster, 'clusterTemplateRevisionId', clusterTemplateRevisionId);
          }

          ctr = clusterTemplateRevisions.findBy('id', clusterTemplateRevisionId);

          if (ctr) {
            Ember.set(model, 'clusterTemplateRevision', ctr); // This is breaking fields that already have values that don't match the template, like kubernetesVersion with 1.14.x
            // this.clusterTemplateService.cloneAndPopulateClusterConfig(cluster, ctr);
          } else {
            // user does not have access to the template that was used to launch a cluster
            // create a fake cluster that we'll use to turn into a "temaplate Revision" to be passed down to components
            // I am using JSON flow here to drop any of the dynamic UI only fields so we dont risk cross contamination with observables and the like.
            let tempCluster = JSON.parse(JSON.stringify(cluster.cloneForNew()));
            Ember.set(tempCluster, 'type', 'clusterSpec');
            let tempSpec = this.globalStore.createRecord(tempCluster); // a template revision has a "cluster config" that should be set which we'll fake with a parsed spec

            Ember.setProperties(tempCluster, {
              type: 'clusterTemplateRevision',
              clusterConfig: JSON.parse(JSON.stringify(tempSpec))
            });
            let tempRevision = this.globalStore.createRecord(tempCluster);
            Ember.set(model, 'clusterTemplateRevision', tempRevision);
          }
        }
      } // load the css/js url here, if the url loads fail we should error the driver out
      // show the driver in the ui, greyed out, and possibly add error text "can not load comonent from url [put url here]"


      let {
        kontainerDrivers
      } = model;
      let externalDrivers = kontainerDrivers.filter(d => d.uiUrl !== '' && d.state === 'active' && d.name.includes(model.cluster.clusterProvider));
      let promises = {};
      externalDrivers.forEach(d => {
        if (Ember.get(d, 'hasUi')) {
          const jsUrl = (0, _loadScript.proxifyUrl)(d.uiUrl, this.get('app.proxyEndpoint'));
          const cssUrl = (0, _loadScript.proxifyUrl)(d.uiUrl.replace(/\.js$/, '.css'), Ember.get(this, 'app.proxyEndpoint')); // skip setProperties cause of weird names

          Ember.set(promises, `${d.name}Js`, (0, _loadScript.loadScript)(jsUrl, `driver-ui-js-${d.name}`));
          Ember.set(promises, `${d.name}Css`, (0, _loadScript.loadStylesheet)(cssUrl, `driver-ui-css-${d.name}`));
        }
      });

      if (Ember.isEmpty(promises)) {
        return model;
      } else {
        return Ember.RSVP.hashSettled(promises).then(settled => {
          let allkeys = Object.keys(settled);
          allkeys.forEach(key => {
            if (Ember.get(settled, `${key}.state`) === 'rejected') {
              let tmp = key.indexOf('Js') > -1 ? key.replace(/\Js$/, '') : key.replace(/\Css$/, '');
              let match = kontainerDrivers.findBy('id', tmp);
              console.log('Error Loading External Component for: ', match);

              if (match && Ember.get(match, 'scriptError') !== true) {
                Ember.set(match, 'scriptError', this.intl.t('clusterNew.externalError'));
              }
            }
          });
        }).finally(() => {
          return model;
        });
      }
    },

    setupController(controller
    /* , model*/
    ) {
      this._super(...arguments);

      Ember.set(controller, 'step', 1);
    },

    resetController(controller, isExiting
    /* , transition*/
    ) {
      if (isExiting) {
        controller.set('errors', null);
        controller.set('provider', null);
      }
    },

    activate() {
      this._super(...arguments);

      Ember.run.scheduleOnce('afterRender', this, 'activateModel');
    },

    actions: {
      willTransition() {
        Ember.set(this, 'controller.scrollTo', null);
      }

    },
    queryParams: {
      provider: {
        refreshModel: true
      },
      clusterTemplateRevision: {
        refreshModel: true
      }
    },

    activateModel() {
      Ember.set(this, 'controller.model.activated', true);
    }

  });

  _exports.default = _default;
});