define("ui/templates/-add-cluster", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MzmDjOT3",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col span-6\"],[12],[2,\"\\n      \"],[10,\"label\"],[12],[1,[30,[36,0],[\"clustersPage.addPage.host.clusterName\"],null]],[8,\"field-required\",[],[[],[]],null],[13],[2,\"\\n      \"],[8,\"input\",[],[[\"@type\",\"@value\"],[\"text\",[34,1,[\"user\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col span-6\"],[12],[2,\"\\n      \"],[10,\"label\"],[12],[1,[30,[36,0],[\"clustersPage.addPage.host.hostName\"],null]],[8,\"field-required\",[],[[],[]],null],[13],[2,\"\\n      \"],[8,\"input\",[],[[\"@type\",\"@value\"],[\"text\",[34,1,[\"advertisedHostname\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"pt-10 pb-15\"],[12],[2,\"\\n    \"],[10,\"label\"],[12],[1,[30,[36,0],[\"clustersPage.addPage.host.ssh\"],null]],[8,\"field-required\",[],[[],[]],null],[13],[2,\"\\n    \"],[8,\"textarea\",[],[[\"@value\",\"@classNames\",\"@rows\"],[[34,1,[\"ssh\"]],\"form-control no-resize\",\"6\"]],null],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"newHost\"]}",
    "moduleName": "ui/templates/-add-cluster.hbs"
  });

  _exports.default = _default;
});