define("ui/components/cru-dns/component", ["exports", "shared/mixins/view-new-edit", "ui/components/cru-dns/template", "ui/utils/errors", "ui/models/service", "shared/mixins/child-hook"], function (_exports, _viewNewEdit, _template, _errors, _service, _childHook) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const LOAD_BALANCER = 'LoadBalancer';
  const NODE_PORT = 'NodePort';
  const EXTERNAL_NAME = 'ExternalName';
  const CLUSTER_IP = 'ClusterIP';
  const HEADLESS = 'Headless';
  const KIND_CHOICES = [{
    label: 'editDns.kind.headless',
    value: HEADLESS
  }, {
    label: 'editDns.kind.clusterIP',
    value: CLUSTER_IP
  }, {
    label: 'editDns.kind.loadBalancer',
    value: LOAD_BALANCER
  }, {
    label: 'editDns.kind.nodePort',
    value: NODE_PORT
  }];

  var _default = Ember.Component.extend(_viewNewEdit.default, _childHook.default, {
    intl: Ember.inject.service(),
    capabilities: Ember.inject.service(),
    layout: _template.default,
    model: null,
    recordType: null,
    timeoutSeconds: null,
    kindChoices: null,
    namespace: Ember.computed.alias('model.namespace'),

    init() {
      this._super(...arguments);

      let type = Ember.get(this, 'model.recordType') || _service.ARECORD;

      Ember.setProperties(this, {
        recordType: type
      });

      if (Ember.get(this, 'model.sessionAffinityConfig.clientIP.timeoutSeconds')) {
        Ember.set(this, 'timeoutSeconds', Ember.get(this, 'model.sessionAffinityConfig.clientIP.timeoutSeconds'));
      }

      this.initKindChoices();
    },

    actions: {
      setAlias(ids) {
        Ember.set(this, 'model.targetDnsRecordIds', ids);
      },

      setWorkload(ids) {
        Ember.set(this, 'model.targetWorkloadIds', ids);
      },

      setSelector(map) {
        Ember.set(this, 'model.selector', map);
      },

      setLabels(labels) {
        let out = {};
        labels.forEach(row => {
          out[row.key] = row.value;
        });
        Ember.set(this, 'model.labels', out);
      }

    },
    timeoutSecondsDidChange: Ember.observer('timeoutSeconds', function () {
      const timeoutSeconds = this.timeoutSeconds;

      if (!Ember.get(this, 'model.sessionAffinityConfig.clientIP.timeoutSeconds')) {
        Ember.set(this, 'model.sessionAffinityConfig', {
          clientIP: {
            timeoutSeconds
          }
        });
      } else {
        Ember.set(this, 'model.sessionAffinityConfig.clientIP.timeoutSeconds', timeoutSeconds);
      }
    }),
    kindDidChange: Ember.observer('kind', function () {
      let kind = this.kind;

      if (kind === HEADLESS) {
        kind = CLUSTER_IP;
        Ember.set(this, 'model.clusterIp', 'None');
      } else if (this.mode === 'new') {
        Ember.set(this, 'model.clusterIp', '');
      }

      if (kind === LOAD_BALANCER || kind === NODE_PORT) {
        Ember.set(this, 'model.externalTrafficPolicy', 'Cluster');
      } else {
        Ember.set(this, 'model.externalTrafficPolicy', null);
      }

      Ember.set(this, 'model.kind', kind);
    }),
    namespaceDidChange: Ember.observer('namespace.id', function () {
      if (this.recordType === 'workload') {
        if (Ember.get(this, 'model.targetWorkloads').some(target => target.namespaceId !== Ember.get(this, 'namespace.id'))) {
          Ember.setProperties(this, {
            'model.targetWorkloadIds': null,
            recordType: null
          });
          Ember.run.next(() => {
            Ember.set(this, 'recordType', 'workload');
          });
        }
      }
    }),
    recordTypeDidChange: Ember.observer('recordType', function () {
      const recordType = this.recordType;

      if (recordType === _service.CNAME) {
        Ember.set(this, 'kind', EXTERNAL_NAME);
      } else {
        Ember.set(this, 'kind', HEADLESS);
      }
    }),
    showSessionAffinity: Ember.computed('isHeadless', 'kind', 'showMoreOptions', function () {
      return this.showMoreOptions && this.kind !== HEADLESS;
    }),
    showMoreOptions: Ember.computed('recordType', 'kind', function () {
      return _service.CNAME !== this.recordType;
    }),
    isHeadless: Ember.computed('kind', function () {
      return this.kind === HEADLESS;
    }),

    /*
    targetServicesAsMaps: null,
    targetIpArray: null,
    stack: null,
    stackErrors: null,
    */
    workloadsChoices: Ember.computed('namespace.id', 'workloads.[]', function () {
      const namespaceId = Ember.get(this, 'namespace.id');
      return (this.workloads || []).filter(w => Ember.get(w, 'namespaceId') === namespaceId);
    }),

    initKindChoices() {
      const loadBalancerCapabilites = Ember.get(this, 'capabilities.loadBalancerCapabilites');

      if (Ember.get(this, 'model.kind') === CLUSTER_IP && Ember.get(this, 'model.clusterIp') === null) {
        Ember.set(this, 'kind', HEADLESS);
      } else if (Ember.get(this, 'model.kind')) {
        Ember.set(this, 'kind', Ember.get(this, 'model.kind'));
      }

      Ember.set(this, 'kindChoices', KIND_CHOICES.map(k => {
        let disabled = false;

        if (!loadBalancerCapabilites.l4LoadBalancerEnabled && Ember.get(k, 'value') === 'LoadBalancer') {
          disabled = true;
        }

        let out = {
          label: Ember.get(k, 'label'),
          value: Ember.get(k, 'value'),
          disabled
        };
        return out;
      }));
    },

    willSave() {
      this.model.clearTypesExcept(this.recordType);

      if (this.mode === 'edit' && this.recordType === _service.WORKLOAD) {
        delete this.model[_service.SELECTOR];
      }

      const ports = this.primaryResource.ports || [];

      if (this.primaryResource.kind !== LOAD_BALANCER && this.primaryResource.kind !== NODE_PORT) {
        ports.forEach(port => delete port['nodePort']);
      }

      Ember.set(this, 'model.namespaceId', Ember.get(this, 'namespace.id') || '__placeholder__');
      const self = this;
      const sup = this._super;
      const errors = [];
      errors.pushObjects(this.namespaceErrors || []);
      Ember.set(this, 'errors', errors);

      if (Ember.get(errors, 'length') !== 0) {
        return false;
      }

      return this.applyHooks('_beforeSaveHooks').then(() => {
        Ember.set(this, 'model.namespaceId', Ember.get(this, 'namespace.id'));
        return sup.apply(self, ...arguments);
      }).catch(err => {
        Ember.set(this, 'errors', [_errors.default.stringify(err)]);
      });
    },

    validate() {
      const errors = this.errors || [];
      const intl = this.intl;
      const aliasTargets = Ember.get(this, 'model.targetDnsRecords') || [];
      const aliases = aliasTargets.length;
      const aliasesToCname = aliasTargets.filterBy('recordType', _service.CNAME).length;
      const selectorKeys = Object.keys(Ember.get(this, 'model.selector') || {}).length;
      const workloads = (Ember.get(this, 'model.targetWorkloads') || []).length;

      switch (this.recordType) {
        case _service.ARECORD:
          if (!(Ember.get(this, 'model.ipAddresses') || []).any(ip => ip)) {
            errors.pushObject(intl.t('editDns.errors.targetRequired'));
          }

          break;

        case _service.CNAME:
          if (!Ember.get(this, 'model.hostname')) {
            errors.pushObject(intl.t('editDns.errors.targetRequired'));
          }

          break;

        case _service.ALIAS:
          if (aliases < 1) {
            errors.pushObject(intl.t('editDns.errors.targetRequired'));
          }

          if (aliasesToCname > 1) {
            errors.pushObject(intl.t('editDns.errors.multipleCname'));
          }

          if (aliasesToCname >= 1 && aliases > aliasesToCname) {
            errors.pushObject(intl.t('editDns.errors.mixedAlias'));
          }

          break;

        case _service.WORKLOAD:
          if (workloads < 1) {
            errors.pushObject(intl.t('editDns.errors.targetRequired'));
          }

          break;

        case _service.SELECTOR:
          if (selectorKeys < 1) {
            errors.pushObject(intl.t('editDns.errors.selectorRequired'));
          }

          break;
      }

      Ember.set(this, 'errors', errors);
      return errors.length === 0;
    }

  });

  _exports.default = _default;
});