define("ui/components/container/form-sources/component", ["exports", "ui/components/container/form-sources/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service('store'),
    layout: _template.default,
    sources: null,
    statusClass: null,
    fetching: false,
    editing: true,
    headers: [{
      name: 'source',
      sort: ['source'],
      translationKey: 'formSources.type.label',
      searchField: 'source'
    }, {
      name: 'sourceName',
      sort: ['sourceName', 'source'],
      searchField: 'sourceName',
      translationKey: 'formSources.source.label'
    }, {
      name: 'sourceKey',
      sort: ['sourceKey', 'sourceName', 'source'],
      searchField: 'sourceKey',
      translationKey: 'formSources.prefixOrKey.label'
    }, {
      name: 'targetKey',
      sort: ['targetKey', 'sourceKey', 'sourceName', 'source'],
      searchField: 'targetKey',
      translationKey: 'formSources.prefix.label'
    }],

    init() {
      this._super(...arguments);

      if (!this.sources) {
        Ember.set(this, 'sources', []);
      }

      this.sources.forEach(source => {
        if (source.sourceKey === undefined) {
          Ember.set(source, 'sourceKey', null);
        }
      });
    },

    actions: {
      addSource() {
        let source = {
          source: 'secret',
          sourceKey: null
        };
        this.sources.addObject(source);
      },

      removeSource(source) {
        this.sources.removeObject(source);
      }

    }
  });

  _exports.default = _default;
});