define("ui/components/modal-rollback-app/component", ["exports", "shared/mixins/modal-base", "ui/components/modal-rollback-app/template", "jsondiffpatch", "moment"], function (_exports, _modalBase, _template, _jsondiffpatch, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const HIDDEN_FIELDS = ['digest'];

  function sanitize(config) {
    HIDDEN_FIELDS.forEach(key => {
      if (config.hasOwnProperty(key)) {
        delete config[key];
      }
    });
    return config;
  }

  var _default = Ember.Component.extend(_modalBase.default, {
    layout: _template.default,
    classNames: ['large-modal'],
    loading: true,
    forceUpgrade: false,
    revisions: null,
    revisionId: null,

    didReceiveAttrs() {
      let model = Ember.get(this, 'modalService.modalOpts.originalModel').clone();
      Ember.set(this, 'model', model);
      this.store.rawRequest({
        url: Ember.get(model, 'links.revision'),
        method: 'GET'
      }).then(revs => {
        Ember.set(this, 'revisions', revs.body.data);
      }).catch(err => {
        this.send('cancel');
        this.growl.fromError(err);
      }).finally(() => {
        Ember.set(this, 'loading', false);
      });
    },

    actions: {
      save(cb) {
        const {
          forceUpgrade,
          revisionId
        } = this;
        this.model.doAction('rollback', {
          revisionId,
          forceUpgrade
        }).then(() => {
          this.send('cancel');
        }).finally(() => {
          cb();
        });
      }

    },
    choices: Ember.computed('revisions.[]', function () {
      return (this.revisions || []).sortBy('created').reverse().map(r => {
        let time = (0, _moment.default)(Ember.get(r, 'created'));
        return {
          label: `${Ember.get(r, 'name')}: ${time.format('YYYY-MM-DD HH:mm:ss')} (${time.fromNow()})`,
          value: Ember.get(r, 'name'),
          data: r
        };
      });
    }),
    current: Ember.computed('choices.[]', 'choices.firstObject.data', function () {
      return Ember.get(this, 'choices.firstObject.data');
    }),
    selected: Ember.computed('revisionId', 'revisions.[]', function () {
      return this.revisions.findBy('name', this.revisionId);
    }),
    diff: Ember.computed('current.status', 'selected.status', function () {
      if (this.current && this.selected) {
        let left = Ember.get(this, 'current.status');
        let right = Ember.get(this, 'selected.status');

        var delta = _jsondiffpatch.default.diff(sanitize(left), sanitize(right));

        _jsondiffpatch.default.formatters.html.hideUnchanged();

        return _jsondiffpatch.default.formatters.html.format(delta, left).htmlSafe();
      }

      return null;
    })
  });

  _exports.default = _default;
});