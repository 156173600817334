define("ui/authenticated/cluster/projects/new/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    scope: Ember.inject.service(),
    access: Ember.inject.service(),
    roleTemplateService: Ember.inject.service('roleTemplate'),

    model() {
      const store = this.globalStore;
      const cluster = this.modelFor('authenticated.cluster');
      const project = store.createRecord({
        type: 'project',
        name: '',
        clusterId: Ember.get(cluster, 'id')
      });
      return Ember.RSVP.hash({
        me: Ember.get(this, 'access.principal'),
        project,
        projects: store.findAll('project'),
        roles: this.roleTemplateService.get('allFilteredRoleTemplates'),
        users: store.find('user', null, {
          forceReload: true
        })
      });
    }

  });

  _exports.default = _default;
});