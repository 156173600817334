define("ui/authenticated/cluster/projects/index/route", ["exports", "ui/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    clusterStore: Ember.inject.service(),
    scope: Ember.inject.service(),
    shortcuts: {
      'g': 'toggleGrouping'
    },

    model() {
      let cluster = this.modelFor('authenticated.cluster');

      if (!Ember.get(cluster, 'isReady')) {
        this.transitionTo('authenticated.cluster.index');
      }

      return Ember.RSVP.hash({
        projects: this.globalStore.findAll('project'),
        namespaces: this.clusterStore.findAll('namespace')
      });
    },

    actions: {
      toggleGrouping() {
        let choices = ['none', 'project'];
        let cur = this.get('controller.group');
        let neu = choices[(choices.indexOf(cur) + 1) % choices.length];
        Ember.run.next(() => {
          this.set('controller.group', neu);
        });
      }

    },
    setDefaultRoute: Ember.on('activate', function () {
      Ember.set(this, `session.${_constants.default.SESSION.CLUSTER_ROUTE}`, 'authenticated.cluster.projects');
    })
  });

  _exports.default = _default;
});