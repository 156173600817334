define("ui/update-password/controller", ["exports", "ui/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    access: Ember.inject.service(),
    settings: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    prefs: Ember.inject.service(),
    queryParams: ['first'],
    showCurrent: null,
    agreedToEula: false,
    landing: null,

    init() {
      this._super(...arguments);

      Ember.set(this, 'showCurrent', !Ember.get(this, 'access.userCode.password'));
      Ember.set(this, 'landing', Ember.get(this, `setting.${_constants.default.SETTING.UI_DEFAULT_LANDING}`));
      Ember.set(this, 'landing', 'ember');
    },

    actions: {
      setView(which) {
        Ember.set(this, 'landing', which);
      }

    },
    firstLogin: Ember.computed('first', 'access.firstLogin', function () {
      if (this.first !== undefined) {
        return true;
      }

      return Ember.get(this, 'access.firstLogin');
    }),
    currentPassword: Ember.computed('', 'access.userCode.password', 'firstLogin', function () {
      return Ember.get(this, 'access.userCode.password') || (this.firstLogin ? 'admin' : null);
    }),

    complete(success) {
      const landing = this.landing;
      let router = this.router;

      if (success) {
        if (this.firstLogin) {
          const value = Ember.get(this, 'model.optIn') ? 'in' : 'out';
          this.settings.set(_constants.default.SETTING.TELEMETRY, value);
          this.settings.set(_constants.default.SETTING.EULA_AGREED, new Date().toISOString());
          this.settings.set(_constants.default.SETTING.UI_DEFAULT_LANDING, landing);
          this.prefs.set(_constants.default.PREFS.LANDING, landing);
        }

        this.access.set('firstLogin', false);
        this.access.set('userCode', null);

        if (landing === 'vue') {
          let link = '/dashboard';

          if (Ember.get(this, 'app.environment') === 'development') {
            link = 'https://localhost:8005/';
          }

          window.location.href = link;
          return;
        }

        router.replaceWith('authenticated');
      }
    }

  });

  _exports.default = _default;
});