define("ui/models/pipelineexecution", ["exports", "@rancher/ember-api-store/models/resource", "shared/utils/pipeline-constants", "@rancher/ember-api-store/utils/denormalize"], function (_exports, _resource, _pipelineConstants, _denormalize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  let PipelineExecution = _resource.default.extend({
    router: Ember.inject.service(),
    type: 'pipelineexecution',
    pipeline: (0, _denormalize.reference)('pipelineId', 'pipeline'),
    relevantState: Ember.computed.alias('executionState'),
    availableActions: Ember.computed('actionLinks.{rerun,stop}', function () {
      const a = this.actionLinks || {};
      return [{
        label: 'action.rerun',
        icon: 'icon icon-refresh',
        action: 'rerun',
        enabled: !!a.rerun,
        bulkable: true
      }, {
        label: 'action.stop',
        icon: 'icon icon-stop',
        action: 'stop',
        enabled: !!a.stop,
        bulkable: true
      }, {
        divider: true
      }];
    }),
    amount: Ember.computed('pipelineConfig.stages.[]', function () {
      const activity_stages = Ember.get(this, 'pipelineConfig.stages');
      let countStage = 0;
      let countStep = 0;

      for (let i = 0; i < activity_stages.length; i++) {
        const stage = activity_stages[i];
        countStage++;

        for (let j = 0; j < stage.steps.length; j++) {
          stage.steps[j];
          countStep++;
        }
      }

      return {
        countStage,
        countStep
      };
    }),
    shortCommit: Ember.computed('commit', function () {
      const commit = this.commit;

      if (commit) {
        return commit.substr(0, 8);
      }

      return '';
    }),
    startedTimeStamp: Ember.computed('started', function () {
      const time = this.started;
      return new Date(time);
    }),
    showTransitioning: Ember.computed('showTransitioningMessage', 'executionState', function () {
      return this.showTransitioningMessage && this.executionState !== _pipelineConstants.default.STATES.ABORTED && this.executionState !== _pipelineConstants.default.STATES.FAILED;
    }),
    displayRepositoryUrl: Ember.computed('repositoryUrl', function () {
      let url = this.repositoryUrl;

      if (url.endsWith('.git')) {
        url = url.substr(0, url.length - 4);
      }

      return url;
    }),
    commitUrl: Ember.computed('commit', 'displayRepositoryUrl', 'pipeline.displayName', 'pipeline.sourceCodeCredential.sourceCodeType', function () {
      let url = this.displayRepositoryUrl;
      const sourceCodeType = Ember.get(this, 'pipeline.sourceCodeCredential.sourceCodeType');
      const name = Ember.get(this, 'pipeline.displayName');

      switch (sourceCodeType) {
        case 'bitbucketserver':
          if (name.startsWith('~')) {
            return `${this.bitbucketRootUrl('users')}/commits/${this.commit}`;
          } else {
            return `${this.bitbucketRootUrl('projects')}/commits/${this.commit}`;
          }

        case 'bitbucketcloud':
          return `${url}/commits/${this.commit}`;

        default:
          return `${url}/commit/${this.commit}`;
      }
    }),
    branchUrl: Ember.computed('branch', 'displayRepositoryUrl', 'pipeline.displayName', 'pipeline.sourceCodeCredential.sourceCodeType', function () {
      let url = this.displayRepositoryUrl;
      const sourceCodeType = Ember.get(this, 'pipeline.sourceCodeCredential.sourceCodeType');
      const name = Ember.get(this, 'pipeline.displayName');

      switch (sourceCodeType) {
        case 'bitbucketserver':
          if (name.startsWith('~')) {
            return `${this.bitbucketRootUrl('users')}/browse?at=refs%2Fheads%2F${this.branch}`;
          } else {
            return `${this.bitbucketRootUrl('projects')}/browse?at=refs%2Fheads%2F${this.branch}`;
          }

        case 'bitbucketcloud':
          return `${url}/src/${this.branch}`;

        default:
          return `${url}/tree/${this.branch}`;
      }
    }),
    duration: Ember.computed('started', 'ended', function () {
      const started = this.started;
      const ended = this.ended;

      if (ended) {
        const duration = new Date(ended).getTime() - new Date(started).getTime();
        return duration < 0 ? null : duration;
      }

      return null;
    }),

    bitbucketRootUrl(repoType) {
      let url = this.displayRepositoryUrl;
      url = url.substr(0, Ember.get(url, 'length') - Ember.get(this, 'pipeline.displayName.length') - 5);
      return `${url}/${repoType}/${Ember.get(this, 'pipeline.projectName')}/repos/${Ember.get(this, 'pipeline.repoName')}`;
    },

    actions: {
      rerun() {
        return this.doAction('rerun').then(() => {
          const pipelineId = Ember.get(this, 'pipeline.id');
          const nextRun = Ember.get(this, 'pipeline.nextRun');
          this.router.transitionTo('authenticated.project.pipeline.pipelines.run', pipelineId, nextRun);
        });
      },

      stop() {
        return this.doAction('stop');
      }

    }
  });

  PipelineExecution.reopenClass({
    mangleIn(data) {
      if (data && data.pipeline) {
        data.pipeline._type = data.pipeline.type;
        delete data.pipeline.type;
      }

      return data;
    }

  });
  var _default = PipelineExecution;
  _exports.default = _default;
});