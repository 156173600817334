define("ui/models/namespaceddockercredential", ["exports", "ui/models/dockercredential", "@rancher/ember-api-store/utils/denormalize"], function (_exports, _dockercredential, _denormalize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dockercredential.default.extend({
    clusterStore: Ember.inject.service(),
    canClone: true,
    namespace: (0, _denormalize.reference)('namespaceId', 'namespace', 'clusterStore'),
    actions: {
      clone() {
        this.router.transitionTo('authenticated.project.registries.new', {
          queryParams: {
            id: this.id,
            type: this.type
          }
        });
      }

    }
  });

  _exports.default = _default;
});