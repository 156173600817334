define("ui/authenticated/cluster/projects/new-ns/controller", ["exports", "ui/mixins/new-or-edit", "ui/utils/constants"], function (_exports, _newOrEdit, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ISTIO_INJECTION = 'istio-injection';
  const ENABLED = 'enabled';

  var _default = Ember.Controller.extend(_newOrEdit.default, {
    scope: Ember.inject.service(),
    queryParams: ['addTo', 'from'],
    addTo: null,
    from: null,
    istioInjection: false,
    primaryResource: Ember.computed.alias('model.namespace'),
    actions: {
      cancel() {
        let backTo = this.session.get(_constants.default.SESSION.BACK_TO);

        if (backTo) {
          this.transitionToRoute('authenticated.project.ns.index', this.addTo);
        } else {
          this.transitionToRoute('authenticated.cluster.projects.index');
        }
      },

      updateNsQuota(quota) {
        if (quota) {
          Ember.set(this, 'primaryResource.resourceQuota', {
            limit: quota
          });
        } else {
          Ember.set(this, 'primaryResource.resourceQuota', null);
        }
      },

      updateContainerDefault(limit) {
        Ember.set(this, 'primaryResource.containerDefaultResourceLimit', limit);
      },

      toggleAutoInject() {
        Ember.set(this, 'istioInjection', !this.istioInjection);
      },

      setLabels(labels) {
        let out = {};
        labels.forEach(row => {
          out[row.key] = row.value;
        });
        Ember.set(this, 'primaryResource.labels', out);
      }

    },
    projectDidChange: Ember.observer('primaryResource.project.id', function () {
      Ember.set(this, 'switchingProject', true);
      Ember.run.next(() => {
        Ember.set(this, 'switchingProject', false);
      });

      if (!Ember.get(this, 'primaryResource.project.resourceQuota')) {
        Ember.set(this, 'primaryResource.resourceQuota', null);
      }
    }),
    allProjects: Ember.computed('model.allProjects', 'scope.currentCluster.id', function () {
      return Ember.get(this, 'model.allProjects').filterBy('clusterId', Ember.get(this, 'scope.currentCluster.id'));
    }),
    projectLimit: Ember.computed('allProjects', 'primaryResource.projectId', 'primaryResource.resourceQuota.limit', function () {
      const projectId = Ember.get(this, 'primaryResource.projectId');
      const project = this.allProjects.findBy('id', projectId);
      return Ember.get(project, 'resourceQuota.limit');
    }),
    projectUsedLimit: Ember.computed('allProjects', 'primaryResource.projectId', 'primaryResource.resourceQuota.limit', function () {
      const projectId = Ember.get(this, 'primaryResource.projectId');
      const project = this.allProjects.findBy('id', projectId);
      return Ember.get(project, 'resourceQuota.usedLimit');
    }),
    nsDefaultQuota: Ember.computed('allProjects', 'primaryResource.projectId', 'primaryResource.resourceQuota.limit', function () {
      const projectId = Ember.get(this, 'primaryResource.projectId');
      const project = this.allProjects.findBy('id', projectId);
      return Ember.get(project, 'namespaceDefaultResourceQuota.limit');
    }),
    nameExists: Ember.computed('model.namespaces.@each.name', 'primaryResource.{id,name}', function () {
      const name = Ember.get(this, 'primaryResource.name');

      if (name) {
        const found = Ember.get(this, 'model.namespaces').findBy('name', name);
        return found && Ember.get(this, 'primaryResource.id') !== Ember.get(found, 'id');
      }

      return false;
    }),

    willSave() {
      const isEnabled = this.istioInjection;
      const labels = { ...Ember.get(this, 'primaryResource.labels')
      };

      if (isEnabled) {
        labels[ISTIO_INJECTION] = ENABLED;
      }

      Ember.set(this, 'primaryResource.labels', labels);
      return this._super(...arguments);
    },

    validate() {
      this._super();

      const errors = this.errors || [];
      const quotaErrors = this.primaryResource.validateResourceQuota();

      if (quotaErrors.length > 0) {
        errors.pushObjects(quotaErrors);
      }

      Ember.set(this, 'errors', errors);
      return Ember.get(this, 'errors.length') === 0;
    },

    doneSaving() {
      this.send('cancel');
    }

  });

  _exports.default = _default;
});