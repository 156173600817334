define("ui/not-found/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    actions: {
      home() {
        const target = `${window.location.origin}/dashboard`;
        const looped = window.location.href === target;

        if (looped || Ember.get(this, 'app.environment') === 'development') {
          const router = this.router;
          router.transitionTo('authenticated');
        } else {
          window.location.href = '/dashboard';
        }
      }

    }
  });

  _exports.default = _default;
});