define("ui/components/new-edit-project/component", ["exports", "ui/components/new-edit-project/template", "ui/mixins/new-or-edit", "shared/mixins/child-hook"], function (_exports, _template, _newOrEdit, _childHook) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const M_CONFIG = {
    type: 'projectRoleTemplateBinding',
    subjectKind: '',
    userId: '',
    projectRoleTemplateId: '',
    projectId: ''
  };

  var _default = Ember.Component.extend(_newOrEdit.default, _childHook.default, {
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    layout: _template.default,
    memberConfig: M_CONFIG,
    model: null,
    isNew: false,
    primaryResource: Ember.computed.alias('model.project'),
    policies: Ember.computed.alias('model.policies'),

    init() {
      this._super(...arguments);

      let bindings = (Ember.get(this, 'model.project.projectRoleTemplateBindings') || []).slice();
      bindings = bindings.filter(x => Ember.get(x, 'name') !== 'creator');
      Ember.set(this, 'memberArray', bindings);

      if (Ember.isEmpty(Ember.get(this, 'primaryResource.id'))) {
        Ember.set(this, 'isNew', true);
      }
    },

    actions: {
      cancel() {
        this.goBack();
      },

      expandFn() {},

      updateQuota(quota) {
        const primaryResource = this.primaryResource;

        if (quota) {
          Ember.setProperties(primaryResource, quota);
        } else {
          Ember.setProperties(primaryResource, {
            resourceQuota: null,
            namespaceDefaultResourceQuota: null
          });
        }
      },

      updateContainerDefault(limit) {
        const primaryResource = this.primaryResource;
        Ember.set(primaryResource, 'containerDefaultResourceLimit', limit);
      }

    },
    creator: Ember.computed('editing', 'model.{me,users}', 'primaryResource.creatorId', function () {
      let cid = Ember.get(this, 'primaryResource.creatorId');
      let creator = null;

      if (this.editing) {
        let users = Ember.get(this, 'model.users');
        creator = users.findBy('id', cid) || users.findBy('username', cid); // TODO 2.0 must do because first clusters and projects are given admin as the creator id which is not the admins userid
      } else {
        creator = Ember.get(this, 'model.me');
      }

      return creator;
    }),

    goBack() {
      this.router.transitionTo('authenticated.cluster.projects.index');
    },

    validate() {
      this._super();

      const errors = this.errors || [];
      const intl = this.intl;
      const resourceQuota = Ember.get(this, 'primaryResource.resourceQuota.limit') || {};
      const nsResourceQuota = Ember.get(this, 'primaryResource.namespaceDefaultResourceQuota.limit') || {};
      Object.keys(nsResourceQuota).forEach(key => {
        if (nsResourceQuota[key] && !resourceQuota[key]) {
          errors.push(intl.t('formResourceQuota.errors.projectLimitRequired', {
            resource: intl.t(`formResourceQuota.resources.${key}`)
          }));
        }
      });
      Object.keys(resourceQuota).forEach(key => {
        if (resourceQuota[key] && !nsResourceQuota[key]) {
          errors.push(intl.t('formResourceQuota.errors.nsDefaultLimitRequired', {
            resource: intl.t(`formResourceQuota.resources.${key}`)
          }));
        }
      });
      Ember.set(this, 'errors', errors);
      return Ember.get(this, 'errors.length') === 0;
    },

    doneSaving() {
      this.goBack();
    },

    doSave(opt) {
      opt = opt || {};
      opt.qp = {
        '_replace': 'true'
      };
      return this._super(opt);
    }

  });

  _exports.default = _default;
});