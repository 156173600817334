define("ui/components/modal-edit-certificate/component", ["exports", "shared/mixins/new-or-edit", "shared/mixins/modal-base", "ui/components/modal-edit-certificate/template"], function (_exports, _newOrEdit, _modalBase, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modalBase.default, _newOrEdit.default, {
    intl: Ember.inject.service(),
    layout: _template.default,
    classNames: ['large-modal'],
    editing: true,
    model: null,
    originalModel: Ember.computed.alias('modalService.modalOpts'),

    init() {
      this._super(...arguments);

      this.set('model', this.originalModel.clone());
    },

    manageModel() {
      let clone = this.originalModel;
      let model = this.model;

      if (clone.get('key') === model.get('key')) {
        delete model.key;
      }
    },

    validate() {
      var model = this.model;
      var errors = this.errors || [];
      var intl = this.intl; // key is the only node that can be deleted safely

      this.manageModel();

      if (!model.cert) {
        errors.push(intl.t('validation.required', {
          key: 'cert'
        }));
      }

      if (model.get('name') === null) {
        errors.push(intl.t('validation.required', {
          key: 'name'
        }));
      }

      this.set('errors', null);
      return true;
    },

    doneSaving() {
      this.send('cancel');
    }

  });

  _exports.default = _default;
});