define("ui/volumes/detail/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      const store = this.store;
      const clusterStore = this.clusterStore;
      return Ember.RSVP.hash({
        persistentVolumes: clusterStore.findAll('persistentVolume'),
        storageClasses: clusterStore.findAll('storageClass'),
        pvc: store.find('persistentVolumeClaim', params.volume_id)
      });
    }

  });

  _exports.default = _default;
});