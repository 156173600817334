define("ui/components/modal-kubeconfig/component", ["exports", "shared/mixins/modal-base", "ui/components/modal-kubeconfig/template"], function (_exports, _modalBase, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modalBase.default, {
    globalStore: Ember.inject.service(),
    access: Ember.inject.service(),
    growl: Ember.inject.service(),
    scope: Ember.inject.service(),
    settings: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    layout: _template.default,
    classNames: ['modal-container', 'large-modal', 'alert'],
    kubeconfig: null,
    step: 1,
    downloadUrl: 'http://kubernetes.io/docs/user-guide/prereqs/',

    didReceiveAttrs() {
      Ember.get(this, 'scope.currentCluster').doAction('generateKubeconfig').then(obj => {
        Ember.set(this, 'kubeconfig', Ember.get(obj, 'config'));
        Ember.set(this, 'step', 2);
      }).catch(err => {
        this.growl.fromError('Error creating kubeconfig file', err);
        this.modalService.toggleModal();
      });
    },

    didInsertElement() {
      this._super();

      Ember.run.next(this, () => {
        var btn = $('.close-kubeconfig')[0]; // eslint-disable-line

        if (btn) {
          btn.focus();
        }
      });
    }

  });

  _exports.default = _default;
});