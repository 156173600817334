define("ui/models/workload", ["exports", "shared/mixins/grafana", "@rancher/ember-api-store/models/resource", "shared/utils/util", "shared/utils/parse-unit", "@rancher/ember-api-store/utils/denormalize", "ui/mixins/state-counts", "ui/mixins/endpoint-ports", "shared/mixins/display-image", "shared/utils/constants"], function (_exports, _grafana, _resource, _util, _parseUnit, _denormalize, _stateCounts, _endpointPorts, _displayImage, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.activeIcon = activeIcon;
  _exports.default = void 0;
  const WORKLOAD_CONFIG_FIELDS = ['cronJobConfig', 'daemonSetConfig', 'deploymentConfig', 'jobConfig', 'replicaSetConfig', 'replicationControllerConfig', 'statefulSetConfig'];

  var Workload = _resource.default.extend(_grafana.default, _displayImage.default, _stateCounts.default, _endpointPorts.default, {
    intl: Ember.inject.service(),
    growl: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    scope: Ember.inject.service(),
    router: Ember.inject.service(),
    clusterStore: Ember.inject.service(),
    pods: (0, _denormalize.hasMany)('id', 'pod', 'workloadId'),
    scaleTimer: null,
    // @TODO-2.0 cleanup all these...
    hasPorts: true,
    canUpgrade: true,
    canHaveLabels: true,
    realButNotLb: true,
    canHaveLinks: true,
    canChangeNetworking: true,
    canChangeSecurity: true,
    canHaveSecrets: true,
    canHaveEnvironment: true,
    canHaveHealthCheck: true,
    isBalancer: false,
    canBalanceTo: true,
    grafanaResourceId: Ember.computed.alias('name'),
    namespace: (0, _denormalize.reference)('namespaceId', 'namespace', 'clusterStore'),
    canClone: Ember.computed.not('hasSidekicks'),
    hasSidekicks: Ember.computed.gt('containers.length', 1),
    launchConfig: Ember.computed.alias('containers.firstObject'),
    canScaleUp: Ember.computed.alias('canScale'),

    init() {
      this._super(...arguments);

      this.defineStateCounts('pods', 'podStates', 'podCountSort');
    },

    restarts: Ember.computed('pods.@each.restarts', function () {
      let out = 0;
      (this.pods || []).forEach(pod => {
        out += Ember.get(pod, 'restarts');
      });
      return out;
    }),
    lcType: Ember.computed('type', function () {
      return (this.type || '').toLowerCase();
    }),
    canEdit: Ember.computed('links.update', 'lcType', function () {
      const lcType = this.lcType;
      return !!Ember.get(this, 'links.update') && lcType !== 'job';
    }),
    availableActions: Ember.computed('actionLinks.{activate,deactivate,pause,restart,rollback,garbagecollect}', 'links.{update,remove}', 'podForShell', 'isPaused', 'canEdit', function () {
      const a = this.actionLinks || {};
      const podForShell = this.podForShell;
      const isPaused = this.isPaused;
      const canEdit = this.canEdit;
      let choices = [{
        label: 'action.redeploy',
        icon: 'icon icon-refresh',
        action: 'redeploy',
        enabled: canEdit,
        bulkable: true
      }, {
        label: 'action.addSidekick',
        icon: 'icon icon-plus-circle',
        action: 'addSidekick'
      }, {
        label: 'action.rollback',
        icon: 'icon icon-history',
        action: 'rollback',
        enabled: !!a.rollback
      }, {
        divider: true
      }, {
        label: 'action.execute',
        icon: 'icon icon-terminal',
        action: 'shell',
        enabled: !!podForShell,
        altAction: 'popoutShell'
      }, //      { label: 'action.logs',           icon: 'icon icon-file',             action: 'logs',           enabled: !!a.logs, altAction: 'popoutLogs' },
      {
        divider: true
      }, {
        label: 'action.pause',
        icon: 'icon icon-pause',
        action: 'pause',
        enabled: !!a.pause && !isPaused,
        bulkable: true
      }, {
        label: 'action.resume',
        icon: 'icon icon-play',
        action: 'resume',
        enabled: !!a.pause && isPaused,
        bulkable: true
      }];
      return choices;
    }),
    displayType: Ember.computed('type', function () {
      let type = this.type;
      return this.intl.t(`servicePage.serviceType.${type}`);
    }),
    sortName: Ember.computed('displayName', function () {
      return (0, _util.sortableNumericSuffix)(this.displayName);
    }),
    combinedState: Ember.computed('state', 'healthState', function () {
      var service = this.state;
      var health = this.healthState;

      if (service === 'active' && health) {
        // Return the health state for active services
        return health;
      } // Return the service for anything else


      return service;
    }),
    isGlobalScale: Ember.computed('lcType', function () {
      let lcType = this.lcType;
      return lcType === 'daemonset';
    }),
    canScaleDown: Ember.computed('canScale', 'scale', function () {
      return this.canScale && this.scale > 0;
    }),
    displayScale: Ember.computed('scale', 'isGlobalScale', 'lcType', function () {
      if (this.isGlobalScale) {
        return this.intl.t('servicePage.multistat.daemonSetScale');
      } else {
        return this.scale;
      }
    }),
    canScale: Ember.computed('lcType', function () {
      let lcType = this.lcType;
      return lcType !== 'cronjob' && lcType !== 'daemonset' && lcType !== 'job';
    }),
    activeIcon: Ember.computed('lcType', function () {
      return activeIcon(this);
    }),
    memoryReservationBlurb: Ember.computed('launchConfig.memoryReservation', function () {
      if (Ember.get(this, 'launchConfig.memoryReservation')) {
        return (0, _parseUnit.formatSi)(Ember.get(this, 'launchConfig.memoryReservation'), 1024, 'iB', 'B');
      }

      return '';
    }),
    podForShell: Ember.computed('pods.@each.canShell', function () {
      return this.pods.findBy('canShell', true);
    }),
    secondaryLaunchConfigs: Ember.computed('containers.[]', function () {
      return (this.containers || []).slice(1);
    }),
    isCreatedByRancher: Ember.computed('workloadAnnotations', function () {
      const workloadAnnotations = this.workloadAnnotations || {};
      return !!workloadAnnotations[_constants.default.LABEL.CREATOR_ID];
    }),
    currentScale: Ember.computed('pods.@each.state', 'scale', function () {
      const {
        pods = []
      } = this;
      return pods.filter(p => p.state === 'running').length;
    }),
    actions: {
      activate() {
        return this.doAction('activate');
      },

      deactivate() {
        return this.doAction('deactivate');
      },

      pause() {
        return this.doAction('pause');
      },

      resume() {
        return this.doAction('resume');
      },

      restart() {
        return this.doAction('restart', {
          rollingRestartStrategy: {}
        });
      },

      rollback() {
        this.modalService.toggleModal('modal-rollback-service', {
          originalModel: this
        });
      },

      garbageCollect() {
        return this.doAction('garbagecollect');
      },

      // Start and stop are only here to mimic the same actions that exist on a container
      // the reason being bulkActions, to forgo writing distinct logic for containers vs
      // services lets just mimic the actions here.
      start() {
        return this.doAction('activate');
      },

      stop() {
        return this.doAction('deactivate');
      },

      promptStop() {
        this.modalService.toggleModal('modal-container-stop', {
          model: [this]
        });
      },

      scaleUp() {
        Ember.set(this, 'scale', this.scale + 1);
        this.saveScale();
      },

      scaleDown() {
        let scale = this.scale;
        scale -= 1;
        scale = Math.max(scale, 0);
        Ember.set(this, 'scale', scale);
        this.saveScale();
      },

      edit(upgradeImage = 'false') {
        var route = 'containers.run';

        if (this.lcType === 'loadbalancerservice') {
          route = 'balancers.run';
        }

        this.router.transitionTo(route, {
          queryParams: {
            workloadId: this.id,
            upgrade: true,
            upgradeImage,
            namespaceId: this.namespaceId
          }
        });
      },

      clone() {
        this.router.transitionTo('containers.run', {
          queryParams: {
            workloadId: this.id
          }
        });
      },

      redeploy() {
        if (this.hasAction('redeploy')) {
          this.doAction('redeploy');
        } else {
          this.updateTimestamp();
          this.save();
        }
      },

      addSidekick() {
        this.router.transitionTo('containers.run', {
          queryParams: {
            workloadId: this.id,
            addSidekick: true
          }
        });
      },

      shell() {
        this.modalService.toggleModal('modal-shell', {
          model: this.podForShell
        });
      },

      popoutShell() {
        const projectId = Ember.get(this, 'scope.currentProject.id');
        const podId = Ember.get(this, 'podForShell.id');
        const route = this.router.urlFor('authenticated.project.console', projectId);
        const system = Ember.get(this, 'podForShell.node.info.os.operatingSystem') || '';
        let windows = false;

        if (system.startsWith('Windows')) {
          windows = true;
        }

        Ember.run.later(() => {
          const opt = 'toolbars=0,width=900,height=700,left=200,top=200';
          window.open(`//${window.location.host}${route}?podId=${podId}&windows=${windows}&isPopup=true`, '_blank', opt);
        });
      }

    },

    updateTimestamp() {
      let obj = this.annotations;

      if (!obj) {
        obj = {};
        Ember.set(this, 'annotations', obj);
      }

      obj[_constants.default.LABEL.TIMESTAMP] = new Date().toISOString().replace(/\.\d+Z$/, 'Z');
    },

    saveScale() {
      if (this.scaleTimer) {
        Ember.run.cancel(this.scaleTimer);
      }

      const scale = this.scale;
      var timer = Ember.run.later(this, function () {
        this.save({
          data: {
            scale
          }
        }).catch(err => {
          this.growl.fromError('Error updating scale', err);
        });
      }, 500);
      Ember.set(this, 'scaleTimer', timer);
    },

    clearConfigsExcept(keep) {
      const keys = this.allKeys().filter(x => WORKLOAD_CONFIG_FIELDS.indexOf(x) > -1);

      for (let key, i = 0; i < keys.length; i++) {
        key = keys[i];

        if (key !== keep && Ember.get(this, key)) {
          Ember.set(this, key, null);
        }
      }
    }

  });

  function activeIcon(workload) {
    var out = 'icon icon-services';

    switch (workload.get('lcType')) {
      case 'pod':
        out = 'icon icon-container';
        break;

      case 'cronjob':
        out = 'icon icon-backup';
        break;

      case 'job':
        out = 'icon icon-file';
        break;

      case 'daemonset':
        out = 'icon icon-globe';
        break;

      case 'statefulset':
        out = 'icon icon-database';
        break;
    }

    return out;
  }

  Workload.reopenClass({
    stateMap: {
      'active': {
        icon: activeIcon,
        color: 'text-success'
      }
    },

    mangleIn(data) {
      if (data) {
        const publicEndpoints = Ember.get(data, 'publicEndpoints') || [];
        const containers = Ember.get(data, 'containers') || [];
        publicEndpoints.forEach(endpoint => {
          endpoint.type = 'publicEndpoint';
        });
        containers.forEach(container => {
          container.type = 'container';
        });
      }

      return data;
    }

  });
  var _default = Workload;
  _exports.default = _default;
});