define("ui/ingresses/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    scope: Ember.inject.service(),
    projectController: Ember.inject.controller('authenticated.project'),
    queryParams: ['sortBy'],
    sortBy: 'name',
    headers: [{
      name: 'state',
      sort: ['sortState', 'displayName'],
      searchField: 'displayState',
      translationKey: 'generic.state',
      width: 120
    }, {
      name: 'name',
      sort: ['sortName', 'id'],
      searchField: 'displayName',
      translationKey: 'generic.name',
      width: 200
    }, {
      name: 'targets',
      sort: false,
      search: false,
      translationKey: 'ingressPage.table.targets.label'
    }, {
      classNames: 'text-right pr-20',
      name: 'created',
      sort: ['created', 'id'],
      searchField: false,
      translationKey: 'generic.created',
      width: 200
    }],
    group: Ember.computed.alias('projectController.group'),
    groupTableBy: Ember.computed.alias('projectController.groupTableBy'),
    balancerServices: Ember.computed('model.services.@each.kind', function () {
      return Ember.get(this, 'model.services').filterBy('kind', 'LoadBalancer');
    }),
    rows: Ember.computed('model.ingresses.[]', 'balancerServices.[]', function () {
      const out = (this.balancerServices || []).slice();
      out.addObjects(Ember.get(this, 'model.ingresses') || []);
      return out;
    })
  });

  _exports.default = _default;
});