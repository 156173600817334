define("ui/models/member", ["exports", "@rancher/ember-api-store/models/resource"], function (_exports, _resource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { reference } from '@rancher/ember-api-store/utils/denormalize';
  var _default = _resource.default.extend({
    globalStore: Ember.inject.service(),
    principal: Ember.computed('userPrincipalId', 'groupPrincipalId', function () {
      if (this.userPrincipalId) {
        return this.globalStore.getById('principal', this.userPrincipalId);
      } else if (this.groupPrincipalId) {
        return this.globalStore.getById('principal', this.groupPrincipalId);
      }

      return;
    }),
    displayType: Ember.computed('principal.id', 'principalType', function () {
      let principal = this.principal;
      let type = null;

      if (principal && Ember.get(principal, 'displayType')) {
        type = Ember.get(principal, 'displayType');
      } else if (principal && Ember.get(principal, 'principalType')) {
        type = this.principalType;
      }

      return type;
    }),
    displayName: Ember.computed('groupPrincipalId', 'principal.id', 'userPrincipalId', function () {
      let principal = this.principal;
      let name = null;

      if (principal && Ember.get(principal, 'displayName')) {
        name = Ember.get(principal, 'displayName');
      } else if (this.userPrincipalId) {
        name = this.userPrincipalId;
      } else if (this.groupPrincipalId) {
        name = this.groupPrincipalId;
      }

      return name;
    })
  });

  _exports.default = _default;
});