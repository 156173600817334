define("ui/models/scalehost", ["exports", "@rancher/ember-api-store/models/resource"], function (_exports, _resource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resource.default.extend({
    hostSelectorStr: Ember.computed('hostSelector', function () {
      let all = this.hostSelector || [];
      return Object.keys(all).map(key => {
        let val = all[key];
        return key + (val ? `=${val}` : '');
      }).join(', ');
    }),

    validationErrors() {
      let errors = this._super(...arguments);

      let min = parseInt(this.min, 10);
      let max = parseInt(this.max, 10);

      if (min && max && min > max) {
        errors.push('"Minimum Scale" cannot be greater than "Maximum Scale"');
      }

      return errors;
    }

  });

  _exports.default = _default;
});