define("ui/components/modal-rollback-mc-app/component", ["exports", "shared/mixins/modal-base", "ui/components/modal-rollback-mc-app/template", "jsondiffpatch", "moment"], function (_exports, _modalBase, _template, _jsondiffpatch, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const HIDDEN_FIELDS = ['digest', 'created', 'createdTS', 'links', 'uuid', 'id', 'name'];

  function sanitizeToRemoveHiddenKeys(config) {
    HIDDEN_FIELDS.forEach(key => {
      if (config.hasOwnProperty(key)) {
        delete config[key];
      }
    });
    return config;
  }

  var _default = Ember.Component.extend(_modalBase.default, {
    layout: _template.default,
    classNames: ['large-modal'],
    loading: true,
    revisions: null,
    revisionId: null,
    multiClusterAppHasUpgradeStrategy: Ember.computed.notEmpty('model.upgradeStrategy.rollingUpdate'),

    didReceiveAttrs() {
      this.initModelWithClone();
      this.getMultiClusterAppRevisions();
    },

    actions: {
      save(cb) {
        const {
          revisionId
        } = this;
        const neu = {
          revisionId
        };

        if (this.multiClusterAppHasUpgradeStrategy) {
          Ember.set(neu, 'batch', this.model.upgradeStrategy.rollingUpdate);
        }

        this.model.doAction('rollback', {
          revisionId
        }).then(() => this.send('cancel')).finally(() => cb());
      }

    },
    choices: Ember.computed('revisions.[]', function () {
      return (this.revisions || []).sortBy('created').reverse().map(r => {
        let time = (0, _moment.default)(Ember.get(r, 'created'));
        return {
          label: `${Ember.get(r, 'name')}: ${time.format('YYYY-MM-DD HH:mm:ss')} (${time.fromNow()})`,
          value: Ember.get(r, 'name'),
          data: r
        };
      });
    }),
    currentMultiClusterAppRevision: Ember.computed('choices.[]', 'choices.firstObject.data', 'revisionId', 'selectedMultiClusterAppRevision', function () {
      return Ember.get(this, 'choices.firstObject.data');
    }),
    selectedMultiClusterAppRevision: Ember.computed('choices.[]', 'revisionId', 'currentMultiClusterAppRevision', function () {
      const match = this.choices.findBy('value', this.revisionId);
      return match ? match.data : null;
    }),
    answersDiff: Ember.computed('currentMultiClusterAppRevision', 'selectedMultiClusterAppRevision', 'revisionId', function () {
      if (this.currentMultiClusterAppRevision && this.selectedMultiClusterAppRevision) {
        const {
          currentMultiClusterAppRevision,
          selectedMultiClusterAppRevision
        } = this;
        return this.generateAnswersJsonDiff(currentMultiClusterAppRevision, selectedMultiClusterAppRevision);
      }

      return null;
    }),

    initModelWithClone() {
      Ember.set(this, 'model', Ember.get(this, 'modalService.modalOpts.originalModel').clone());
    },

    generateAnswersJsonDiff(left, right) {
      const delta = _jsondiffpatch.default.diff(sanitizeToRemoveHiddenKeys(left), sanitizeToRemoveHiddenKeys(right));

      _jsondiffpatch.default.formatters.html.hideUnchanged();

      return _jsondiffpatch.default.formatters.html.format(delta, left).htmlSafe();
    },

    getMultiClusterAppRevisions() {
      const revisionsURL = Ember.get(this, 'modalService.modalOpts.revisionsLink');
      return this.store.rawRequest({
        url: revisionsURL,
        method: 'GET'
      }).then(revs => Ember.set(this, 'revisions', revs.body.data)).catch(err => {
        this.send('cancel');
        this.growl.fromError(err);
      }).finally(() => Ember.set(this, 'loading', false));
    }

  });

  _exports.default = _default;
});