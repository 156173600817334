define("ui/verify-reset-password/controller", ["exports", "@rancher/ember-api-store/utils/fetch"], function (_exports, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    passwordRest: false,
    loading: false,
    password: null,
    canSend: false,
    actions: {
      resetPassword() {
        var body = this.model;
        body.token = this.token;
        body.pw = this.password;
        this.set('loading', true);
        (0, _fetch.default)('/update-password', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        }).then(() => {
          this.set('loading', false);
          window.location.href = '/';
        }).catch(err => {
          this.set('loading', false);
          this.set('errors', [err.body.detail]);
        });
      }

    }
  });

  _exports.default = _default;
});