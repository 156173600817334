define("ui/models/persistentvolumeclaim", ["exports", "@rancher/ember-api-store/models/resource", "@rancher/ember-api-store/utils/denormalize", "shared/utils/parse-unit"], function (_exports, _resource, _denormalize, _parseUnit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var PersistentVolumeClaim = _resource.default.extend({
    clusterStore: Ember.inject.service(),
    type: 'persistentVolumeClaim',
    canEdit: false,
    storageClass: (0, _denormalize.reference)('storageClassId', 'storageClass', 'clusterStore'),
    persistentVolume: (0, _denormalize.reference)('volumeId', 'persistentVolume', 'clusterStore'),
    namespace: (0, _denormalize.reference)('namespaceId', 'namespace', 'clusterStore'),
    availableActions: Ember.computed('canExpand', function () {
      let out = [{
        label: 'action.resize',
        icon: 'icon icon-hdd',
        action: 'resize',
        enabled: this.canExpand,
        bulkable: false
      }];
      return out;
    }),
    canExpand: Ember.computed('storageClass.allowVolumeExpansion', function () {
      const {
        storageClass
      } = this;

      if (!Ember.isEmpty(storageClass) && Ember.get(storageClass, 'allowVolumeExpansion')) {
        return true;
      }

      return false;
    }),
    workloads: Ember.computed('id', 'namespace.workloads.@each.volumes', function () {
      return (Ember.get(this, 'namespace.workloads') || []).filter(workload => (Ember.get(workload, 'volumes') || []).find(volume => Ember.get(volume, 'persistentVolumeClaim.persistentVolumeClaimId') === this.id));
    }),
    sizeBytes: Ember.computed('status.capacity.storage', function () {
      const str = Ember.get(this, 'status.capacity.storage');

      if (str) {
        return (0, _parseUnit.parseSi)(str, 1024);
      }

      return;
    }),
    displaySize: Ember.computed('sizeBytes', function () {
      const bytes = this.sizeBytes;

      if (bytes) {
        return (0, _parseUnit.formatSi)(bytes, 1024, 'iB', 'B');
      }

      return;
    }),
    actions: {
      resize() {
        this.modalService.toggleModal('modal-resize-pvc', {
          model: this
        });
      }

    }
  });

  var _default = PersistentVolumeClaim;
  _exports.default = _default;
});