define("ui/components/new-password/component", ["exports", "ui/components/new-password/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    newPassword: null,
    confirmPassword: null,
    passwordOkay: false,
    passwordOut: null,
    passwordsMatch: Ember.computed('newPassword', 'confirmPassword', function () {
      if (this.confirmPassword) {
        if (this.newPassword === this.confirmPassword) {
          this.set('passwordOut', this.newPassword);
          this.set('passwordOkay', true);
          return true;
        } else {
          this.set('passwordOut', null);
          this.set('passwordOkay', false);
          return false;
        }
      } else {
        this.set('passwordOkay', false);
        return true;
      }
    })
  });

  _exports.default = _default;
});