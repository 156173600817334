define("ui/components/modal-rollback-service/component", ["exports", "shared/mixins/modal-base", "ui/components/modal-rollback-service/template", "shared/utils/constants", "moment", "jsondiffpatch"], function (_exports, _modalBase, _template, _constants, _moment, _jsondiffpatch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const HIDDEN_FIELDS = ['created', 'creatorId', 'name', 'ownerReferences', 'removed', 'scale', 'state', 'uuid', 'workloadLabels', 'workloadAnnotations'];

  function sanitize(config, keys) {
    const result = {};
    keys.forEach(key => {
      if (config[key] !== undefined) {
        result[key] = config[key];
      }

      if (key === 'labels' && result.labels) {
        delete result.labels['pod-template-hash'];
      }

      if (key === 'selector' && result.selector && result.selector.matchLabels) {
        delete result.selector.matchLabels['pod-template-hash'];
      }
    });
    return result;
  }

  var _default = Ember.Component.extend(_modalBase.default, {
    growl: Ember.inject.service(),
    layout: _template.default,
    classNames: ['large-modal'],
    keys: null,
    name: null,
    loading: true,
    revisions: null,
    revisionId: null,

    init() {
      this._super(...arguments);

      const schema = this.store.getById('schema', 'workload');
      const resourceFields = Ember.get(schema, 'resourceFields');
      Ember.set(this, 'keys', Object.keys(resourceFields).filter(field => !field.endsWith('Status') && HIDDEN_FIELDS.indexOf(field) === -1));
    },

    didReceiveAttrs() {
      let model = Ember.get(this, 'modalService.modalOpts.originalModel').clone();
      Ember.set(this, 'model', model);
      this.store.rawRequest({
        url: Ember.get(model, 'links.revisions'),
        method: 'GET'
      }).then(revs => {
        Ember.set(this, 'revisions', revs.body.data);
      }).catch(err => {
        this.send('cancel');
        this.growl.fromError(err);
      }).finally(() => {
        Ember.set(this, 'loading', false);
      });
    },

    actions: {
      save(cb) {
        const id = Ember.get(this, 'selected.id');
        this.model.doAction('rollback', {
          replicaSetId: id
        }).then(() => {
          this.send('cancel');
        }).finally(() => {
          cb();
        });
      }

    },
    choices: Ember.computed('model.workloadAnnotations', 'revisions.[]', function () {
      return (this.revisions || []).sortBy('createdTS').reverse().map(r => {
        let time = (0, _moment.default)(Ember.get(r, 'created'));
        let id = Ember.get(r, 'id');
        return {
          label: `${id}: ${time.format('YYYY-MM-DD HH:mm:ss')} (${time.fromNow()})`,
          value: id,
          ts: Ember.get(r, 'createdTS'),
          data: r,
          disabled: r.workloadAnnotations[_constants.default.LABEL.DEPLOYMENT_REVISION] === Ember.get(this, 'model.workloadAnnotations')[_constants.default.LABEL.DEPLOYMENT_REVISION]
        };
      });
    }),
    current: Ember.computed('model.workloadAnnotations', 'revisions.@each.workloadAnnotations', function () {
      const currentRevision = Ember.get(this, 'model.workloadAnnotations')[_constants.default.LABEL.DEPLOYMENT_REVISION];

      return (this.revisions || []).find(r => Ember.get(r, 'workloadAnnotations')[_constants.default.LABEL.DEPLOYMENT_REVISION] === currentRevision);
    }),
    selected: Ember.computed('revisionId', 'revisions.[]', function () {
      return (this.revisions || []).findBy('id', this.revisionId);
    }),
    diff: Ember.computed('current', 'keys', 'selected', function () {
      if (this.current && this.selected) {
        let left = sanitize(this.current, this.keys);
        let right = sanitize(this.selected, this.keys);

        var delta = _jsondiffpatch.default.diff(left, right);

        _jsondiffpatch.default.formatters.html.hideUnchanged();

        return _jsondiffpatch.default.formatters.html.format(delta, left).htmlSafe();
      }

      return null;
    })
  });

  _exports.default = _default;
});