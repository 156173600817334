define("ui/models/pod", ["exports", "ui/utils/constants", "@rancher/ember-api-store/models/resource", "@rancher/ember-api-store/utils/denormalize", "ui/utils/util", "shared/utils/parse-unit", "shared/mixins/grafana", "shared/mixins/display-image"], function (_exports, _constants, _resource, _denormalize, _util, _parseUnit, _grafana, _displayImage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.stoppedIcon = stoppedIcon;
  _exports.stoppedColor = stoppedColor;
  _exports.default = void 0;

  var Pod = _resource.default.extend(_grafana.default, _displayImage.default, {
    router: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    globalStore: Ember.inject.service(),
    clusterStore: Ember.inject.service(),
    scope: Ember.inject.service(),
    canHaveLabels: true,
    escToClose: true,
    canEdit: false,
    canClone: false,
    grafanaDashboardName: 'Pods',
    grafanaResourceId: Ember.computed.alias('name'),
    namespace: (0, _denormalize.reference)('namespaceId', 'namespace', 'clusterStore'),
    node: (0, _denormalize.reference)('nodeId', 'node', 'globalStore'),
    workload: (0, _denormalize.reference)('workloadId'),
    hasSidekicks: Ember.computed.gt('containers.length', 1),
    canEditYaml: Ember.computed('links.update', 'links.yaml', function () {
      return !!Ember.get(this, 'links.update') && !!Ember.get(this, 'links.yaml');
    }),
    canShell: Ember.computed('containers', function () {
      return !!this.containers.findBy('canShell', true);
    }),
    availableActions: Ember.computed('canShell', function () {
      const canShell = this.canShell;
      var choices = [{
        label: 'action.execute',
        icon: 'icon icon-terminal',
        action: 'shell',
        enabled: canShell,
        altAction: 'popoutShell'
      }, {
        label: 'action.logs',
        icon: 'icon icon-file',
        action: 'logs',
        enabled: true,
        altAction: 'popoutLogs'
      }];
      return choices;
    }),
    memoryReservationBlurb: Ember.computed('memoryReservation', function () {
      if (this.memoryReservation) {
        return (0, _parseUnit.formatSi)(this.memoryReservation, 1024, 'iB', 'B');
      }

      return;
    }),
    combinedState: Ember.computed('node.state', 'workload.state', 'state', 'healthState', 'healthCheck', function () {
      var node = Ember.get(this, 'node.state');
      var resource = this.state; // var workload = get(this,'workload.state');

      var health = this.healthState;
      var hasCheck = !!this.healthCheck;

      if (!hasCheck && _constants.default.DISCONNECTED_STATES.includes(node)) {
        return 'unknown';
      } else if (_constants.default.ACTIVEISH_STATES.includes(resource) && health) {
        return health;
      } else {
        return resource;
      }
    }),
    isOn: Ember.computed('state', function () {
      return ['running', 'migrating', 'restarting'].indexOf(this.state) >= 0;
    }),
    displayState: Ember.computed('_displayState', 'exitCode', 'state', function () {
      let out = this._displayState;
      let code = this.exitCode;

      if (this.state === 'stopped' && this.exitCode > 0) {
        out += ` (${code})`;
      }

      return out;
    }),
    displayEnvironmentVars: Ember.computed('environment', function () {
      var envs = [];
      var environment = this.environment || {};
      Object.keys(environment).forEach(key => {
        envs.pushObject({
          key,
          value: environment[key]
        });
      });
      return envs;
    }),
    displayIp: Ember.computed('status.podIp', function () {
      return Ember.get(this, 'status.podIp') || null;
    }),
    dislayContainerMessage: Ember.computed('containers.@each.showTransitioningMessage', function () {
      return !!this.containers.findBy('showTransitioningMessage', true);
    }),
    restarts: Ember.computed('status.containerStatuses.@each.restartCount', function () {
      let out = 0;
      (Ember.get(this, 'status.containerStatuses') || []).forEach(state => {
        out += Ember.get(state, 'restartCount');
      });
      return out;
    }),
    nodeIp: Ember.computed('status.nodeIp', function () {
      return Ember.get(this, 'status.nodeIp') || null;
    }),
    sortIp: Ember.computed('primaryIpAddress', 'primaryAssociatedIpAddress', function () {
      var ip = this.primaryAssociatedIpAddress || this.primaryIpAddress;

      if (!ip) {
        return '';
      }

      var match = ip.match(/^(\d+)\.(\d+)\.(\d+)\.(\d+)$/);

      if (match) {
        return match.slice(1).map(octet => (0, _util.strPad)(octet, 3, '0', false)).join('.');
      }

      return '';
    }),
    isGlobalScale: Ember.computed('labels', function () {
      return `${(this.labels || {})[_constants.default.LABEL.SCHED_GLOBAL]}` === 'true';
    }),
    actions: {
      clone() {
        this.router.transitionTo('containers.run', {
          queryParams: {
            podId: this.id
          }
        });
      },

      shell() {
        this.modalService.toggleModal('modal-shell', {
          model: this
        });
      },

      popoutShell() {
        const projectId = Ember.get(this, 'scope.currentProject.id');
        const podId = this.id;
        const route = this.router.urlFor('authenticated.project.console', projectId);
        const system = Ember.get(this, 'node.info.os.operatingSystem') || '';
        let windows = false;

        if (system.startsWith('Windows')) {
          windows = true;
        }

        Ember.run.later(() => {
          window.open(`//${window.location.host}${route}?podId=${podId}&windows=${windows}&isPopup=true`, '_blank', 'toolbars=0,width=900,height=700,left=200,top=200');
        });
      },

      logs() {
        const dataToModal = {
          model: this
        };

        if (this.containers && this.containers.firstObject) {
          Ember.set(dataToModal, 'containerName', this.containers.firstObject.name);
        }

        this.modalService.toggleModal('modal-container-logs', dataToModal);
      },

      popoutLogs() {
        const projectId = Ember.get(this, 'scope.currentProject.id');
        const podId = this.id;
        const route = this.router.urlFor('authenticated.project.container-log', projectId);
        Ember.run.later(() => {
          window.open(`//${window.location.host}${route}?podId=${podId}&isPopup=true`, '_blank', 'toolbars=0,width=900,height=700,left=200,top=200');
        });
      }

    },

    hasLabel(key, desiredValue) {
      const labels = this.labels || {};
      const value = Ember.get(labels, key);

      if (value === undefined) {
        return false;
      }

      if (desiredValue === undefined) {
        return true;
      }

      return value === desiredValue;
    }

  });

  function stoppedIcon(inst) {
    if (inst.get('restartPolicy.name') === 'no' && inst.get('exitCode') === 0) {
      return 'icon icon-dot-circlefill';
    }

    return 'icon icon-circle';
  }

  function stoppedColor(inst) {
    if (inst.get('restartPolicy.name') === 'no' && inst.get('exitCode') === 0) {
      return 'text-success';
    }

    return 'text-error';
  }

  Pod.reopenClass({
    stateMap: {
      'stopped': {
        icon: stoppedIcon,
        color: stoppedColor
      }
    },

    mangleIn(data) {
      if (data && data.containers) {
        data.containers.forEach(container => {
          container.type = 'container';
          container.podId = data.id;
        });
      }

      return data;
    }

  });
  var _default = Pod;
  _exports.default = _default;
});