define("ui/models/port", ["exports", "@rancher/ember-api-store/models/resource"], function (_exports, _resource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Port = _resource.default.extend({
    _publicIp: null,
    _publicIpState: null,
    displayPublicIp: Ember.computed('_publicIp', '_publicIpState', 'bindAddress', 'publicIpAddressId', 'publicPort', 'store', function () {
      var bind = this.bindAddress;

      if (bind) {
        return bind;
      } else if (!this.publicPort) {
        return null;
      }

      var ip = this._publicIp;

      if (ip) {
        return ip;
      } else if (this._publicIpState === 2) {
        return '(Unknown IP)';
      } else if (!this._publicIpState) {
        this.set('_publicIpState', 1);
        this.store.find('ipaddress', this.publicIpAddressId).then(ip => {
          this.set('_publicIp', ip.get('address'));
        }).finally(() => {
          this.set('_publicIpState', 2);
        });
        return 'Loading...';
      }

      return null;
    })
  });

  var _default = Port;
  _exports.default = _default;
});