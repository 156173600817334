define("ui/authenticated/cluster/storage/classes/detail/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    clusterStore: Ember.inject.service(),

    model(params) {
      const clusterStore = this.clusterStore;
      const storageClassId = params.storage_class_id;
      return Ember.RSVP.hash({
        storageclass: clusterStore.find('storageclass', storageClassId),
        persistentVolumes: clusterStore.findAll('persistentVolume').then(data => (data || []).filterBy('storageClassId', storageClassId))
      });
    }

  });

  _exports.default = _default;
});