define("ui/models/publicendpoint", ["exports", "@rancher/ember-api-store/models/resource", "shared/utils/constants"], function (_exports, _resource, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function portMatch(ports, equals, endsWith) {
    if (!Ember.isArray(ports)) {
      ports = [ports];
    }

    if (!Ember.isArray(equals)) {
      equals = [equals];
    }

    if (!Ember.isArray(endsWith)) {
      endsWith = [endsWith];
    }

    for (let i = 0; i < ports.length; i++) {
      let port = ports[i];

      if (equals.includes(port)) {
        return true;
      }

      for (let j = 0; j < endsWith.length; j++) {
        let suffix = `${endsWith[j]}`;
        let portStr = `${port}`;

        if (portStr !== suffix && portStr.endsWith(suffix)) {
          return true;
        }
      }
    }

    return false;
  }

  var PublicEndpoint = _resource.default.extend({
    globalStore: Ember.inject.service(),
    scope: Ember.inject.service(),
    settings: Ember.inject.service(),
    portProto: Ember.computed('port', 'protocol', function () {
      let out = `${this.port}/${this.protocol.toLowerCase()}`;
      return out;
    }),
    // ip:port
    endpoint: Ember.computed('addresses', 'allNodes', 'hostname', 'isIngress', 'port', 'scope.currentCluster.id', function () {
      const addresses = this.addresses;
      const allNodes = this.allNodes;
      const hostname = this.hostname || '';
      let out = '';

      if (this.isIngress && hostname !== '') {
        out = hostname;
      } else if (addresses && addresses.length) {
        out = addresses[0];
      } else if (allNodes) {
        const globalStore = this.globalStore;
        const nodes = globalStore.all('node').filterBy('clusterId', Ember.get(this, 'scope.currentCluster.id'));
        let node = nodes.findBy('externalIpAddress');

        if (node) {
          out = Ember.get(node, 'externalIpAddress');
        } else {
          node = nodes.findBy('ipAddress');

          if (node) {
            out = Ember.get(node, 'ipAddress');
          }
        }
      }

      if (out) {
        out += `:${this.port}`;
      }

      return out;
    }),
    // port[/udp]
    displayEndpoint: Ember.computed('port', 'protocol', 'path', function () {
      let path = this.path || '';

      if (path && path !== '/') {
        return path;
      }

      let out = '';
      out += this.port;
      let proto = this.protocol.toLowerCase();
      out += `/${proto}`;
      return out;
    }),
    linkEndpoint: Ember.computed('displayEndpoint', 'endpoint', 'isIngress', 'isMaybeSecure', 'isTcpish', 'path', 'port', function () {
      let path = this.path || '';

      if (this.isTcpish && this.port > 0) {
        let out = this.endpoint;

        if (this.isMaybeSecure) {
          out = `https://${out.replace(/:443$/, '')}`;
        } else {
          out = `http://${out.replace(/:80$/, '')}`;
        }

        if (this.isIngress) {
          out = out + path;
        }

        return out;
      }

      return;
    }),
    isTcpish: Ember.computed('protocol', function () {
      const proto = this.protocol.toLowerCase();
      return ['tcp', 'http', 'https'].includes(proto);
    }),
    isMaybeSecure: Ember.computed('port', 'protocol', function () {
      const proto = this.protocol.toLowerCase();
      return portMatch([this.port], [443, 8443], '443') || proto === 'https';
    }),
    isIngress: Ember.computed('ingressId', function () {
      return this.ingressId !== '' && this.ingressId !== null;
    }),
    isReady: Ember.computed('hostname', 'isIngress', function () {
      const xip = Ember.get(this, `settings.${_constants.default.SETTING.INGRESS_IP_DOMAIN}`);
      const hostname = this.hostname || '';

      if (this.isIngress) {
        if (xip === hostname) {
          return false;
        }
      }

      return true;
    })
  });

  var _default = PublicEndpoint;
  _exports.default = _default;
});