define("ui/models/secret", ["exports", "@rancher/ember-api-store/models/resource"], function (_exports, _resource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resource.default.extend({
    router: Ember.inject.service(),
    allWorkloads: Ember.inject.service(),
    state: 'active',
    canClone: true,
    canHaveLabels: true,
    firstKey: Ember.computed.alias('keys.firstObject'),
    keys: Ember.computed('data', function () {
      return Object.keys(this.data || {}).sort();
    }),
    workloads: Ember.computed('allWorkloads.list.@each.{containers,volumes}', 'name', 'namespaceId', function () {
      return (Ember.get(this, 'allWorkloads.list') || []).map(item => item.obj).filter(workload => {
        if (this.namespaceId && Ember.get(workload, 'namespaceId') !== this.namespaceId) {
          return false;
        }

        const volume = (Ember.get(workload, 'volumes') || []).find(volume => Ember.get(volume, 'secret.secretName') === this.name);
        const env = (Ember.get(workload, 'containers') || []).find(container => (Ember.get(container, 'environmentFrom') || []).find(env => Ember.get(env, 'source') === 'secret' && Ember.get(env, 'sourceName') === this.name));
        return volume || env;
      });
    }),
    actions: {
      edit() {
        this.router.transitionTo('authenticated.project.secrets.detail.edit', this.id);
      },

      clone() {
        this.router.transitionTo('authenticated.project.secrets.new', {
          queryParams: {
            id: this.id,
            type: this.type
          }
        });
      }

    }
  });

  _exports.default = _default;
});