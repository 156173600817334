define("ui/components/form-contextual-key-value/component", ["exports", "shared/utils/stateful-promise"], function (_exports, _statefulPromise) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    keyContent: [],
    keyValuePairs: [],
    addButtonClass: 'btn bg-link icon-btn mt-10',
    keyLabel: 'formKeyValue.key.label',
    valueLabel: 'formKeyValue.value.label',
    keyPlaceholder: 'formKeyValue.key.placeholder',
    valuePlaceholder: 'formKeyValue.value.placeholder',
    actions: {
      onAdd() {
        const keyValuePairs = this.keyValuePairs; // We push a null keyValuePair and replace it so that we can get the filteredContent
        // with the newly selected value visible to the provider of the contetFilter method.

        keyValuePairs.pushObject(null);
        keyValuePairs.replace(-1, 1, [{
          key: Ember.get(this, 'filteredKeyContent.firstObject.value'),
          value: ''
        }]);
      },

      onRemove(index) {
        this.keyValuePairs.removeAt(index);
      }

    },
    asyncKeyContent: Ember.computed('keyContent', function () {
      return _statefulPromise.default.wrap(this.keyContent, []);
    }),
    selections: Ember.computed('keyValuePairs.[]', 'asyncKeyContent.value', function () {
      return this.keyValuePairs.slice(0, -1).map(kvp => {
        const option = Ember.get(this, 'asyncKeyContent.value').find(v => v.value === kvp.key);
        return Ember.ObjectProxy.create({
          content: kvp,
          label: option ? option.label : ''
        });
      });
    }),
    lastValue: Ember.computed('keyValuePairs', 'keyValuePairs.[]', {
      get() {
        return this.keyValuePairs.objectAt(Ember.get(this, 'keyValuePairs.length') - 1);
      },

      set(key, value) {
        this.keyValuePairs.set(Ember.get(this, 'keyValuePairs.length') - 1, value);
        return value;
      }

    }),
    canAddMore: Ember.computed('filteredKeyContent.length', 'keyValuePairs.length', function () {
      return Ember.get(this, 'filteredKeyContent.length') > 1 || Ember.get(this, 'filteredKeyContent.length') > 0 && Ember.get(this, 'keyValuePairs.length') === 0;
    }),
    lastIndex: Ember.computed('keyValuePairs.[]', function () {
      return Ember.get(this, 'keyValuePairs.length') - 1;
    }),
    filteredKeyContent: Ember.computed('asyncKeyContent.value', 'keyContentFilter', 'keyValuePairs.[]', function () {
      if (!this.keyContentFilter) {
        return Ember.get(this, 'asyncKeyContent.value') || [];
      }

      return this.keyContentFilter(Ember.get(this, 'asyncKeyContent.value'), this.keyValuePairs.slice(0, -1)) || [];
    })
  });

  _exports.default = _default;
});