define("ui/models/globalrole", ["exports", "@rancher/ember-api-store/models/resource", "@rancher/ember-api-store/utils/denormalize"], function (_exports, _resource, _denormalize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const BASE = 'user-base';
  const USER = 'user';
  const ADMIN = 'admin';
  const SPECIAL = [BASE, ADMIN, USER];

  var _default = _resource.default.extend({
    access: Ember.inject.service(),
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    globalRoleBindings: (0, _denormalize.hasMany)('id', 'globalRoleBinding', 'globalRoleId'),
    // I think its safe to hack around this - wjw
    _displayState: 'active',
    // because of this the state shows as "Unknown" with bright yellow background
    stateColor: 'text-success',
    canRemove: Ember.computed.not('builtin'),
    canClone: Ember.computed('access.me', 'id', function () {
      return this.access.allows('globalrole', 'create', 'global');
    }),
    isHidden: Ember.computed('id', function () {
      return SPECIAL.includes(this.id);
    }),
    isBase: Ember.computed('id', function () {
      return this.id === BASE;
    }),
    isUser: Ember.computed('id', function () {
      return this.id === USER;
    }),
    isAdmin: Ember.computed('id', function () {
      return this.id === ADMIN;
    }),
    isCustom: Ember.computed('isAdmin', 'isUser', 'isBase', function () {
      return !this.isAdmin && !this.isBase && !this.isUser;
    }),
    globalRoleAssociatedUserCount: Ember.computed('globalRoleBindings.@each.{id,state,newUserDefault}', function () {
      return (this.globalRoleBindings || []).length;
    }),
    displayName: Ember.computed('id', 'name', 'intl.locale', function () {
      const intl = this.intl;
      const id = this.id;
      const key = `formGlobalRoles.role.${id}.label`;

      if (intl.exists(key)) {
        return intl.t(key);
      }

      const name = this.name;

      if (name) {
        return name;
      }

      return `(${id})`;
    }),
    detail: Ember.computed('id', 'intl.locale', 'name', function () {
      const intl = this.intl;
      const id = this.id;
      const key = `formGlobalRoles.role.${id}.detail`;

      if (intl.exists(key)) {
        return intl.t(key);
      }

      return intl.t('formGlobalRoles.mode.userCreated.noDescription');
    }),
    // globalRoles can not be removed or changed as of now and do not have a state
    actions: {
      edit() {
        this.router.transitionTo('global-admin.security.roles.edit', this.id, {
          queryParams: {
            type: 'global'
          }
        });
      },

      clone() {
        this.router.transitionTo('global-admin.security.roles.new', {
          queryParams: {
            context: 'global',
            id: this.id
          }
        });
      }

    }
  });

  _exports.default = _default;
});