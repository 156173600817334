define("ui/ingresses/run/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      const store = this.store;
      const dependencies = {
        namespacedcertificates: store.findAll('namespacedcertificate'),
        certificates: store.findAll('certificate')
      };

      if (params.ingressId) {
        dependencies['existingIngress'] = store.find('ingress', params.ingressId);
      }

      return Ember.RSVP.hash(dependencies).then(hash => {
        let ingress;

        if (hash.existingIngress) {
          if (`${params.upgrade}` === 'true') {
            ingress = hash.existingIngress.clone();
            hash.existing = hash.existingIngress;
          } else {
            ingress = hash.existingIngress.cloneForNew();
          }

          delete hash.existingIngress;
        } else {
          ingress = store.createRecord({
            type: 'ingress',
            name: '',
            rules: [],
            tls: []
          });
        }

        hash.ingress = ingress;
        return hash;
      });
    },

    resetController(controller, isExisting) {
      if (isExisting) {
        Ember.set(controller, 'ingressId', null);
        Ember.set(controller, 'upgrade', null);
      }
    },

    actions: {
      cancel() {
        this.goToPrevious();
      }

    }
  });

  _exports.default = _default;
});