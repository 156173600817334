define("ui/models/projectalertrule", ["exports", "@rancher/ember-api-store/models/resource", "ui/mixins/model-alert", "ui/utils/constants"], function (_exports, _resource, _modelAlert, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const projectAlertRule = _resource.default.extend(_modelAlert.default, {
    intl: Ember.inject.service(),
    projectStore: Ember.inject.service('store'),
    canClone: true,
    canEdit: true,
    type: 'projectAlertRule',
    _targetType: 'pod',
    displayTargetType: Ember.computed('targetType', function () {
      return this.intl.t(`alertPage.targetTypes.${this.targetType}`);
    }),
    podName: Ember.computed('podRule.podId', function () {
      const id = Ember.get(this, 'podRule.podId');
      const pod = this.projectStore.all('pod').filterBy('id', id).get('firstObject');

      if (!pod) {
        return null;
      }

      return Ember.get(pod, 'displayName');
    }),
    workloadName: Ember.computed('workloadRule.workloadId', function () {
      const id = Ember.get(this, 'workloadRule.workloadId');
      const workload = this.projectStore.all('workload').filterBy('id', id).get('firstObject');

      if (!workload) {
        return null;
      }

      return Ember.get(workload, 'displayName');
    }),
    displayCondition: Ember.computed('metricRule', 'podRule.{condition,restartIntervalSeconds,restartTimes}', 'targetType', 'workloadRule.availablePercentage', function () {
      const t = this.targetType;
      const intl = this.intl;
      let out = intl.t('alertPage.na');
      const times = Ember.get(this, 'podRule.restartTimes');
      const interval = Ember.get(this, 'podRule.restartIntervalSeconds');
      const c = Ember.get(this, 'podRule.condition');
      const percent = Ember.get(this, 'workloadRule.availablePercentage');
      const metricRule = this.metricRule;

      switch (t) {
        case 'pod':
          switch (c) {
            case 'restarts':
              out = intl.t('alertPage.index.table.displayCondition.restarted', {
                times,
                interval: interval / 60
              });
              break;

            case 'notscheduled':
              out = intl.t('alertPage.index.table.displayCondition.notScheduled');
              break;

            case 'notrunning':
              out = intl.t('alertPage.index.table.displayCondition.notRunning');
              break;
          }

          break;

        case 'workload':
        case 'workloadSelector':
          out = intl.t('alertPage.index.table.displayCondition.available', {
            percent
          });
          break;

        case 'metric':
          out = metricRule.comparison === _constants.default.ALERTING_COMPARISON.HAS_VALUE ? intl.t(`alertPage.comparison.${metricRule.comparison}`) : `${intl.t(`alertPage.comparison.${metricRule.comparison}`)} ${metricRule.thresholdValue}`;
          break;
      }

      return out;
    }),
    targetType: Ember.computed('podRule.podId', 'workloadRule.{workloadId,selector}', 'metricRule.expression', function () {
      if (Ember.get(this, 'podRule.podId')) {
        return 'pod';
      }

      if (Ember.get(this, 'workloadRule.workloadId')) {
        return 'workload';
      }

      if (Ember.get(this, 'workloadRule.selector')) {
        return 'workloadSelector';
      }

      if (Ember.get(this, 'metricRule.expression')) {
        return 'metric';
      }

      return;
    }),
    actions: {
      clone() {
        this.router.transitionTo('authenticated.project.alert.new-rule', this.groupId, {
          queryParams: {
            id: this.id
          }
        });
      },

      edit() {
        this.router.transitionTo('authenticated.project.alert.edit-rule', this.groupId, this.id);
      }

    }
  });

  var _default = projectAlertRule;
  _exports.default = _default;
});