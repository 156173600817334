define("ui/verify/route", ["exports", "shared/utils/constants", "shared/utils/util", "ui/mixins/verify-auth"], function (_exports, _constants, _util, _verifyAuth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const samlProviders = ['ping', 'adfs', 'keycloak', 'okta', 'shibboleth'];
  const allowedForwards = ['localhost'];

  var _default = Ember.Route.extend(_verifyAuth.default, {
    oauth: Ember.inject.service(),
    intl: Ember.inject.service(),
    azureAD: Ember.inject.service(),
    language: Ember.inject.service('user-language'),

    beforeModel() {
      if (!this.intl.locale) {
        return this.language.initUnauthed();
      }
    },

    model(params
    /* , transition */
    ) {
      const oauth = this.oauth;
      const azure = this.azureAD;
      const forward = Ember.get(params, 'forward'); // Allow another redirect if the hostname is in the whitelist above.
      // This allows things like sharing github auth between rancher at localhost:8000
      // and rio dev at localhost:8004

      if (forward) {
        const parsed = (0, _util.parseUrl)(forward);

        if (allowedForwards.includes(parsed.hostname.toLowerCase())) {
          if (Ember.get(params, 'login')) {
            window.location.href = (0, _util.addQueryParams)(forward, {
              forwarded: 'true',
              code
            });
          } else {
            oauth.login(forward);
          }
        } else {
          return Ember.RSVP.reject(new Error('Invalid forward url'));
        }

        return;
      }

      if (Ember.get(params, 'errorCode')) {
        let errorMessageKey = Ember.get(params, 'errorMsg') || null;
        let errorMessageTranslation = this.intl.t('loginPage.error.unknown');
        let locale = this.intl.locale || ['en-us'];

        if (errorMessageKey && this.intl.exists(`loginPage.error.${errorMessageKey}`, locale)) {
          errorMessageTranslation = this.intl.t(`loginPage.error.${errorMessageKey}`);
        }

        reply(errorMessageTranslation, Ember.get(params, 'errorCode'));
      }

      const code = Ember.get(params, 'code');
      const state = Ember.get(params, 'state');
      /*
       presence of window.opener indicates this is inital setup popup, not login
       handle auth enable
      */

      if (window.opener) {
        let openersOauth = window.opener.ls('oauth');
        let openerStore = window.opener.ls('globalStore');
        let openersAzure = window.opener.ls('azure-ad');

        if (!state) {
          let provider = Ember.get(params, 'config') || Ember.get(params, 'authProvider');
          let type = `${provider}Config`;
          let config = openerStore.getById(type, provider); // do nothing & close popup for saml

          if (samlProviders.includes(provider)) {
            if (window.opener.window.onAuthTest) {
              reply(null, config);
            } else {
              reply({
                err: 'failure'
              });
            }
          } else if (provider === 'azuread') {
            return azure.testConfig(config).then(resp => {
              azure.test(resp, openersAzure.get('state'));
            }).catch(err => {
              reply({
                err
              });
            });
          } else {
            // redirect to 3rd party login for oauth
            return oauth.testConfig(config).then(resp => {
              oauth.authorize(resp, openersOauth.get('state'));
            }).catch(err => {
              reply({
                err
              });
            });
          } // if state is defined, this route was hit via redirect from 3rd party auth;
          // validate nonce and close window

        } else {
          let stateMsg = 'Authorization state did not match, please try again.';
          let parsedState;

          try {
            parsedState = JSON.parse(oauth.decodeState(state));
          } catch {
            reply({
              err: 'nonce'
            });
          } // handle github/google/azuread


          if (Ember.get(params, 'code')) {
            let openers = openersOauth;

            if (openers.stateMatches(Ember.get(parsedState, 'nonce'))) {
              reply(params.error_description, params.code);
            } else {
              reply(stateMsg);
            } // handle bitbucket

          } else if (Ember.get(params, 'oauth_token') && Ember.get(params, 'oauth_verifier')) {
            reply(null, {
              oauthToken: Ember.get(params, 'oauth_token'),
              oauthVerifier: Ember.get(params, 'oauth_verifier')
            });
          }
        } // handle login verification

      } else {
        let parsedState;

        try {
          parsedState = JSON.parse(oauth.decodeState(state));
        } catch {
          reply({
            err: 'nonce'
          });
        }

        if (oauth.stateMatches(parsedState.nonce)) {
          const providerType = parsedState.provider;
          const currentProvider = Ember.get(this, 'access.providers').findBy('id', providerType);
          return currentProvider.doAction('login', {
            code,
            responseType: 'cookie',
            description: _constants.default.SESSION.DESCRIPTION,
            ttl: _constants.default.SESSION.TTL
          }).then(() => {
            return this.access.detect().then(() => this.transitionTo('authenticated'));
          });
        }
      }

      function reply(err, code) {
        try {
          let cb = window.opener.window.onAuthTest;

          if (window.opener.window.onAzureTest) {
            cb = window.opener.window.onAzureTest;
          }

          cb(err, code);
          setTimeout(() => {
            window.close();
          }, 250);
          return new Ember.RSVP.promise();
        } catch (e) {
          window.close();
        }
      }
    }

  });

  _exports.default = _default;
});