define("ui/authenticated/cluster/security/members/new/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    roleTemplateService: Ember.inject.service('roleTemplate'),

    // need to get all roles, we should have two roles and custom like the global perms
    // cluster owner, cluster-member, custom
    model() {
      const gs = this.globalStore;
      const cid = this.paramsFor('authenticated.cluster');
      return Ember.RSVP.hash({
        cluster: gs.find('cluster', cid.cluster_id, {
          forceReload: true
        }),
        roles: this.roleTemplateService.get('allFilteredRoleTemplates'),
        roleBindings: gs.findAll('clusterRoleTemplateBinding')
      });
    }

  });

  _exports.default = _default;
});