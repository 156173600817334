define("ui/update-password/route", ["exports", "ui/utils/constants", "shared/utils/parse-version"], function (_exports, _constants, _parseVersion) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    access: Ember.inject.service(),
    settings: Ember.inject.service(),
    globalStore: Ember.inject.service(),

    model() {
      let promises = [];

      if (Ember.get(this, 'access.firstLogin')) {
        promises.push(this.globalStore.find('preference'));
        promises.push(this.settings.loadAll());
      }

      return Promise.all(promises).then(() => {
        const cur = Ember.get(this, `settings.${_constants.default.SETTING.TELEMETRY}`);
        const version = Ember.get(this, `settings.${_constants.default.SETTING.VERSION_RANCHER}`);
        let optIn;

        if (!version || (0, _parseVersion.isDevBuild)(version)) {
          // For dev builds, default to opt out
          optIn = cur === 'in';
        } else {
          // For releases, default to opt in
          optIn = cur !== 'out';
        }

        return {
          user: Ember.get(this, 'access.me'),
          code: Ember.get(this, 'access.userCode') || '',
          optIn
        };
      });
    },

    activate() {
      $('BODY').addClass('container-farm'); // eslint-disable-line
    },

    deactivate() {
      $('BODY').removeClass('container-farm'); // eslint-disable-line
    }

  });

  _exports.default = _default;
});