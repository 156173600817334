define("ui/authenticated/project/config-maps/new/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params
    /* , transition */
    ) {
      if (Ember.get(params, 'id')) {
        const record = this.store.getById('configMap', Ember.get(params, 'id'));
        return record.cloneForNew();
      }

      return this.store.createRecord({
        type: 'configMap'
      });
    },

    resetController(controller, isExiting
    /* , transition*/
    ) {
      if (isExiting) {
        Ember.set(controller, 'id', null);
        Ember.set(controller, 'type', null);
      }
    }

  });

  _exports.default = _default;
});