define("ui/models/roletemplate", ["exports", "@rancher/ember-api-store/models/resource", "ui/utils/constants"], function (_exports, _resource, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resource.default.extend({
    router: Ember.inject.service(),
    roleTemplateService: Ember.inject.service('roleTemplate'),
    growl: Ember.inject.service(),
    type: 'roleTemplate',
    canClone: true,
    state: Ember.computed('locked', function () {
      return this.locked ? 'locked' : 'active';
    }),
    isCustom: Ember.computed('id', 'roleTemplateId', function () {
      return !_constants.default.BASIC_ROLE_TEMPLATE_ROLES.includes(this.id);
    }),
    displayName: Ember.computed('name', 'id', function () {
      let name = this.name;

      if (name) {
        return name;
      }

      return `(${this.id})`;
    }),
    canRemove: Ember.computed('links.remove', 'builtin', function () {
      return !!Ember.get(this, 'links.remove') && !this.builtin;
    }),
    actions: {
      edit() {
        this.router.transitionTo('global-admin.security.roles.edit', this.id);
      },

      clone() {
        this.router.transitionTo('global-admin.security.roles.new', {
          queryParams: {
            id: this.id,
            context: this.context
          }
        });
      }

    },

    delete() {
      const self = this;
      const sup = self._super;
      const roleTemplateService = this.roleTemplateService;
      let canDelete = true;
      const roleNames = [];
      return roleTemplateService.fetchFilteredRoleTemplates().then(res => {
        const roleTemplates = res.filter(r => r.canRemove);
        roleTemplates.map(r => {
          const {
            roleTemplateIds = []
          } = r;
          (roleTemplateIds || []).map(id => {
            if (id === this.id) {
              canDelete = false;
              roleNames.pushObject(r.name);
            }
          });
        });

        if (canDelete) {
          return sup.apply(self, arguments);
        } else {
          return this.growl.error(this.intl.t('rolesPage.index.errors.inherited', {
            displayName: this.displayName,
            roleNames: roleNames.join(',')
          }));
        }
      });
    }

  });

  _exports.default = _default;
});