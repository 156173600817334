define("ui/components/container/form-security/component", ["exports", "shared/utils/parse-unit", "shared/utils/util", "ui/components/container/form-security/template"], function (_exports, _parseUnit, _util, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const GPU_KEY = 'nvidia.com/gpu';

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['accordion-wrapper'],
    // Inputs
    instance: null,
    service: null,
    editing: true,
    isSidekick: null,
    // ----------------------------------
    capabilityChoices: null,
    // ----------------------------------
    memoryMode: 'unlimited',
    // unlimited, set
    memoryMb: null,
    // Memory
    memoryReservationMb: null,
    // ----------------------------------
    cpuMode: 'unlimited',
    // unlimited, set
    cpuMillis: null,
    // CPU
    cpuReservationMillis: null,
    // ----------------------------------
    gpuReservation: null,
    limits: Ember.computed.alias('instance.resources.limits'),
    requests: Ember.computed.alias('instance.resources.requests'),

    init() {
      this._super(...arguments);

      if (!Ember.get(this, 'instance.resources')) {
        Ember.set(this, 'instance.resources', {
          requests: {},
          limits: {}
        });
      }

      if (!Ember.get(this, 'instance.resources.requests')) {
        Ember.set(this, 'instance.resources.requests', {});
      }

      if (!Ember.get(this, 'instance.resources.limits')) {
        Ember.set(this, 'instance.resources.limits', {});
      }

      this.initCapability();
      this.initMemory();
      this.initGpu();
      this.initCpu();
    },

    actions: {
      modifyCapabilities(type, select) {
        let options = Array.prototype.slice.call(select.target.options, 0);
        let selectedOptions = [];
        options.filterBy('selected', true).forEach(cap => selectedOptions.push(cap.value));
        Ember.set(this, `instance.${type}`, selectedOptions);
      }

    },
    // ----------------------------------
    privilegedChanged: Ember.observer('instance.privileged', 'instance.capAdd.[]', function () {
      if (Ember.get(this, 'instance.privileged') || Ember.get(this, 'instance.capAdd') && Ember.get(this, 'instance.capAdd').indexOf('SYS_ADMIN') > -1) {
        Ember.set(this, 'instance.allowPrivilegeEscalation', true);
      }
    }),
    memoryDidChange: Ember.observer('memoryMb', 'memoryMode', function () {
      Ember.run.next(this, 'updateMemory');
    }),
    memoryReservationChanged: Ember.observer('memoryReservationMb', function () {
      var mem = this.memoryReservationMb;

      if (isNaN(mem) || mem <= 0) {
        const requests = Ember.get(this, 'instance.resources.requests');
        delete requests['memory'];
      } else {
        Ember.set(this, 'instance.resources.requests.memory', `${mem}Mi`);
      }
    }),
    cpuDidChange: Ember.observer('cpuMillis', 'cpuMode', function () {
      Ember.run.next(this, 'updateCpu');
    }),
    cpuReservationChanged: Ember.observer('cpuReservationMillis', function () {
      var cpu = this.cpuReservationMillis;

      if (isNaN(cpu) || cpu <= 0) {
        const requests = Ember.get(this, 'instance.resources.requests');
        delete requests['cpu'];
      } else {
        Ember.set(this, 'instance.resources.requests.cpu', `${cpu}m`);
      }
    }),
    updateGpu: Ember.observer('gpuReservation', function () {
      var gpu = this.gpuReservation;
      const requests = Ember.get(this, 'instance.resources.requests');
      const limits = Ember.get(this, 'instance.resources.limits');

      if (isNaN(gpu) || gpu <= 0) {
        delete requests[GPU_KEY];
        delete limits[GPU_KEY];
      } else {
        requests[GPU_KEY] = `${gpu}`;
        limits[GPU_KEY] = `${gpu}`;
      }
    }),
    // 2) has CAP_SYS_ADMIN
    allowPrivilegeEscalationDisabled: Ember.computed('instance.privileged', 'instance.capAdd.[]', function () {
      return Ember.get(this, 'instance.privileged') || Ember.get(this, 'instance.capAdd') && Ember.get(this, 'instance.capAdd').indexOf('SYS_ADMIN') > -1;
    }),

    // ----------------------------------
    // Capability
    initCapability() {
      Ember.set(this, 'instance.capAdd', Ember.get(this, 'instance.capAdd') || []);
      Ember.set(this, 'instance.capDrop', Ember.get(this, 'instance.capDrop') || []);
      var choices = this.store.getById('schema', 'container').optionsFor('capAdd').sort();
      Ember.set(this, 'capabilityChoices', choices);
    },

    // ----------------------------------
    // AllowPrivilegeEscalation
    // It is true always when the container is:
    // 1) run as Privileged
    // ----------------------------------
    initMemory() {
      var mem = Ember.get(this, 'instance.resources.limits.memory');
      var memReservation = Ember.get(this, 'instance.resources.requests.memory');

      if (memReservation) {
        Ember.set(this, 'memoryReservationMb', (0, _parseUnit.parseSi)(memReservation, 1024) / 1048576);
      } else {
        Ember.set(this, 'memoryReservationMb', '');
      }

      if (mem) {
        Ember.set(this, 'memoryMb', (0, _parseUnit.parseSi)(mem, 1024) / 1048576);
        Ember.set(this, 'memoryMode', 'set');
      } else {
        Ember.set(this, 'memoryMb', 128);
        Ember.set(this, 'memoryMode', 'unlimited');
      }

      this.updateMemory();
      this.memoryReservationChanged();
    },

    updateMemory() {
      let mem = parseInt(this.memoryMb, 10);
      let memoryMode = this.memoryMode; // Memory

      if (memoryMode === 'unlimited' || isNaN(mem) || mem <= 0) {
        const limits = Ember.get(this, 'instance.resources.limits');
        delete limits['memory'];
        return;
      }

      Ember.set(this, 'instance.resources.limits.memory', `${mem}Mi`);
    },

    // ----------------------------------
    initCpu() {
      var cpu = Ember.get(this, 'instance.resources.limits.cpu');
      var cpuReservation = Ember.get(this, 'instance.resources.requests.cpu');
      Ember.set(this, 'cpuReservationMillis', (0, _util.convertToMillis)(cpuReservation));

      if (cpu) {
        Ember.set(this, 'cpuMillis', (0, _util.convertToMillis)(cpu));
        Ember.set(this, 'cpuMode', 'set');
      } else {
        Ember.set(this, 'cpuMillis', 1000);
        Ember.set(this, 'cpuMode', 'unlimited');
      }

      this.updateCpu();
      this.cpuReservationChanged();
    },

    updateCpu() {
      let cpu = parseInt(this.cpuMillis, 10);
      let cpuMode = this.cpuMode;

      if (cpuMode === 'unlimited' || isNaN(cpu) || cpu <= 0) {
        const limits = Ember.get(this, 'instance.resources.limits');
        delete limits['cpu'];
        return;
      }

      Ember.set(this, 'instance.resources.limits.cpu', `${cpu}m`);
    },

    // ----------------------------------
    // GPU
    initGpu() {
      var gpu = (Ember.get(this, 'instance.resources.limits') || {})[GPU_KEY];
      Ember.set(this, 'gpuReservation', gpu);
      this.updateGpu();
    }

  });

  _exports.default = _default;
});