define("ui/components/settings/theme-toggle/component", ["exports", "ui/utils/constants", "ui/components/settings/theme-toggle/template"], function (_exports, _constants, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    prefs: Ember.inject.service(),
    userTheme: Ember.inject.service('user-theme'),
    layout: _template.default,
    actions: {
      changeTheme(theme) {
        var userTheme = this.userTheme;
        var currentTheme = userTheme.getTheme();

        if (theme !== currentTheme) {
          userTheme.setTheme(theme);
        }
      }

    },
    theme: Ember.computed(`prefs.${_constants.default.PREFS.THEME}`, function () {
      return this.get(`prefs.${_constants.default.PREFS.THEME}`);
    })
  });

  _exports.default = _default;
});