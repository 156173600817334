define("ui/components/cru-secret/component", ["exports", "shared/mixins/view-new-edit", "shared/mixins/optionally-namespaced", "ui/components/cru-secret/template"], function (_exports, _viewNewEdit, _optionallyNamespaced, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_viewNewEdit.default, _optionallyNamespaced.default, {
    layout: _template.default,
    model: null,
    namespace: null,
    namespacedType: 'namespacedSecret',
    projectType: 'secret',
    scope: 'project',
    titleKey: 'newSecret.title',

    init() {
      this._super(...arguments);

      if (Ember.get(this, 'model.type') === 'namespacedSecret') {
        Ember.set(this, 'scope', 'namespace');
      }
    },

    actions: {
      updateData(map) {
        Ember.set(this, 'primaryResource.data', map);
      }

    },

    willSave() {
      let pr = this.primaryResource; // Namespace is required, but doesn't exist yet... so lie to the validator

      let nsId = Ember.get(pr, 'namespaceId');
      Ember.set(pr, 'namespaceId', '__TEMP__');
      let ok = this.validate();
      Ember.set(pr, 'namespaceId', nsId);
      delete pr.kind;
      return ok;
    },

    validate() {
      this._super();

      const errors = this.errors || [];

      if (this.scope !== 'project') {
        errors.pushObjects(this.namespaceErrors || []);
      }

      Ember.set(this, 'errors', errors);
      return errors.length === 0;
    },

    doSave() {
      let self = this;
      let sup = self._super;
      return this.namespacePromise().then(() => sup.apply(self, arguments));
    },

    doneSaving() {
      this.cancel();
    }

  });

  _exports.default = _default;
});