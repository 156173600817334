define("ui/authenticated/cluster/cluster-catalogs/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    scope: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    globalCatalogs: Ember.computed.alias('model.globalCatalogs'),
    filtered: Ember.computed.union('globalCatalogs', 'clusterCatalogs'),
    actions: {
      add() {
        const record = this.globalStore.createRecord({
          type: 'clustercatalog',
          kind: 'helm',
          branch: 'master',
          clusterId: Ember.get(this, 'scope.currentCluster.id')
        });
        this.modalService.toggleModal('modal-edit-catalog', {
          model: record,
          scope: 'cluster'
        });
      }

    },
    clusterCatalogs: Ember.computed('model.clusterCatalogs.@each.{clusterId,id,state}', 'scope.currentCluster.id', function () {
      return Ember.get(this, 'model.clusterCatalogs').filterBy('clusterId', Ember.get(this, 'scope.currentCluster.id'));
    })
  });

  _exports.default = _default;
});