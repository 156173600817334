define("ui/components/container/form-scale/component", ["exports", "ui/components/container/form-scale/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const HISTORY_LIMIT = 10;

  function getDefaultConfig(config) {
    switch (config) {
      case 'deployment':
        return {
          type: 'deploymentConfig',
          revisionHistoryLimit: HISTORY_LIMIT
        };

      case 'daemonSet':
        return {
          type: 'daemonSetConfig',
          revisionHistoryLimit: HISTORY_LIMIT
        };

      case 'replicaSet':
        return {
          type: 'replicaSetConfig'
        };

      case 'replicationController':
        return {
          type: 'replicationControllerConfig'
        };

      case 'statefulSet':
        return {
          type: 'statefulSetConfig',
          podManagementPolicy: 'OrderedReady',
          revisionHistoryLimit: HISTORY_LIMIT,
          volumeClaimTemplates: []
        };

      case 'cronJob':
        return {
          type: 'cronJobConfig',
          concurrencyPolicy: 'Allow',
          failedJobsHistoryLimit: 1,
          schedule: '0 * * * *',
          successfulJobsHistoryLimit: 3,
          jobConfig: {},
          suspend: false
        };

      case 'job':
        return {
          type: 'jobConfig'
        };
    }
  }

  var _default = Ember.Component.extend({
    layout: _template.default,
    initialScale: null,
    isUpgrade: null,
    isGlobal: null,
    min: 0,
    max: 1000,
    scaleMode: null,
    editing: true,
    userInput: null,
    canChangeScaleMode: Ember.computed.not('isUpgrade'),

    init() {
      this._super(...arguments);

      let initial = this.initialScale;

      if (initial === null) {
        initial = 1;
      }

      Ember.set(this, 'userInput', `${initial}`);
      this.scaleModeChanged();

      if (this.scaleMode !== 'deployment' && !this.isUpgrade) {
        Ember.set(this, 'advancedShown', true);
      }
    },

    actions: {
      increase() {
        Ember.set(this, 'userInput', Math.min(this.max, this.asInteger + 1));
      },

      decrease() {
        Ember.set(this, 'userInput', Math.max(this.min, this.asInteger - 1));
      },

      showAdvanced() {
        this.set('advancedShown', true);
      }

    },
    scaleChanged: Ember.observer('asInteger', function () {
      let cur = this.asInteger;
      this.setScale(cur);
    }),
    scaleModeChanged: Ember.observer('scaleMode', function () {
      var scaleMode = this.scaleMode;

      if (!scaleMode || scaleMode === 'sidekick') {
        return;
      }

      const config = `${scaleMode}Config`;
      const workload = this.workload;

      if (!Ember.get(workload, config)) {
        Ember.set(workload, config, this.store.createRecord(getDefaultConfig(scaleMode)));
      }
    }),
    canAdvanced: Ember.computed('advancedShown', 'isUpgrade', 'scaleMode', function () {
      if (this.advancedShown) {
        return false;
      }

      if (this.isUpgrade) {
        return false;
      }

      return true;
    }),
    asInteger: Ember.computed('userInput', function () {
      return parseInt(this.userInput, 10) || 0;
    }),
    canChangeScale: Ember.computed('scaleMode', function () {
      return ['deployment', 'replicaSet', 'daemonSet', 'replicationController', 'statefulSet'].includes(this.scaleMode);
    }),

    setScale() {
      throw new Error('setScale action is required!');
    }

  });

  _exports.default = _default;
});