define("ui/authenticated/project/registries/new/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params
    /* , transition*/
    ) {
      if (Ember.get(params, 'id')) {
        return this.store.find(Ember.get(params, 'type'), Ember.get(params, 'id')).then(cred => cred.cloneForNew());
      }

      return this.store.createRecord({
        type: 'dockerCredential',
        registries: {
          'index.docker.io': {
            username: '',
            password: ''
          }
        }
      });
    },

    resetController(controller, isExiting
    /* , transition*/
    ) {
      if (isExiting) {
        Ember.set(controller, 'id', null);
        Ember.set(controller, 'type', null);
      }
    }

  });

  _exports.default = _default;
});