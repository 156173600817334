define("ui/components/cru-registry/component", ["exports", "shared/mixins/view-new-edit", "shared/mixins/optionally-namespaced", "ui/components/cru-registry/template", "ui/models/dockercredential"], function (_exports, _viewNewEdit, _optionallyNamespaced, _template, _dockercredential) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TEMP_NAMESPACE_ID = '__TEMP__';

  var _default = Ember.Component.extend(_viewNewEdit.default, _optionallyNamespaced.default, {
    globalStore: Ember.inject.service(),
    clusterStore: Ember.inject.service(),
    scopeService: Ember.inject.service('scope'),
    layout: _template.default,
    model: null,
    titleKey: 'cruRegistry.title',
    scope: 'project',
    namespace: null,
    asArray: null,
    projectType: 'dockerCredential',
    namespacedType: 'namespacedDockerCredential',

    init() {
      this._super(...arguments);

      if (Ember.get(this, 'model.type') === 'namespacedDockerCredential') {
        Ember.set(this, 'scope', 'namespace');
        Ember.set(this, 'namespace', Ember.get(this, 'model.namespace'));
      }

      const globalRegistryEnabled = this.globalStore.all('setting').findBy('id', 'global-registry-enabled') || {};
      Ember.set(this, 'globalRegistryEnabled', Ember.get(globalRegistryEnabled, 'value') === 'true');
      let asArray = JSON.parse(JSON.stringify(Ember.get(this, 'model.asArray') || []));

      if (!globalRegistryEnabled && this.mode === 'new') {
        asArray = asArray.map(item => {
          if (item.preset === this.hostname) {
            return { ...item,
              preset: 'custom'
            };
          }

          return item;
        });
      }

      Ember.set(this, 'asArray', asArray);
    },

    arrayChanged: Ember.observer('asArray.@each.{preset,address,username,password,auth}', function () {
      const registries = {};
      this.asArray.forEach(obj => {
        const preset = Ember.get(obj, 'preset');
        let key = Ember.get(obj, 'address');

        if (_dockercredential.PRESETS_BY_NAME[preset]) {
          key = _dockercredential.PRESETS_BY_NAME[preset];
        }

        let val = {};
        ['username', 'password', 'auth'].forEach(k => {
          let v = Ember.get(obj, k);

          if (v) {
            val[k] = v;
          }
        });
        registries[key] = val;
      });
      Ember.set(this, 'model.registries', registries);
      return this._super(...arguments);
    }),
    hostname: window.location.host,

    willSave() {
      const {
        primaryResource: pr
      } = this;
      let tempSet = false;

      if (Ember.isEmpty(Ember.get(pr, 'namespaceId'))) {
        // Namespace is required, but doesn't exist yet... so lie to the validator
        Ember.set(pr, 'namespaceId', TEMP_NAMESPACE_ID);
        tempSet = true;
      }

      let ok = this.validate();

      if (tempSet) {
        // unset temp so that namespacePromise can takeover from here
        Ember.set(pr, 'namespaceId', null);
      }

      return ok;
    },

    validate() {
      this._super();

      const errors = this.errors || [];

      if (this.scope === 'namespace' && Ember.isEmpty(Ember.get(this, 'primaryResource.namespaceId'))) {
        errors.pushObjects(this.namespaceErrors || []);
      }

      Ember.set(this, 'errors', errors);
      return errors.length === 0;
    },

    doSave() {
      let self = this;
      let sup = self._super;
      return this.namespacePromise().then(() => sup.apply(self, arguments));
    },

    doneSaving() {
      if (this.done) {
        this.done();
      }
    }

  });

  _exports.default = _default;
});