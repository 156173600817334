define("ui/components/modal-drain-node/component", ["exports", "shared/mixins/modal-base", "ui/components/modal-drain-node/template", "async"], function (_exports, _modalBase, _template, _async) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modalBase.default, {
    growl: Ember.inject.service(),
    layout: _template.default,
    classNames: ['medium-modal'],
    selection: {},
    resources: Ember.computed.alias('modalService.modalOpts.resources'),

    init() {
      this._super(...arguments);

      this.selection = {};
    },

    actions: {
      drain() {
        const nodeDrainInput = { ...this.selection
        };
        const resources = this.resources.slice();
        (0, _async.eachLimit)(resources, 5, (resource, cb) => {
          if (!resource) {
            return cb();
          }

          resource.doAction('drain', nodeDrainInput).finally(cb);
        });
        this.send('cancel');
      }

    }
  });

  _exports.default = _default;
});