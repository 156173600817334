define("ui/components/volume-source/source-secret/component", ["exports", "ui/components/volume-source/source-secret/template", "shared/mixins/volume-source"], function (_exports, _template, _volumeSource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_volumeSource.default, {
    layout: _template.default,
    field: 'secret',
    specific: false,
    defaultMode: null,
    editing: true,

    didReceiveAttrs() {
      this._super(...arguments);

      if (!!Ember.get(this, 'config.items')) {
        Ember.set(this, 'specific', true);
      }

      const modeStr = Ember.get(this, 'config.defaultMode');

      if (modeStr) {
        Ember.set(this, 'defaultMode', new Number(modeStr).toString(8));
      } else {
        Ember.set(this, 'defaultMode', '400');
      }
    },

    specificDidChange: Ember.observer('specific', function () {
      if (!this.specific) {
        Ember.set(this, 'config.items', null);
      }
    }),
    modeDidChange: Ember.observer('defaultMode', function () {
      const octal = this.defaultMode || '0';
      Ember.set(this, 'config.defaultMode', parseInt(octal, 8));
    })
  });

  _exports.default = _default;
});