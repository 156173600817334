define("ui/authenticated/cluster/notifier/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    modalService: Ember.inject.service('modal'),
    globalStore: Ember.inject.service(),
    scope: Ember.inject.service(),
    queryParams: ['type'],
    currentType: 'slack',
    notifiers: Ember.computed.alias('model.notifiers'),
    actions: {
      showNewEditModal() {
        this.modalService.toggleModal('notifier/modal-new-edit', {
          closeWithOutsideClick: false,
          controller: this,
          currentType: this.currentType,
          mode: 'add'
        });
      }

    }
  });

  _exports.default = _default;
});