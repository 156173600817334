define("ui/authenticated/project/project-catalogs/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    catalog: Ember.inject.service(),

    model() {
      return this.catalog.fetchUnScopedCatalogs();
    },

    resetController(controller, isExiting
    /* , transition*/
    ) {
      if (isExiting) {
        Ember.set(controller, 'istio', false);
      }
    }

  });

  _exports.default = _default;
});