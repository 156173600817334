define("ui/mixins/model-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    router: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    scope: Ember.inject.service(),
    intl: Ember.inject.service(),
    pageScope: Ember.computed.reads('scope.currentPageScope'),
    canClone: false,
    relevantState: Ember.computed('combinedState', 'alertState', 'state', function () {
      if (this.state === 'removing') {
        return 'removing';
      }

      return this.combinedState || this.alertState || 'unknown';
    }),
    isAlertRule: Ember.computed('type', function () {
      return (this.type || '').endsWith('Rule');
    }),

    init() {
      const stateMap = {
        'muted': {
          icon: 'icon icon-tag',
          color: 'text-default'
        },
        'alerting': {
          icon: 'icon icon-tag',
          color: 'text-error'
        },
        'inactive': {
          icon: 'icon icon-tag',
          color: 'text-warning'
        }
      };
      Ember.set(this.constructor, 'stateMap', stateMap);

      this._super(...arguments);
    },

    displayTargetType: Ember.computed('targetType', function () {
      const t = this.targetType;
      const intl = this.intl;
      return intl.t(`alertPage.targetTypes.${t}`);
    }),
    resourceKind: Ember.computed('eventRule.resourceKind', function () {
      const rk = Ember.get(this, 'eventRule.resourceKind');
      return this.intl.t(`alertPage.resourceKinds.${rk}`);
    }),
    firstRecipient: Ember.computed('recipients.length', function () {
      const recipient = (this.recipients || []).get('firstObject');

      if (recipient && Ember.get(recipient, 'notifierId')) {
        const notifierId = Ember.get(recipient, 'notifierId');

        if (!notifierId) {
          return null;
        }

        const notifier = this.globalStore.all('notifier').filterBy('id', notifierId).get('firstObject');

        if (!notifier) {
          return null;
        }

        return `${notifier.get('displayNameAndType')}`;
      }

      return null;
    }),
    displayRecipient: Ember.computed('firstRecipient', 'model.recipients.length', 'recipients.length', function () {
      const len = Ember.get(this, 'recipients.length');
      const firstRecipient = this.firstRecipient;
      const intl = this.intl;
      let out = intl.t('alertPage.na');

      if (len === 0) {// out = na;
      } else if (len === 1) {
        out = firstRecipient;
      } else {
        out = `${len} ${intl.t('alertPage.items')}`;
      }

      return out;
    }),
    nodeName: Ember.computed('targetNode.nodeId', function () {
      const id = Ember.get(this, 'targetNode.nodeId');

      if (!id) {
        return null;
      }

      const node = this.globalStore.all('node').filterBy('id', id).get('firstObject');

      if (!node) {
        return null;
      }

      return node.get('displayName');
    }),
    actions: {
      edit() {
        const ps = this.pageScope;
        const id = this.id;

        if (ps === 'cluster') {
          this.router.transitionTo('authenticated.cluster.alert.edit', id);
        } else if (ps === 'project') {
          this.router.transitionTo('authenticated.project.alert.edit', id);
        }
      },

      mute() {
        return this.doAction('mute');
      },

      unmute() {
        return this.doAction('unmute');
      },

      activate() {
        return this.doAction('activate');
      },

      deactivate() {
        return this.doAction('deactivate');
      }

    },
    availableActions: Ember.computed('actionLinks.{activate,deactivate,mute,unmute}', 'alertState', 'isAlertRule', function () {
      const state = this.alertState;
      const isAlertRule = this.isAlertRule;
      let out = [];

      if (isAlertRule) {
        out = [{
          label: 'action.mute',
          action: 'mute',
          enabled: state === 'alerting',
          icon: 'icon icon-mute',
          bulkable: true
        }, {
          label: 'action.unmute',
          action: 'unmute',
          icon: 'icon icon-unmute',
          enabled: state === 'muted',
          bulkable: true
        }, {
          label: 'action.deactivate',
          action: 'deactivate',
          icon: 'icon icon-pause',
          enabled: state === 'active',
          bulkable: true
        }, {
          label: 'action.activate',
          icon: 'icon icon-play',
          action: 'activate',
          enabled: state === 'inactive',
          bulkable: true
        }];
      }

      return out;
    })
  });

  _exports.default = _default;
});