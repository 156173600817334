define("ui/authenticated/project/secrets/route", ["exports", "ui/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      const store = this.store;
      return Ember.RSVP.hash({
        projectSecrets: store.findAll('secret'),
        namespacedSecrets: store.findAll('namespacedSecret')
      });
    },

    setDefaultRoute: Ember.on('activate', function () {
      Ember.set(this, `session.${_constants.default.SESSION.PROJECT_ROUTE}`, 'authenticated.project.secrets');
    })
  });

  _exports.default = _default;
});