define("ui/components/cru-hpa/component", ["exports", "shared/mixins/view-new-edit", "ui/components/cru-hpa/template", "ui/utils/errors", "shared/mixins/child-hook", "shared/mixins/manage-labels"], function (_exports, _viewNewEdit, _template, _errors, _childHook, _manageLabels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const RESOURCE_METRICS_API_GROUP = 'metrics.k8s.io';

  var _default = Ember.Component.extend(_viewNewEdit.default, _childHook.default, {
    intl: Ember.inject.service(),
    layout: _template.default,
    model: null,
    apiServices: null,
    metrics: Ember.computed.alias('model.metrics'),
    namespace: Ember.computed.alias('model.namespace'),

    didInsertElement() {
      if (Ember.get(this, 'metrics.length') === 0) {
        this.send('addMetric');
      }
    },

    actions: {
      addMetric() {
        const metric = Ember.Object.create({
          type: 'Resource',
          name: 'cpu',
          target: {
            type: 'Utilization',
            value: null
          }
        });
        this.metrics.pushObject(metric);
      },

      removeMetric(metric) {
        this.metrics.removeObject(metric);
      },

      setLabels(labels) {
        Ember.set(this, 'model.labels', (0, _manageLabels.flattenLabelArrays)(labels));
      }

    },
    namespaceDidChange: Ember.observer('deploymentsChoices', function () {
      const deployments = this.deploymentsChoices || [];
      const found = deployments.findBy('id', Ember.get(this, 'model.workloadId'));

      if (!found) {
        Ember.set(this, 'model.workloadId', null);
      }
    }),
    selectedWorkload: Ember.computed('model.workloadId', 'deployments.[]', function () {
      return (this.deployments || []).findBy('id', Ember.get(this, 'model.workloadId'));
    }),
    deploymentsChoices: Ember.computed('namespace.id', 'deployments.[]', function () {
      const namespaceId = Ember.get(this, 'namespace.id');
      return (this.deployments || []).filter(w => Ember.get(w, 'namespaceId') === namespaceId).sortBy('displayName');
    }),
    resourceMetricsAvailable: Ember.computed('apiServices', function () {
      const apiServices = this.apiServices || [];
      return apiServices.find(api => Ember.get(api, 'name').split('.').length === 4 && Ember.get(api, 'name').endsWith(RESOURCE_METRICS_API_GROUP));
    }),

    validate() {
      this._super();

      const intl = this.intl;
      const errors = this.errors || [];

      if (Ember.get(this, 'model.minReplicas') === null) {
        errors.pushObject(intl.t('validation.required', {
          key: intl.t('cruHpa.minReplicas.label')
        }));
      }

      (Ember.get(this, 'model.metrics') || []).forEach(metric => {
        if (Ember.get(metric, 'target.type') === 'Utilization' && !Ember.get(metric, 'target.utilization')) {
          errors.pushObject(intl.t('validation.required', {
            key: intl.t('cruHpa.metrics.value.label')
          }));
        }

        if (Ember.get(metric, 'target.type') === 'AverageValue' && !Ember.get(metric, 'target.averageValue')) {
          errors.pushObject(intl.t('validation.required', {
            key: intl.t('cruHpa.metrics.value.label')
          }));
        }

        if (Ember.get(metric, 'target.type') === 'Value' && !Ember.get(metric, 'target.value')) {
          errors.pushObject(intl.t('validation.required', {
            key: intl.t('cruHpa.metrics.value.label')
          }));
        }
      });
      Ember.set(this, 'errors', errors.uniq());
      return errors.length === 0;
    },

    willSave() {
      Ember.set(this, 'model.namespaceId', Ember.get(this, 'namespace.id') || '__placeholder__');
      const self = this;
      const sup = this._super;
      const errors = [];
      errors.pushObjects(this.namespaceErrors || []);
      Ember.set(this, 'errors', errors);

      if (Ember.get(errors, 'length') !== 0) {
        return false;
      }

      return this.applyHooks('_beforeSaveHooks').then(() => {
        Ember.set(this, 'model.namespaceId', Ember.get(this, 'namespace.id'));
        return sup.apply(self, ...arguments);
      }).catch(err => {
        Ember.set(this, 'errors', [_errors.default.stringify(err)]);
      });
    }

  });

  _exports.default = _default;
});