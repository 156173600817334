define("ui/initializers/extend-ember-link", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize()
  /* application */
  {
    Ember.LinkComponent.reopen({
      attributeBindings: ['tooltip', 'data-placement'],
      // Set activeParent=LI on a {{link-to}} to automatically propagate the active
      // class to the parent element of that tag name (like <li>{{link-to}}</li>)
      activeParent: null,
      addActiveObserver: Ember.on('didInsertElement', function () {
        if (this.activeParent) {
          this.addObserver('active', this, 'activeChanged');
          this.addObserver('application.currentRouteName', this, 'activeChanged');
          this.activeChanged();
        }
      }),

      activeChanged() {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }

        const parent = (0, _jquery.default)().closest(this.activeParent);

        if (!parent || !parent.length) {
          return;
        }

        let active = !!this.active;
        let more = this.currentWhen;

        if (!active && more && more.length) {
          const currentRouteName = Ember.get(this, 'application.currentRouteName');

          for (let i = 0; i < Ember.get(more, 'length'); i++) {
            const entry = more.objectAt(i);

            if (currentRouteName === entry || currentRouteName.startsWith(`${entry}.`)) {
              active = true;
              break;
            }
          }
        }

        parent.toggleClass('active', active);
      }

    });
  }

  var _default = {
    name: 'extend-ember-link',
    initialize
  };
  _exports.default = _default;
});