define("ui/components/modal-edit-node-pool/component", ["exports", "shared/mixins/modal-base", "ui/components/modal-edit-node-pool/template"], function (_exports, _modalBase, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modalBase.default, {
    layout: _template.default,
    classNames: ['large-modal'],
    model: null,
    originalModel: Ember.computed.alias('modalService.modalOpts'),

    init() {
      this._super(...arguments);

      Ember.set(this, 'model', this.originalModel.clone());
    },

    actions: {
      save() {
        Ember.set(this, 'modalService.modalOpts.nodeTaints', Ember.get(this, 'model.nodeTaints') || []);
        this.send('cancel');
      }

    }
  });

  _exports.default = _default;
});