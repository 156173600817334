define("ui/components/modal-view-template-diff/component", ["exports", "jsondiffpatch", "shared/mixins/modal-base", "ui/components/modal-view-template-diff/template"], function (_exports, _jsondiffpatch, _modalBase, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const HIDDEN_FIELDS = ['created', 'createdTS', 'links', 'actionLinks', 'id', 'conditions', 'uuid'];

  function sanitize(config) {
    const result = {};
    Object.keys(config).forEach(key => {
      if (!HIDDEN_FIELDS.includes(key)) {
        result[key] = config[key];
      }
    });
    return result;
  }

  var _default = Ember.Component.extend(_modalBase.default, {
    modalService: Ember.inject.service('modal'),
    intl: Ember.inject.service(),
    layout: _template.default,
    classNames: ['modal-container', 'large-modal', 'large-height', 'alert'],
    diff: '',
    leftName: '',
    rightName: '',

    didReceiveAttrs() {
      const templates = Ember.get(this, 'modalService.modalOpts');

      if (templates.length === 2) {
        const delta = _jsondiffpatch.default.diff(sanitize(templates[0]), sanitize(templates[1]));

        _jsondiffpatch.default.formatters.html.hideUnchanged();

        const diff = _jsondiffpatch.default.formatters.html.format(delta, templates[0]).htmlSafe();

        Ember.set(this, 'diff', diff);
        Ember.set(this, 'leftName', templates[0].name);
        Ember.set(this, 'rightName', templates[1].name);
      } else {
        Ember.set(this, 'diff', this.intl.t('clusterTemplatesPage.compare.error'));
      }
    },

    cancel() {
      this.modalService.toggleModal();
    }

  });

  _exports.default = _default;
});