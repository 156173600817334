define("ui/components/modal-new-volume/component", ["exports", "shared/mixins/modal-base", "ui/components/modal-new-volume/template"], function (_exports, _modalBase, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modalBase.default, {
    layout: _template.default,
    classNames: ['large-modal'],
    editing: true,
    callback: Ember.computed.alias('modalService.modalOpts.callback'),
    model: Ember.computed.alias('modalService.modalOpts.model'),

    init() {
      this._super(...arguments);

      if (!this.model) {
        this.set('model', {});
      }
    },

    actions: {
      doSave() {
        let callback = this.callback;

        if (callback) {
          callback(this.model);
        }

        this.send('cancel');
      }

    }
  });

  _exports.default = _default;
});