define("ui/catalog-tab/index/controller", ["exports", "ui/utils/platform"], function (_exports, _platform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    application: Ember.inject.controller(),
    catalogController: Ember.inject.controller('catalog-tab'),
    queryParams: ['istio'],
    parentRoute: 'catalog-tab',
    launchRoute: 'catalog-tab.launch',
    istio: false,
    category: Ember.computed.alias('catalogController.category'),
    actions: {
      categoryAction(category) {
        this.transitionToRoute(this.launchRoute, {
          queryParams: {
            category
          }
        });
      },

      launch(id, onlyAlternate) {
        if (onlyAlternate && !(0, _platform.isAlternate)(event)) {
          return false;
        }

        if (this.istio) {
          this.transitionToRoute(this.launchRoute, id, {
            queryParams: {
              istio: true
            }
          });
        } else {
          this.transitionToRoute(this.launchRoute, id);
        }
      },

      refresh() {
        let catalogTab = Ember.getOwner(this).lookup('route:catalog-tab');
        catalogTab.send('refresh');
      }

    }
  });

  _exports.default = _default;
});