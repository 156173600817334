define("ui/authenticated/project/security/members/new/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    roleTemplateService: Ember.inject.service('roleTemplate'),

    model() {
      const gs = this.globalStore;
      const pid = this.paramsFor('authenticated.project');
      return Ember.RSVP.hash({
        project: gs.find('project', pid.project_id, {
          forceReload: true
        }),
        roles: this.roleTemplateService.get('allFilteredRoleTemplates'),
        roleBindings: gs.findAll('projectRoleTemplateBinding')
      });
    }

  });

  _exports.default = _default;
});