define("ui/models/globaldnsprovider", ["exports", "@rancher/ember-api-store/models/resource"], function (_exports, _resource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resource.default.extend({
    router: Ember.inject.service(),
    config: null,
    provider: null,
    // I think its safe to hack around this - wjw
    _displayState: 'active',
    // because of this the state shows as "Unknown" with bright yellow background
    stateColor: 'text-success',

    init() {
      this._super(...arguments);

      if (this.route53ProviderConfig) {
        Ember.setProperties(this, {
          config: Ember.computed.alias('route53ProviderConfig'),
          provider: 'route53'
        });
      }

      if (this.cloudflareProviderConfig) {
        Ember.setProperties(this, {
          config: Ember.computed.alias('cloudflareProviderConfig'),
          provider: 'cloudflare'
        });
      }

      if (this.alidnsProviderConfig) {
        Ember.setProperties(this, {
          config: Ember.computed.alias('alidnsProviderConfig'),
          provider: 'alidns'
        });
      }
    },

    canEdit: Ember.computed('links.update', function () {
      return !!Ember.get(this, 'links.update');
    }),
    canRemove: Ember.computed('links.remove', function () {
      return !!Ember.get(this, 'links.remove');
    }),
    actions: {
      edit() {
        this.router.transitionTo('global-admin.global-dns.providers.new', {
          queryParams: {
            id: this.id
          }
        });
      }

    }
  });

  _exports.default = _default;
});