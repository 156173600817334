define("ui/models/kontainerdriver", ["exports", "@rancher/ember-api-store/models/resource"], function (_exports, _resource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var KontainerDriver = _resource.default.extend({
    intl: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    type: 'kontainerDriver',
    availableActions: Ember.computed('actionLinks.{activate,deactivate}', function () {
      let a = this.actionLinks || {};
      return [{
        label: 'action.activate',
        icon: 'icon icon-play',
        action: 'activate',
        enabled: !!a.activate,
        bulkable: true
      }, {
        label: 'action.deactivate',
        icon: 'icon icon-pause',
        action: 'promptDeactivate',
        enabled: !!a.deactivate,
        bulkable: true,
        altAction: 'deactivate'
      }];
    }),
    displayName: Ember.computed('id', 'intl.locale', 'name', function () {
      const intl = this.intl;
      const name = this.name;
      const keyByName = `kontainerDriver.displayName.${name}`;
      const keyById = `kontainerDriver.displayName.${this.id}`;

      if (name && intl.exists(keyByName)) {
        return intl.t(keyByName);
      }

      if (intl.exists(keyById)) {
        return intl.t(keyById);
      } else if (name) {
        return name.capitalize();
      } else {
        return `(${this.id})`;
      }
    }),
    canEdit: Ember.computed('links.update', 'builtin', function () {
      return !!Ember.get(this, 'links.update') && !this.builtin;
    }),
    hasUi: Ember.computed('hasBuiltinUi', 'uiUrl', function () {
      return !!this.uiUrl;
    }),
    actions: {
      activate() {
        return this.doAction('activate');
      },

      deactivate() {
        return this.doAction('deactivate');
      },

      edit() {
        this.modalService.toggleModal('modal-edit-driver', this);
      },

      promptDeactivate() {
        this.modalService.toggleModal('modal-confirm-deactivate', {
          originalModel: this,
          action: 'deactivate'
        });
      }

    }
  });

  var _default = KontainerDriver;
  _exports.default = _default;
});