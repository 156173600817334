define("ui/components/new-edit-ingress/component", ["exports", "shared/mixins/new-or-edit", "ui/components/new-edit-ingress/template"], function (_exports, _newOrEdit, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_newOrEdit.default, {
    intl: Ember.inject.service(),
    scope: Ember.inject.service(),
    layout: _template.default,
    ingress: null,
    editing: null,
    existing: null,
    namespacedCertificates: null,
    certificates: null,
    isGKE: Ember.computed.alias('scope.currentCluster.isGKE'),
    primaryResource: Ember.computed.alias('ingress'),

    init() {
      this._super(...arguments);

      if (this.existing) {
        Ember.set(this, 'namespace', Ember.get(this, 'existing.namespace'));
      }
    },

    actions: {
      done() {
        if (this.done) {
          this.done();
        }
      },

      cancel() {
        if (this.cancel) {
          this.cancel();
        }
      },

      setLabels(labels) {
        let out = {};
        labels.forEach(row => {
          out[row.key] = row.value;
        });
        Ember.set(this, 'ingress.labels', out);
      }

    },
    headerLabel: Ember.computed('intl.locale', 'existing', function () {
      let k;

      if (this.existing) {
        k = 'newIngress.header.edit';
      } else {
        k = 'newIngress.header.add';
      }

      return this.intl.t(k);
    }),

    willSave() {
      let pr = this.primaryResource; // Namespace is required, but doesn't exist yet... so lie to the validator

      let nsId = Ember.get(pr, 'namespaceId');
      Ember.set(pr, 'namespaceId', '__TEMP__');
      let ok = this.validate();
      Ember.set(pr, 'namespaceId', nsId);
      return ok;
    },

    doSave() {
      let pr = this.primaryResource;
      let namespacePromise = Ember.RSVP.resolve();

      if (!this.existing) {
        // Set the namespace ID
        if (Ember.get(this, 'namespace.id')) {
          Ember.set(pr, 'namespaceId', Ember.get(this, 'namespace.id'));
        } else if (this.namespace) {
          namespacePromise = this.namespace.save().then(newNamespace => {
            Ember.set(pr, 'namespaceId', Ember.get(newNamespace, 'id'));
            return newNamespace.waitForState('active');
          });
        }
      }

      let self = this;
      let sup = self._super;
      return namespacePromise.then(() => sup.apply(self, arguments));
    },

    validate() {
      let intl = this.intl;
      let pr = this.primaryResource;
      let errors = pr.validationErrors() || [];
      errors.pushObjects(this.namespaceErrors || []);
      errors.pushObjects(this.certErrors || []);

      if (!Ember.get(this, 'ingress.rules.length') && !Ember.get(this, 'ingress.defaultBackend')) {
        errors.push(intl.t('newIngress.error.noRules'));
      }

      if (Ember.get(this, 'ingress.rules.length')) {
        const invalid = Ember.get(this, 'ingress.rules').some(rule => {
          const paths = [];
          Object.keys(rule.paths).forEach(key => {
            paths.push(rule.paths[key]);
          });
          return paths.some(path => !path.targetPort);
        });

        if (invalid) {
          errors.push(intl.t('validation.required', {
            key: intl.t('generic.port')
          }));
        }
      }

      if (errors.length) {
        Ember.set(this, 'errors', errors.uniq());
        return false;
      }

      return true;
    },

    doneSaving() {
      this._super(...arguments);

      this.send('done');
    }

  });

  _exports.default = _default;
});