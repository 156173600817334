define("ui/components/container/new-edit/component", ["exports", "ui/utils/errors", "shared/mixins/new-or-edit", "ui/utils/constants", "shared/mixins/child-hook", "ui/components/container/new-edit/template", "jquery"], function (_exports, _errors, _newOrEdit, _constants, _childHook, _template, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const WINDOWS_NODE_SELECTOR = 'beta.kubernetes.io/os = windows';
  const LINUX_NODE_SELECTOR = 'beta.kubernetes.io/os != windows';
  const LINUX = 'linux';
  const WINDOWS = 'windows';

  var _default = Ember.Component.extend(_newOrEdit.default, _childHook.default, {
    clusterStore: Ember.inject.service(),
    intl: Ember.inject.service(),
    prefs: Ember.inject.service(),
    scope: Ember.inject.service(),
    settings: Ember.inject.service(),
    layout: _template.default,
    tagName: 'form',
    isUpgrade: false,
    service: null,
    launchConfig: null,
    launchConfigIndex: null,
    namespace: null,
    scale: 1,
    scaleMode: null,
    serviceLinksArray: null,
    isRequestedHost: null,
    upgradeOptions: null,
    separateLivenessCheck: false,
    targetOs: WINDOWS,
    // Errors from components
    commandErrors: null,
    volumeErrors: null,
    networkingErrors: null,
    secretsErrors: null,
    readyCheckErrors: null,
    liveCheckErrors: null,
    schedulingErrors: null,
    securityErrors: null,
    scaleErrors: null,
    imageErrors: null,
    portErrors: null,
    namespaceErrors: null,
    labelErrors: null,
    annotationErrors: null,
    // ----------------------------------
    advanced: false,
    header: '',
    showTargetOS: false,
    isSidekick: Ember.computed.equal('scaleMode', 'sidekick'),

    init() {
      window.nec = this;

      this._super(...arguments);

      if (this.launchConfig && !Ember.get(this, 'launchConfig.environmentFrom')) {
        Ember.set(this, 'launchConfig.environmentFrom', []);
      }

      const service = this.service;
      const scheduling = Ember.get(service, 'scheduling');

      if (!this.isSidekick && !Ember.get(service, 'scheduling.node')) {
        Ember.set(service, 'scheduling', { ...scheduling,
          node: {}
        });
      }

      if (!this.isSidekick) {
        Ember.setProperties(this, {
          description: Ember.get(this, 'service.description'),
          scale: Ember.get(this, 'service.scale'),
          scheduling: Ember.get(this, 'service.scheduling')
        });
      } else {
        Ember.set(this, 'description', Ember.get(this, 'launchConfig.description'));
      }

      Ember.set(this, 'name', Ember.get(this, 'launchConfig.name'));
      let namespaceId = null;
      namespaceId = Ember.get(this, 'service.namespaceId');

      if (namespaceId) {
        let namespace = this.clusterStore.getById('namespace', namespaceId);

        if (namespace) {
          Ember.set(this, 'namespace', namespace);
        }
      }

      if (!this.separateLivenessCheck) {
        const ready = Ember.get(this, 'launchConfig.readinessProbe');
        const live = Ember.get(this, 'launchConfig.livenessProbe');
        const readyStr = JSON.stringify(ready);
        const liveStr = JSON.stringify(live);

        if (readyStr !== liveStr) {
          Ember.set(this, 'separateLivenessCheck', true);
        }
      }

      if (this.showTargetOS && Ember.get(this, `prefs.${_constants.default.PREFS.TARGET_OS}`)) {
        Ember.set(this, 'targetOs', Ember.get(this, `prefs.${_constants.default.PREFS.TARGET_OS}`));
      }
    },

    didInsertElement() {
      const input = (0, _jquery.default)("INPUT[type='text']")[0];

      if (input) {
        input.focus();
      }
    },

    actions: {
      setTargetOs(os) {
        Ember.set(this, 'targetOs', os);
      },

      setImage(uuid) {
        Ember.set(this, 'launchConfig.image', uuid);
      },

      setRequestedHostId(hostId) {
        Ember.set(this, 'launchConfig.requestedHostId', hostId);
      },

      setUpgrade(upgrade) {
        Ember.set(this, 'upgradeOptions', upgrade);
      },

      done() {
        if (this.done) {
          this.done();
        }
      },

      cancel() {
        if (this.cancel) {
          this.cancel();
        }
      },

      toggleSeparateLivenessCheck() {
        Ember.set(this, 'separateLivenessCheck', !this.separateLivenessCheck);
      },

      removeSidekick(idx) {
        var ary = Ember.get(this, 'primaryService.secondaryLaunchConfigs');
        ary.removeAt(idx);
      }

    },
    updateHeader: Ember.on('init', Ember.observer('isUpgrade', 'isSidekick', 'isGlobal', 'service.displayName', 'intl.locale', function () {
      let args = {};
      let k = 'newContainer.';
      k += `${this.isUpgrade ? 'upgrade' : 'add'}.`;

      if (this.isSidekick) {
        let svc = this.service;

        if (svc && Ember.get(svc, 'id')) {
          k += 'sidekickName';
          args = {
            name: Ember.get(this, 'service.displayName')
          };
        } else {
          k += 'sidekick';
        }
      } else if (this.isGlobal) {
        k += 'globalService';
      } else {
        k += 'service';
      }

      Ember.run.next(() => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }

        Ember.set(this, 'header', this.intl.t(k, args));
      });
    })),

    // ----------------------------------
    // ----------------------------------
    // Save
    // ----------------------------------
    validate() {
      let pr = this.primaryResource;
      let errors = pr.validationErrors() || [];
      const lc = this.launchConfig;
      const quotaErrors = lc.validateQuota(this.namespace);
      errors.pushObjects(quotaErrors);

      if (Ember.get(quotaErrors, 'length') > 0) {
        Ember.setProperties(this, {
          advanced: true,
          securitySectionExpanded: true
        });
      }

      (Ember.get(this, 'service.secondaryLaunchConfigs') || []).forEach(slc => {
        slc.validationErrors().forEach(err => {
          errors.push(`${Ember.get(slc, 'displayName')}: ${err}`);
        });
      }); // Errors from components

      errors.pushObjects(this.commandErrors || []);
      errors.pushObjects(this.volumeErrors || []);
      errors.pushObjects(this.networkingErrors || []);
      errors.pushObjects(this.secretsErrors || []);
      errors.pushObjects(this.readyCheckErrors || []);
      errors.pushObjects(this.liveCheckErrors || []);
      errors.pushObjects(this.schedulingErrors || []);
      errors.pushObjects(this.securityErrors || []);
      errors.pushObjects(this.scaleErrors || []);
      errors.pushObjects(this.imageErrors || []);
      errors.pushObjects(this.portErrors || []);
      errors.pushObjects(this.namespaceErrors || []);
      errors.pushObjects(this.labelErrors || []);
      errors.pushObjects(this.annotationErrors || []);
      errors = errors.uniq();

      if (Ember.get(errors, 'length')) {
        Ember.set(this, 'errors', errors);

        if (this.isSidekick && !this.isUpgrade) {
          Ember.get(pr, 'secondaryLaunchConfigs').pop();
        }

        return false;
      }

      Ember.set(this, 'errors', null);
      return true;
    },

    willSave() {
      let intl = this.intl;
      let pr;
      let nameResource;
      let lc = this.launchConfig;
      let name = (this.name || '').trim().toLowerCase();
      let service = this.service;
      let readinessProbe = Ember.get(lc, 'readinessProbe');

      if (this.showTargetOS && (this.targetOs === LINUX || this.targetOs === WINDOWS)) {
        const selector = this.targetOs === WINDOWS ? WINDOWS_NODE_SELECTOR : LINUX_NODE_SELECTOR;

        if (!Ember.get(service, 'scheduling')) {
          Ember.set(service, 'scheduling', {
            node: {
              requireAll: [selector]
            }
          });
        } else if (!Ember.get(service, 'scheduling.node')) {
          Ember.set(service, 'scheduling.node', {
            requireAll: [selector]
          });
        } else if (!Ember.get(service, 'scheduling.node.requireAll')) {
          Ember.set(service, 'scheduling.node.requireAll', [selector]);
        } else {
          const requireAll = Ember.get(service, 'scheduling.node.requireAll') || [];
          const found = requireAll.find(r => r && r.replace(/\s+/g, '') === selector.replace(/\s+/g, ''));

          if (!found) {
            requireAll.push(selector);
          }
        }
      }

      if (!this.separateLivenessCheck) {
        if (readinessProbe) {
          const livenessProbe = Object.assign({}, readinessProbe);
          Ember.set(livenessProbe, 'successThreshold', 1);
          Ember.set(lc, 'livenessProbe', livenessProbe);
        } else {
          Ember.set(lc, 'livenessProbe', null);
        }
      }

      const uid = Ember.get(lc, 'uid');

      if (uid === '') {
        Ember.set(lc, 'uid', null);
      }

      if (this.isSidekick) {
        let errors = [];

        if (!service) {
          errors.push(this.intl.t('newContainer.errors.noSidekick'));
          Ember.set(this, 'errors', errors);
          return false;
        }

        if (!name) {
          errors.push(intl.t('validation.required', {
            key: intl.t('formNameDescription.name.label')
          }));
          Ember.set(this, 'errors', errors);
          return false;
        }

        pr = service;
        nameResource = lc;
        let slc = Ember.get(pr, 'secondaryLaunchConfigs');

        if (!slc) {
          slc = [];
          Ember.set(pr, 'secondaryLaunchConfigs', slc);
        }

        let lci = this.launchConfigIndex;

        if (lci === undefined || lci === null) {
          // If it's a new sidekick, add it to the end of the list
          lci = slc.length;
        } else {
          lci = parseInt(lci, 10);
        }

        let duplicate = pr.containers.find((x, idx) => idx !== lci + 1 && Ember.get(x, 'name').toLowerCase() === name);

        if (duplicate) {
          errors.push(intl.t('newContainer.errors.duplicateName', {
            name,
            service: Ember.get(duplicate, 'displayName')
          }));
          Ember.set(this, 'errors', errors);
          return false;
        }

        slc[lci] = lc;
        Ember.set(lc, 'name', name);
        Ember.set(pr, 'containers', [pr.containers[0]]);
        pr.containers.pushObjects(slc);
      } else {
        service.clearConfigsExcept(`${this.scaleMode}Config`);

        if (this.scaleMode === 'statefulSet' && !Ember.get(service, 'statefulSetConfig.serviceName')) {
          Ember.set(service, 'statefulSetConfig.serviceName', name);
        }

        pr = service;
        nameResource = pr;
        Ember.set(pr, 'scale', this.scale);
        const containers = Ember.get(pr, 'containers');

        if (!containers) {
          Ember.set(pr, 'containers', []);
        } else {
          Ember.set(lc, 'name', name);
          containers[0] = lc;
        }
      }

      nameResource.setProperties({
        name,
        description: this.description
      });
      Ember.set(this, 'primaryResource', pr);
      Ember.set(this, 'originalPrimaryResource', pr);
      let errors = [];

      if (!Ember.get(this, 'namespace.name')) {
        errors.push(intl.t('validation.required', {
          key: intl.t('generic.namespace')
        }));
        Ember.set(this, 'errors', errors);
        return false;
      }

      Ember.set(pr, 'namespaceId', Ember.get(this, 'namespace.id') || '__placeholder__');
      const self = this;
      const sup = this._super;
      pr.updateTimestamp();
      return this.applyHooks('_beforeSaveHooks').then(() => {
        Ember.set(pr, 'namespaceId', Ember.get(this, 'namespace.id'));
        return this.applyHooks('_volumeHooks').then(() => sup.apply(self, ...arguments)).catch(err => {
          Ember.set(this, 'errors', [_errors.default.stringify(err)]);
        });
      }).catch(err => {
        Ember.set(this, 'errors', [_errors.default.stringify(err)]);
      });
    },

    doneSaving() {
      if (!this.isUpgrade) {
        let scaleMode = this.scaleMode;

        if (scaleMode === 'sidekick') {
          // Remember sidekick as service since you're not
          // likely to want to add many sidekicks in a row
          scaleMode = 'deployment';
        }

        Ember.set(this, `prefs.${_constants.default.PREFS.LAST_SCALE_MODE}`, scaleMode);
        Ember.set(this, `prefs.${_constants.default.PREFS.LAST_IMAGE_PULL_POLICY}`, Ember.get(this, 'launchConfig.imagePullPolicy'));
        Ember.set(this, `prefs.${_constants.default.PREFS.LAST_NAMESPACE}`, Ember.get(this, 'namespace.id'));

        if (this.showTargetOS) {
          Ember.set(this, `prefs.${_constants.default.PREFS.TARGET_OS}`, this.targetOs);
        }
      }

      if (this.done) {
        this.done();
      }
    }

  });

  _exports.default = _default;
});