define("ui/components/cru-storage-class/component", ["exports", "shared/mixins/view-new-edit", "ui/components/cru-storage-class/template", "ui/models/storageclass", "shared/mixins/child-hook", "ui/utils/constants"], function (_exports, _viewNewEdit, _template, _storageclass, _childHook, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const WAIT_FOR_FIRST_CONSUMER = 'WaitForFirstConsumer';
  const IMMEDIATE = 'Immediate';
  const LOCAL_STORAGE = 'kubernetes.io/no-provisioner';
  const {
    LONGHORN_PROVISIONER_KEY
  } = _constants.default.STORAGE;

  var _default = Ember.Component.extend(_viewNewEdit.default, _childHook.default, {
    intl: Ember.inject.service(),
    features: Ember.inject.service(),
    layout: _template.default,
    model: null,
    longhornProvisionerKey: LONGHORN_PROVISIONER_KEY,
    titleKey: 'cruStorageClass.title',

    didReceiveAttrs() {
      Ember.set(this, 'wasRecycle', Ember.get(this, 'primaryResource.reclaimPolicy') === 'Recycle');
    },

    actions: {
      updateParams(map) {
        Ember.set(this, 'primaryResource.parameters', map);
      },

      updateOptions(ary) {
        Ember.set(this, 'primaryResource.mountOptions', ary);
      }

    },
    provisionerChanged: Ember.observer('primaryResource.provisioner', function () {
      const provisioner = Ember.get(this, 'primaryResource.provisioner');

      if (this.isNew) {
        Ember.set(this, 'primaryResource.volumeBindingMode', provisioner === LOCAL_STORAGE ? WAIT_FOR_FIRST_CONSUMER : IMMEDIATE);
      }

      if (provisioner === this.longhornProvisionerKey) {
        Ember.set(this, 'primaryResource.allowVolumeExpansion', true);
      } else {
        Ember.set(this, 'primaryResource.allowVolumeExpansion', false);
      }
    }),
    paramsComponent: Ember.computed('primaryResource.provisioner', function () {
      const provisioner = Ember.get(this, 'primaryResource.provisioner');
      const entry = (0, _storageclass.getProvisioners)().findBy('value', provisioner);
      let component = 'generic';

      if (entry && entry.component) {
        component = entry.component;
      }

      return `storage-class/provisioner-${component}`;
    }),
    provisionerChoices: Ember.computed('intl.locale', function () {
      const intl = this.intl;
      const out = (0, _storageclass.getProvisioners)().map(p => {
        const entry = Object.assign({}, p);
        const key = `storageClass.${entry.name}.title`;

        if (intl.exists(key)) {
          entry.label = intl.t(key);
          entry.priority = 1;
        } else {
          entry.label = entry.name;
          entry.priority = 2;
        }

        return entry;
      });
      return out.sortBy('priority', 'label');
    }),
    supportedProvisionerChoices: Ember.computed('provisionerChoices', function () {
      const showUnsupported = this.features.isFeatureEnabled(_constants.default.FEATURES.UNSUPPORTED_STORAGE_DRIVERS);
      return this.provisionerChoices.filter(choice => showUnsupported || choice.supported);
    }),

    willSave() {
      const self = this;
      const sup = this._super;
      return this.applyHooks().then(() => sup.apply(self, ...arguments));
    },

    doneSaving() {
      if (this.done) {
        this.done();
      }
    }

  });

  _exports.default = _default;
});