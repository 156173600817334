define("ui/models/notifier", ["exports", "@rancher/ember-api-store/models/resource", "ui/utils/constants", "moment"], function (_exports, _resource, _constants, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resource.default.extend({
    growl: Ember.inject.service(),
    intl: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    type: 'notifier',
    displayNameAndType: Ember.computed('displayName', 'notifierType', function () {
      const upperCaseType = (this.notifierType || '').replace(/^\S/, s => {
        return s.toUpperCase();
      });
      return `${this.displayName} (${upperCaseType})`;
    }),
    notifierTableLabel: Ember.computed('dingtalkConfig', 'emailConfig', 'msteamsConfig', 'pagerdutyConfig', 'slackConfig', 'smtpConfig', 'webhookConfig', 'wechatConfig', function () {
      const sc = this.slackConfig;
      const pc = this.pagerdutyConfig;
      const ec = this.smtpConfig;
      const wc = this.webhookConfig;
      const wcc = this.wechatConfig;
      const dtc = this.dingtalkConfig;
      const msc = this.msteamsConfig;

      if (sc) {
        return _constants.default.NOTIFIER_TABLE_LABEL.SLACK;
      }

      if (pc) {
        return _constants.default.NOTIFIER_TABLE_LABEL.PAGERDUTY;
      }

      if (ec) {
        return _constants.default.NOTIFIER_TABLE_LABEL.SMTP;
      }

      if (wc) {
        return _constants.default.NOTIFIER_TABLE_LABEL.WEBHOOK;
      }

      if (wcc) {
        return _constants.default.NOTIFIER_TABLE_LABEL.WECHAT;
      }

      if (dtc) {
        return _constants.default.NOTIFIER_TABLE_LABEL.DINGTALK;
      }

      if (msc) {
        return _constants.default.NOTIFIER_TABLE_LABEL.MICROSOFTTEAMS;
      }

      return _constants.default.NOTIFIER_TABLE_LABEL.DEFAULT;
    }),
    notifierType: Ember.computed('dingtalkConfig', 'emailConfig', 'msteamsConfig', 'pagerdutyConfig', 'slackConfig', 'smtpConfig', 'webhookConfig', 'wechatConfig', function () {
      const sc = this.slackConfig;
      const pc = this.pagerdutyConfig;
      const ec = this.smtpConfig;
      const wc = this.webhookConfig;
      const wcc = this.wechatConfig;
      const dtc = this.dingtalkConfig;
      const msc = this.msteamsConfig;

      if (sc) {
        return 'slack';
      }

      if (pc) {
        return 'pagerduty';
      }

      if (ec) {
        return 'email';
      }

      if (wc) {
        return 'webhook';
      }

      if (wcc) {
        return 'wechat';
      }

      if (dtc) {
        return 'dingtalk';
      }

      if (msc) {
        return 'msteams';
      }

      return null;
    }),
    notifierValue: Ember.computed('emailConfig', 'pagerdutyConfig', 'slackConfig', 'smtpConfig', 'webhookConfig', 'wechatConfig', function () {
      const sc = this.slackConfig;
      const pc = this.pagerdutyConfig;
      const ec = this.smtpConfig;
      const wc = this.webhookConfig;
      const wcc = this.wechatConfig;

      if (sc) {
        return Ember.get(sc, 'defaultRecipient');
      }

      if (pc) {
        return Ember.get(pc, 'serviceKey');
      }

      if (ec) {
        return Ember.get(ec, 'defaultRecipient');
      }

      if (wc) {
        return Ember.get(wc, 'url');
      }

      if (wcc) {
        return Ember.get(wcc, 'defaultRecipient');
      }

      return '';
    }),
    displayCreated: Ember.computed('created', function () {
      const d = this.created;
      return (0, _moment.default)(d).fromNow();
    }),
    notifierLabel: Ember.computed('emailConfig', 'pagerdutyConfig', 'slackConfig', 'smtpConfig', 'webhookConfig', 'wechartConfig', 'wechatConfig', function () {
      const sc = this.slackConfig;
      const pc = this.pagerdutyConfig;
      const ec = this.smtpConfig;
      const wc = this.webhookConfig;
      const wcc = this.wechatConfig;

      if (sc) {
        return 'Channel';
      }

      if (pc) {
        return 'Service Key';
      }

      if (ec) {
        return 'Address';
      }

      if (wc) {
        return 'URL';
      }

      if (wcc) {
        return 'Recipient';
      }

      return 'Notifier';
    }),

    findAlerts() {
      const globalStore = this.globalStore;
      const clusterId = this.clusterId;
      const clusterAlertGroups = globalStore.find('clusterAlertGroup', null, {
        filter: {
          clusterId
        }
      });
      const projectAlertGroups = globalStore.findAll('projectAlertGroup');
      return Ember.RSVP.hash({
        clusterAlertGroups,
        projectAlertGroups
      }).then(({
        clusterAlertGroups,
        projectAlertGroups
      }) => {
        const alerts = [...clusterAlertGroups.content, ...projectAlertGroups.content].filter(alert => {
          const recipients = Ember.get(alert, 'recipients');

          if (!recipients || recipients.length === 0) {
            return false;
          }

          return recipients.some(recipient => recipient.notifierId === this.id);
        });
        return alerts;
      });
    },

    delete() {
      const self = this;
      const sup = self._super;
      return this.findAlerts().then(alerts => {
        if (alerts.length) {
          const alertNames = alerts.map(alert => Ember.get(alert, 'displayName')).join(',');
          this.growl.error(this.intl.t('notifierPage.deleteErrorMessage', {
            displayName: this.displayName,
            alertNames
          }));
        } else {
          sup.apply(self, arguments);
        }
      });
    },

    actions: {
      edit() {
        this.modalService.toggleModal('notifier/modal-new-edit', {
          closeWithOutsideClick: false,
          currentType: this.notifierType,
          model: this,
          mode: 'edit'
        });
      },

      clone() {
        const nue = this.clone();
        Ember.setProperties(nue, {
          id: null,
          name: null
        });
        this.modalService.toggleModal('notifier/modal-new-edit', {
          closeWithOutsideClick: false,
          currentType: this.notifierType,
          model: nue,
          mode: 'clone'
        });
      }

    }
  });

  _exports.default = _default;
});