define("ui/models/credential", ["exports", "@rancher/ember-api-store/models/resource"], function (_exports, _resource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resource.default.extend({
    router: Ember.inject.service(),
    canClone: true,
    actions: {
      clone() {
        this.router.transitionTo('authenticated.project.registries.new', null, {
          queryParams: {
            id: this.id
          }
        });
      }

    }
  });

  _exports.default = _default;
});