define("ui/components/input-random-port/component", ["exports", "ui/components/input-random-port/template", "jquery"], function (_exports, _template, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    showEdit: false,
    min: '1',
    max: '65535',
    value: null,
    standardKey: 'generic.random',
    placeholder: null,

    init() {
      this._super(...arguments);

      if (this.value) {
        Ember.set(this, 'showEdit', true);
      }
    },

    actions: {
      showEdit() {
        Ember.set(this, 'showEdit', true);
        Ember.run.next(() => {
          if (this.isDestroyed || this.isDestroying) {
            return;
          }

          (0, _jquery.default)('INPUT').last()[0].focus();
        });
      }

    }
  });

  _exports.default = _default;
});