define("ui/models/setting", ["exports", "@rancher/ember-api-store/models/resource", "ui/utils/constants"], function (_exports, _resource, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resource.default.extend({
    modalService: Ember.inject.service('modal'),
    settings: Ember.inject.service(),
    type: 'setting',
    canRemove: false,
    isDefault: Ember.computed('value', 'default', function () {
      return this['default'] === this.value;
    }),
    canRevert: Ember.computed('default', 'isDefault', function () {
      return !Ember.isEmpty(this['default']) && !this.isDefault;
    }),
    canEdit: Ember.computed('links.update', 'id', function () {
      const id = this.id;
      return !!Ember.get(this, 'links.update') && id !== 'cacerts';
    }),
    availableActions: Ember.computed('actionLinks.{remove,update}', 'canRevert', function () {
      return [{
        label: 'action.revert',
        icon: 'icon icon-history',
        action: 'revert',
        enabled: this.canRevert,
        altAction: 'bypassRevert'
      }];
    }),
    allowed: _constants.default.SETTING.ALLOWED,
    actions: {
      edit() {
        let key = this.id;
        let obj = this.settings.findByName(key);
        let details = this.allowed[key];
        this.modalService.toggleModal('modal-edit-setting', {
          key,
          descriptionKey: `dangerZone.description.${this.id}`,
          kind: details.kind,
          options: details.options,
          canDelete: obj && !obj.get('isDefault'),
          obj
        });
      },

      revert() {
        let key = this.id;
        let details = this.allowed[key];
        this.modalService.toggleModal('modal-revert-setting', {
          setting: this,
          kind: details.kind
        });
      },

      bypassRevert() {
        Ember.set(this, 'value', this['default'] || '');
        this.save();
      }

    },

    delete() {
      return this._super().then(res => {
        Ember.run.later(this, 'reload', 500);
        return res;
      });
    }

  });

  _exports.default = _default;
});