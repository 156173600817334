define("ui/authenticated/controller", ["exports", "ui/utils/constants", "shared/utils/util"], function (_exports, _constants, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    settings: Ember.inject.service(),
    prefs: Ember.inject.service(),
    scope: Ember.inject.service(),
    application: Ember.inject.controller(),
    error: null,
    isPopup: Ember.computed.alias('application.isPopup'),
    pageScope: Ember.computed.alias('scope.currentPageScope'),
    hasHosts: Ember.computed.gt('model.hosts.length', 0),
    bootstrap: Ember.on('init', function () {
      Ember.run.schedule('afterRender', this, () => {
        this.application.setProperties({
          error: null,
          error_description: null,
          state: null
        });
        let bg = this.get(`prefs.${_constants.default.PREFS.BODY_BACKGROUND}`);

        if (bg) {
          $('BODY').css('background', bg); // eslint-disable-line
        } // Add class to hide Page Header and Footer when embedded


        if ((0, _util.isEmbedded)()) {
          $('BODY').addClass('embedded'); // eslint-disable-line
        }
      });
    })
  });

  _exports.default = _default;
});