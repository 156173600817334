define("ui/components/container/form-volumes/component", ["exports", "ui/utils/constants", "ui/components/container/form-volumes/template"], function (_exports, _constants, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LOG_AGGREGATOR = _exports.EXISTING_VCT = _exports.EXISTING_PVC = _exports.EXISTING_VOLUME = _exports.NEW_VCT = _exports.NEW_PVC = _exports.NEW_VOLUME = void 0;
  const NEW_VOLUME = 'newVolume';
  _exports.NEW_VOLUME = NEW_VOLUME;
  const NEW_PVC = 'newPvc';
  _exports.NEW_PVC = NEW_PVC;
  const NEW_VCT = 'newVolumeClaimTemplate';
  _exports.NEW_VCT = NEW_VCT;
  const EXISTING_VOLUME = 'existingVolume';
  _exports.EXISTING_VOLUME = EXISTING_VOLUME;
  const EXISTING_PVC = 'existingPvc';
  _exports.EXISTING_PVC = EXISTING_PVC;
  const EXISTING_VCT = 'existingVct';
  _exports.EXISTING_VCT = EXISTING_VCT;
  const LOG_AGGREGATOR = 'cattle.io/log-aggregator';
  _exports.LOG_AGGREGATOR = LOG_AGGREGATOR;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    scope: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    layout: _template.default,
    classNames: ['accordion-wrapper'],
    // Inputs
    workload: null,
    launchConfig: null,
    namespace: null,
    errors: null,
    editing: true,
    scaleMode: null,
    volumesArray: null,
    nextNum: 1,
    cluster: Ember.computed.alias('scope.currentCluster'),
    project: Ember.computed.alias('scope.currentProject'),
    isWindows: Ember.computed.alias('scope.currentCluster.isWindows'),

    init() {
      this._super(...arguments);

      if (this.registerHook) {
        this.registerHook(this.saveVolumes.bind(this), {
          name: 'saveVolumes',
          key: '_volumeHooks'
        });
      }

      this.initVolumes();
    },

    // Create (ephermal) Volume -> volume entry on pod
    // Create PVC for existing (persistent) volume // cru-pvc
    // Create PVC for a new volume via storageclass // cru-pvc
    // Use an existing PVC (from the project volumes page)
    // Bind-mount (ephemeral volume -> hostPath)
    // Tmpfs (ephemeral volume -> emptyDir -> backing=memory)
    actions: {
      remove(obj) {
        this.volumesArray.removeObject(obj);
      },

      addVolume() {
        const store = this.store;
        this.volumesArray.pushObject({
          mode: NEW_VOLUME,
          volume: store.createRecord({
            type: 'volume',
            name: this.nextName()
          }),
          mounts: [this.store.createRecord({
            type: 'volumeMount'
          })]
        });
      },

      addNewPvc() {
        const store = this.store;
        this.volumesArray.pushObject({
          mode: NEW_PVC,
          pvc: store.createRecord({
            type: 'persistentVolumeClaim'
          }),
          name: null,
          volume: store.createRecord({
            type: 'volume',
            persistentVolumeClaim: store.createRecord({
              type: 'persistentVolumeClaimVolumeSource',
              persistentVolumeClaimId: null
            })
          }),
          mounts: [store.createRecord({
            type: 'volumeMount'
          })]
        });
      },

      addPvc() {
        const store = this.store;
        this.volumesArray.pushObject({
          mode: EXISTING_PVC,
          volume: store.createRecord({
            type: 'volume',
            name: this.nextName(),
            persistentVolumeClaim: store.createRecord({
              type: 'persistentVolumeClaimVolumeSource',
              persistentVolumeClaimId: null
            })
          }),
          mounts: [store.createRecord({
            type: 'volumeMount'
          })]
        });
      },

      addBindMount() {
        const store = this.store;
        this.volumesArray.pushObject({
          mode: _constants.default.VOLUME_TYPES.BIND_MOUNT,
          volume: store.createRecord({
            type: 'volume',
            name: this.nextName(),
            hostPath: store.createRecord({
              type: 'hostPathVolumeSource',
              kind: '',
              path: ''
            })
          }),
          mounts: [store.createRecord({
            type: 'volumeMount'
          })]
        });
      },

      addTmpfs() {
        const store = this.store;
        this.volumesArray.pushObject({
          mode: _constants.default.VOLUME_TYPES.TMPFS,
          volume: store.createRecord({
            type: 'volume',
            name: this.nextName(),
            emptyDir: store.createRecord({
              type: 'emptyDirVolumeSource',
              medium: 'Memory'
            })
          }),
          mounts: [store.createRecord({
            type: 'volumeMount'
          })]
        });
      },

      addConfigMap() {
        const store = this.store;
        this.volumesArray.pushObject({
          mode: _constants.default.VOLUME_TYPES.CONFIG_MAP,
          volume: store.createRecord({
            type: 'volume',
            name: this.nextName(),
            configMap: store.createRecord({
              type: 'configMapVolumeSource',
              defaultMode: 256,
              name: null,
              optional: false
            })
          }),
          mounts: [store.createRecord({
            type: 'volumeMount'
          })]
        });
      },

      addSecret() {
        const store = this.store;
        this.volumesArray.pushObject({
          mode: _constants.default.VOLUME_TYPES.SECRET,
          volume: store.createRecord({
            type: 'volume',
            name: this.nextName(),
            secret: store.createRecord({
              type: 'secretVolumeSource',
              defaultMode: 256,
              secretName: null,
              optional: false
            })
          }),
          mounts: [store.createRecord({
            type: 'volumeMount'
          })]
        });
      },

      addCertificate() {
        const store = this.store;
        this.volumesArray.pushObject({
          mode: _constants.default.VOLUME_TYPES.CERTIFICATE,
          volume: store.createRecord({
            type: 'volume',
            name: this.nextName(),
            secret: store.createRecord({
              type: 'secretVolumeSource',
              defaultMode: 256,
              secretName: null,
              optional: false
            })
          }),
          mounts: [store.createRecord({
            type: 'volumeMount'
          })]
        });
      },

      addCustomLogPath() {
        const store = this.store;
        const name = this.nextName();
        this.volumesArray.pushObject({
          mode: _constants.default.VOLUME_TYPES.CUSTOM_LOG_PATH,
          volume: store.createRecord({
            type: 'volume',
            name,
            flexVolume: store.createRecord({
              type: 'flexVolume',
              driver: LOG_AGGREGATOR,
              fsType: 'ext4',
              options: {
                format: 'json',
                clusterName: Ember.get(this, 'cluster.name'),
                projectName: Ember.get(this, 'project.name'),
                clusterId: Ember.get(this, 'cluster.id'),
                projectId: Ember.get(this, 'project.id').split(':')[1],
                volumeName: name
              }
            })
          }),
          mounts: [store.createRecord({
            type: 'volumeMount'
          })]
        });
      },

      addVolumeClaimTemplate() {
        const {
          store,
          volumesArray
        } = this;
        const vct = store.createRecord({
          type: 'persistentVolumeClaim',
          name: this.nextName()
        });
        volumesArray.pushObject({
          mode: NEW_VCT,
          vct,
          mounts: [store.createRecord({
            type: 'volumeMount'
          })]
        });
      }

    },
    isStatefulSet: Ember.computed('launchConfig', 'scaleMode', function () {
      const {
        scaleMode
      } = this;
      return scaleMode === 'statefulSet';
    }),

    initVolumes() {
      if (!this.expandFn) {
        Ember.set(this, 'expandFn', item => {
          item.toggleProperty('expanded');
        });
      }

      const out = [];
      const {
        workload
      } = this;
      let volumes = workload.volumes || [];
      let volumeClaimTemplates = workload.statefulSetConfig && workload.statefulSetConfig.volumeClaimTemplates ? workload.statefulSetConfig.volumeClaimTemplates : [];

      if (volumeClaimTemplates.length > 0) {
        volumeClaimTemplates.forEach(vct => {
          Ember.set(vct, 'isVolumeClaimTemplate', true);
        });
      }

      let allVolumes = [].concat(volumes.slice(), volumeClaimTemplates.slice());
      allVolumes.forEach(volume => {
        let mode;
        let hidden = false;

        if (volume.persistentVolumeClaim) {
          mode = EXISTING_PVC;
        } else if (volume.hostPath) {
          mode = _constants.default.VOLUME_TYPES.BIND_MOUNT;
        } else if (volume.flexVolume && volume.flexVolume.driver === LOG_AGGREGATOR) {
          mode = _constants.default.VOLUME_TYPES.CUSTOM_LOG_PATH;
          hidden = Ember.get(volume, 'flexVolume.options.containerName') !== Ember.get(this, 'launchConfig.name');
        } else if (volume.secret) {
          mode = this.getSecretType(Ember.get(volume, 'secret.secretName'));
        } else if (volume.configMap) {
          mode = _constants.default.VOLUME_TYPES.CONFIG_MAP;
        } else if (volume.isVolumeClaimTemplate) {
          mode = EXISTING_VCT;
        } else {
          mode = EXISTING_VOLUME;
        }

        out.push({
          mode,
          hidden,
          volume,
          mounts: []
        });
      });
      (Ember.get(this, 'launchConfig.volumeMounts') || []).forEach(mount => {
        const entry = out.findBy('volume.name', mount.name);

        if (entry) {
          entry.mounts.push(mount);
        }
      }); // filter out custom log path volume when logging is disabled

      if (!this.loggingEnabled) {
        Ember.set(this, 'volumesArray', out.filter(row => row.mode !== _constants.default.VOLUME_TYPES.CUSTOM_LOG_PATH));
      } else {
        Ember.set(this, 'volumesArray', out);
      }
    },

    getSecretType(secretName) {
      const store = this.store;
      let found = store.all('secret').findBy('name', secretName);

      if (found) {
        if (Ember.get(found, 'type') === _constants.default.VOLUME_TYPES.CERTIFICATE) {
          return _constants.default.VOLUME_TYPES.CERTIFICATE;
        }
      } else {
        found = store.all('namespacedSecret').findBy('type', secretName);

        if (found && Ember.get(found, 'type') === 'namespacedCertificate') {
          return _constants.default.VOLUME_TYPES.CERTIFICATE;
        }
      }

      return _constants.default.VOLUME_TYPES.SECRET;
    },

    nextName() {
      const volumes = Ember.get(this, 'workload.volumes') || [];
      let num = this.nextNum;
      let name;
      let ok = false;

      while (!ok) {
        name = `vol${num}`;
        ok = !volumes.findBy('name', name);
        num++;
      }

      Ember.set(this, 'nextNum', num);
      return name;
    },

    saveVolumes() {
      const {
        workload,
        launchConfig
      } = this;
      const ary = this.volumesArray || [];
      const promises = [];
      const statefulSetConfig = Ember.get(workload, 'statefulSetConfig') || {};
      const volumeClaimTemplates = Ember.get(statefulSetConfig, 'volumeClaimTemplates') || [];
      let pvc, vct;
      ary.filterBy('pvc').forEach(row => {
        pvc = Ember.get(row, 'pvc');
        Ember.set(pvc, 'namespaceId', Ember.get(this, 'namespace.id'));
        promises.push(Ember.get(row, 'pvc').save());
      });

      if (this.isStatefulSet) {
        ary.filterBy('vct').forEach(row => {
          vct = Ember.get(row, 'vct');
          volumeClaimTemplates.push(vct);
        });
        Ember.set(this, 'workload.statefulSetConfig.volumeClaimTemplates', volumeClaimTemplates);
      }

      ary.filterBy('mode', _constants.default.VOLUME_TYPES.CUSTOM_LOG_PATH).filterBy('volume.flexVolume.driver', LOG_AGGREGATOR).forEach(row => {
        const options = Ember.get(row, 'volume.flexVolume.options');
        const lc = this.launchConfig;
        const workload = this.workload;

        if (!Ember.get(row, 'hidden')) {
          Ember.setProperties(options, {
            containerName: Ember.get(lc, 'name'),
            namespace: Ember.get(workload, 'namespace.id'),
            workloadName: Ember.get(workload, 'name')
          });
        }
      });
      return Ember.RSVP.all(promises).then(() => {
        const volumes = [];
        const mounts = [];
        ary.forEach(row => {
          if (row.volume && !row.volume.isVolumeClaimTemplate) {
            volumes.pushObject(row.volume);
          }

          row.mounts.forEach(mount => {
            if (Ember.get(row, 'vct')) {
              Ember.set(mount, 'name', Ember.get(row, 'vct.name'));
            } else {
              Ember.set(mount, 'name', Ember.get(row, 'volume.name'));
            }

            mounts.pushObject(mount);
          });

          if (row.pvc) {
            const id = Ember.get(row, 'pvc.id');
            Ember.set(row, 'volume.persistentVolumeClaim.persistentVolumeClaimId', id);
          }
        });
        Ember.set(workload, 'volumes', volumes);
        Ember.set(launchConfig, 'volumeMounts', mounts);
      });
    }

  });

  _exports.default = _default;
});