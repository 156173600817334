define("ui/apps-tab/index/route", ["exports", "ui/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    catalog: Ember.inject.service(),
    store: Ember.inject.service(),

    beforeModel() {
      return this.catalog.fetchUnScopedCatalogs();
    },

    model() {
      return this.store.findAll('app').then(apps => ({
        apps
      }));
    },

    afterModel(model
    /* , transition */
    ) {
      return this.catalog.fetchAppTemplates(Ember.get(model, 'apps'));
    },

    setDefaultRoute: Ember.on('activate', function () {
      Ember.set(this, `session.${_constants.default.SESSION.PROJECT_ROUTE}`, 'apps-tab');
    })
  });

  _exports.default = _default;
});