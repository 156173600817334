define("ui/authenticated/cluster/cis/scan/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    scope: Ember.inject.service(),

    model() {
      const clusterScans = this.globalStore.findAll('clusterScan');
      return Ember.RSVP.hash({
        clusterScans,
        reports: (async () => {
          const scans = await clusterScans;
          const reportPromises = scans.map(scan => scan.loadReport('report'));
          return await Promise.all(reportPromises);
        })(),
        clusterTemplateRevisions: this.globalStore.findAll('clustertemplaterevision')
      });
    }

  });

  _exports.default = _default;
});