define("ui/components/cru-persistent-volume/component", ["exports", "shared/mixins/view-new-edit", "ui/components/cru-persistent-volume/template", "ui/models/volume", "shared/utils/parse-unit", "ui/utils/constants"], function (_exports, _viewNewEdit, _template, _volume, _parseUnit, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_viewNewEdit.default, {
    intl: Ember.inject.service(),
    clusterStore: Ember.inject.service(),
    features: Ember.inject.service(),
    settings: Ember.inject.service(),
    layout: _template.default,
    model: null,
    sourceName: null,
    titleKey: 'cruPersistentVolume.title',
    storageClasses: null,
    capacity: null,

    init() {
      this._super(...arguments);

      Ember.set(this, 'storageClasses', this.clusterStore.all('storageclass'));
    },

    didReceiveAttrs() {
      const {
        primaryResource
      } = this;
      const {
        sourceName = ''
      } = primaryResource;

      if (this.isNew) {
        Ember.set(this, 'capacity', 10);
      } else {
        const source = Ember.get(primaryResource, sourceName);

        if (sourceName === 'csi' && source.driver && source.driver === 'driver.longhorn.io') {
          Ember.set(this, 'sourceName', 'csi-volume-longhorn');
        } else {
          Ember.set(this, 'sourceName', sourceName);
        }

        const capacity = Ember.get(primaryResource, 'capacity.storage');

        if (capacity) {
          const bytes = (0, _parseUnit.parseSi)(capacity);
          const gib = bytes / 1024 ** 3;
          Ember.set(this, 'capacity', gib);
        }
      }
    },

    actions: {
      updateOptions(ary) {
        Ember.set(this, 'primaryResource.mountOptions', ary);
      },

      updateNodeAffinities(nodeSelector) {
        if (nodeSelector.nodeSelectorTerms.length > 0) {
          Ember.set(this, 'primaryResource.nodeAffinity', {
            required: nodeSelector
          });
        } else {
          Ember.set(this, 'primaryResource.nodeAffinity', null);
        }
      }

    },
    sourceChoices: Ember.computed('intl.locale', function () {
      const intl = this.intl;
      const out = (0, _volume.getSources)('persistent').map(p => {
        const entry = Object.assign({}, p);
        const key = `volumeSource.${entry.name}.title`;

        if (intl.exists(key)) {
          entry.label = intl.t(key);
          entry.priority = 1;
        } else {
          entry.label = entry.name;
          entry.priority = 2;
        }

        return entry;
      });
      return out.sortBy('priority', 'label');
    }),
    supportedSourceChoices: Ember.computed('sourceChoices', function () {
      const showUnsupported = this.features.isFeatureEnabled(_constants.default.FEATURES.UNSUPPORTED_STORAGE_DRIVERS);
      return this.sourceChoices.filter(choice => showUnsupported || choice.supported);
    }),
    sourceDisplayName: Ember.computed('sourceName', 'sourceChoices.[]', function () {
      const {
        sourceChoices,
        sourceName
      } = this;
      const match = sourceChoices.findBy('name', sourceName);
      return match ? Ember.get(match, 'label') : '';
    }),
    sourceComponent: Ember.computed('sourceName', function () {
      const name = this.sourceName;
      const sources = (0, _volume.getSources)('persistent');
      const entry = sources.findBy('name', name);

      if (entry) {
        return `volume-source/source-${name}`;
      }

      return null;
    }),

    willSave() {
      const vol = this.primaryResource;
      const entry = (0, _volume.getSources)('persistent').findBy('name', this.sourceName);
      const intl = this.intl;
      const errors = [];

      if (!entry) {
        errors.push(intl.t('validation.required', {
          key: intl.t('cruPersistentVolume.source.label')
        }));
        Ember.set(this, 'errors', errors);
        return false;
      }

      if (vol.csi && vol.csi.driver === _constants.default.STORAGE.LONGHORN_PROVISIONER_KEY) {
        if (Ember.isEmpty(vol.csi.volumeHandle)) {
          errors.push(intl.t('validation.required', {
            key: intl.t('cruPersistentVolumeClaim.volumeHandleRequired.label')
          }));
          Ember.set(this, 'errors', errors);
          return false;
        }
      }

      vol.clearSourcesExcept(entry.value);
      const capacity = this.capacity;

      if (capacity) {
        Ember.set(vol, 'capacity', {
          storage: `${capacity}Gi`
        });
      } else {
        errors.push(intl.t('validation.required', {
          key: intl.t('cruPersistentVolumeClaim.capacity.label')
        }));
        Ember.set(this, 'errors', errors);
        return false;
      }

      return this._super(...arguments);
    },

    doneSaving() {
      if (this.cancel) {
        this.cancel();
      }
    }

  });

  _exports.default = _default;
});