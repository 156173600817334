define("ui/volumes/new/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model()
    /* params, transition*/
    {
      const store = this.store;
      const clusterStore = this.clusterStore;
      return Ember.RSVP.hash({
        persistentVolumes: clusterStore.findAll('persistentVolume'),
        storageClasses: clusterStore.findAll('storageClass'),
        pvc: store.createRecord({
          type: 'persistentVolumeClaim'
        })
      });
    }

  });

  _exports.default = _default;
});