define("ui/containers/index/route", ["exports", "ui/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),

    model() {
      var store = this.store;
      var globalStore = this.globalStore;
      return Ember.RSVP.hash({
        workloads: store.findAll('workload'),
        pods: store.findAll('pod'),
        nodes: globalStore.findAll('node')
      });
    },

    setDefaultRoute: Ember.on('activate', function () {
      Ember.set(this, `session.${_constants.default.SESSION.CONTAINER_ROUTE}`, 'containers');
      Ember.set(this, `session.${_constants.default.SESSION.PROJECT_ROUTE}`, undefined);
    })
  });

  _exports.default = _default;
});