define("ui/components/container/form-scheduling/component", ["exports", "ui/components/container/form-scheduling/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    scope: Ember.inject.service(),
    layout: _template.default,
    scheduling: null,
    // Request a specific host
    requestedHostId: null,
    // Is requesting a specific host allowed
    canRequestHost: true,
    // Initial host to start with
    initialHostId: null,
    // Internal properties
    isRequestedHost: false,
    editing: true,
    advanced: false,
    classNames: ['accordion-wrapper'],
    _allNodes: null,

    init() {
      this._super(...arguments);

      Ember.set(this, '_allNodes', this.globalStore.all('node'));
      Ember.set(this, 'advanced', !this.editing);

      if (this.initialHostId) {
        Ember.set(this, 'isRequestedHost', true);
        Ember.set(this, 'requestedHostId', this.initialHostId);
      }
    },

    didReceiveAttrs() {
      if (!this.expandFn) {
        Ember.set(this, 'expandFn', item => {
          item.toggleProperty('expanded');
        });
      }
    },

    isRequestedHostDidChange: Ember.observer('isRequestedHost', function () {
      const scheduling = this.scheduling;

      if (this.isRequestedHost) {
        const hostId = this.requestedHostId || Ember.get(this, 'hostChoices.firstObject.id');
        Object.keys(scheduling).forEach(key => {
          if (scheduling.node) {
            delete scheduling.node[key];
          }
        });
        Ember.set(this, 'requestedHostId', hostId);
      } else {
        Ember.set(this, 'requestedHostId', null);
        delete scheduling.node['nodeId'];
      }
    }),
    requestedHostIdDidChange: Ember.observer('requestedHostId', function () {
      const hostId = this.requestedHostId;

      if (Ember.get(this, 'scheduling.node')) {
        Ember.set(this, 'scheduling.node.nodeId', hostId);
      } else {
        Ember.set(this, 'scheduling.node', {
          nodeId: hostId
        });
      }
    }),
    selectedChoice: Ember.computed('_allNodes.@each.{clusterId,id,name,state}', 'hostChoices', 'initialHostId', function () {
      return this.hostChoices.findBy('id', this.initialHostId);
    }),
    hostChoices: Ember.computed('_allNodes.@each.{clusterId,id,name,state}', 'scope.currentCluster.id', function () {
      const list = this._allNodes.filter(node => !Ember.get(node, 'isUnschedulable')).filterBy('clusterId', Ember.get(this, 'scope.currentCluster.id')).map(host => {
        let hostLabel = Ember.get(host, 'displayName');

        if (Ember.get(host, 'state') !== 'active') {
          hostLabel += ` (${Ember.get(host, 'state')})`;
        }

        return {
          id: Ember.get(host, 'id'),
          name: hostLabel
        };
      });

      return list.sortBy('name', 'id');
    })
  });

  _exports.default = _default;
});