define("ui/models/token", ["exports", "@rancher/ember-api-store/models/resource"], function (_exports, _resource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resource.default.extend({
    growl: Ember.inject.service(),
    state: Ember.computed('expired', function () {
      if (this.expired) {
        return 'expired';
      }

      return 'active';
    }),
    availableActions: Ember.computed('enabled', function () {
      // eslint-disable-line
      return [{
        label: 'action.activate',
        icon: 'icon icon-play',
        action: 'activate',
        // enabled: !this.enabled,
        enabled: false,
        // backend was supposed to be ready but its not ready, when it is flip this switch and bingo bango yor're off to the races
        bulkable: false
      }, {
        label: 'action.deactivate',
        icon: 'icon icon-pause',
        action: 'deactivate',
        // enabled: this.enabled,
        enabled: false,
        // backend was supposed to be ready but its not ready, when it is flip this switch and bingo bango yor're off to the races
        bulkable: false
      }];
    }),
    actions: {
      deactivate() {
        Ember.run.next(() => {
          Ember.set(this, 'enabled', false);
          this.save().catch(err => {
            Ember.set(this, 'enabled', true);
            this.growl.fromError('Error deactivating token', err);
          });
        });
      },

      activate() {
        Ember.run.next(() => {
          Ember.set(this, 'enabled', true);
          this.save().catch(err => {
            Ember.set(this, 'enabled', false);
            this.growl.fromError('Error activating token', err);
          });
        });
      }

    }
  });

  _exports.default = _default;
});