define("ui/models/cluster", ["exports", "@rancher/ember-api-store/models/resource", "@rancher/ember-api-store/utils/denormalize", "shared/mixins/resource-usage", "shared/mixins/grafana", "ui/utils/constants", "moment", "jsondiffpatch", "semver"], function (_exports, _resource, _denormalize, _resourceUsage, _grafana, _constants, _moment, _jsondiffpatch, _semver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.DEFAULT_AKS_NODE_POOL_CONFIG = _exports.DEFAULT_AKS_CONFIG = _exports.DEFAULT_GKE_CONFIG = _exports.DEFAULT_GKE_NODE_POOL_CONFIG = _exports.DEFAULT_EKS_CONFIG = _exports.DEFAULT_NODE_GROUP_CONFIG = _exports.DEFAULT_USER_DATA = void 0;
  const TRUE = 'True';
  const CLUSTER_TEMPLATE_ID_PREFIX = 'cattle-global-data:';
  const SCHEDULE_CLUSTER_SCAN_QUESTION_KEY = 'scheduledClusterScan.enabled';
  const DEFAULT_USER_DATA = `MIME-Version: 1.0
Content-Type: multipart/mixed; boundary="==MYBOUNDARY=="

--==MYBOUNDARY==
Content-Type: text/x-shellscript; charset="us-ascii"

#!/bin/bash
echo "Running custom user data script"

--==MYBOUNDARY==--\\`;
  _exports.DEFAULT_USER_DATA = DEFAULT_USER_DATA;
  const DEFAULT_NODE_GROUP_CONFIG = {
    desiredSize: 2,
    diskSize: 20,
    ec2SshKey: '',
    gpu: false,
    imageId: null,
    instanceType: 't3.medium',
    labels: {},
    maxSize: 2,
    minSize: 2,
    nodegroupName: '',
    nodeRole: '',
    requestSpotInstances: false,
    resourceTags: {},
    spotInstanceTypes: [],
    subnets: [],
    tags: {},
    type: 'nodeGroup',
    userData: DEFAULT_USER_DATA
  };
  _exports.DEFAULT_NODE_GROUP_CONFIG = DEFAULT_NODE_GROUP_CONFIG;
  const DEFAULT_EKS_CONFIG = {
    amazonCredentialSecret: '',
    displayName: '',
    ebsCSIDriver: false,
    imported: false,
    kmsKey: '',
    kubernetesVersion: '',
    loggingTypes: [],
    nodeGroups: [],
    privateAccess: false,
    publicAccess: true,
    publicAccessSources: [],
    region: 'us-west-2',
    secretsEncryption: false,
    securityGroups: [],
    serviceRole: '',
    subnets: [],
    tags: {},
    type: 'eksclusterconfigspec'
  };
  _exports.DEFAULT_EKS_CONFIG = DEFAULT_EKS_CONFIG;
  const DEFAULT_GKE_NODE_POOL_CONFIG = {
    autoscaling: {
      enabled: false,
      maxNodeCount: null,
      minNodeCount: null
    },
    config: {
      diskSizeGb: 100,
      diskType: 'pd-standard',
      imageType: 'COS_CONTAINERD',
      labels: null,
      localSsdCount: 0,
      machineType: 'n1-standard-2',
      oauthScopes: null,
      preemptible: false,
      taints: null,
      tags: null
    },
    initialNodeCount: 3,
    management: {
      autoRepair: true,
      autoUpgrade: true
    },
    maxPodsConstraint: 110,
    name: null,
    version: null,
    type: 'gkenodepoolconfig'
  };
  _exports.DEFAULT_GKE_NODE_POOL_CONFIG = DEFAULT_GKE_NODE_POOL_CONFIG;
  const DEFAULT_GKE_CONFIG = {
    clusterAddons: {
      horizontalPodAutoscaling: true,
      httpLoadBalancing: true,
      networkPolicyConfig: false
    },
    clusterIpv4Cidr: '',
    clusterName: null,
    description: null,
    enableKubernetesAlpha: false,
    googleCredentialSecret: null,
    imported: false,
    ipAllocationPolicy: {
      clusterIpv4CidrBlock: null,
      clusterSecondaryRangeName: null,
      createSubnetwork: false,
      nodeIpv4CidrBlock: null,
      servicesIpv4CidrBlock: null,
      servicesSecondaryRangeName: null,
      subnetworkName: null,
      useIpAliases: true
    },
    kubernetesVersion: '',
    labels: {},
    locations: null,
    loggingService: 'logging.googleapis.com/kubernetes',
    maintenanceWindow: '',
    masterAuthorizedNetworks: {
      cidrBlocks: null,
      enabled: false
    },
    monitoringService: 'monitoring.googleapis.com/kubernetes',
    network: null,
    networkPolicyEnabled: false,
    nodePools: [DEFAULT_GKE_NODE_POOL_CONFIG],
    privateClusterConfig: {
      enablePrivateEndpoint: false,
      enablePrivateNodes: false,
      masterIpv4CidrBlock: null
    },
    projectID: null,
    region: null,
    subnetwork: null,
    type: 'gkeclusterconfigspec',
    zone: 'us-central1-c'
  };
  _exports.DEFAULT_GKE_CONFIG = DEFAULT_GKE_CONFIG;
  const DEFAULT_AKS_CONFIG = {
    authBaseUrl: null,
    authorizedIpRanges: [],
    azureCredentialSecret: null,
    baseUrl: null,
    clusterName: null,
    dnsPrefix: null,
    dnsServiceIp: null,
    dockerBridgeCidr: null,
    imported: false,
    kubernetesVersion: null,
    linuxAdminUsername: 'azureuser',
    loadBalancerSku: 'Standard',
    networkPlugin: 'kubenet',
    networkPolicy: null,
    nodePools: [],
    podCidr: null,
    privateCluster: false,
    resourceGroup: null,
    resourceLocation: 'eastus',
    serviceCidr: null,
    sshPublicKey: null,
    subnet: null,
    tags: {},
    type: 'aksclusterconfigspec',
    virtualNetwork: null,
    virtualNetworkResourceGroup: null,
    windowsAdminPassword: null,
    windowsAdminUsername: null
  };
  _exports.DEFAULT_AKS_CONFIG = DEFAULT_AKS_CONFIG;
  const DEFAULT_AKS_NODE_POOL_CONFIG = {
    availabilityZones: ['1', '2', '3'],
    count: 1,
    enableAutoScaling: false,
    maxPods: 110,
    maxSurge: '1',
    mode: 'System',
    name: '',
    nodeLabels: {},
    nodeTaints: [],
    orchestratorVersion: '',
    osDiskSizeGB: 128,
    osDiskType: 'Managed',
    osType: 'Linux',
    type: 'aksnodepool',
    vmSize: 'Standard_DS2_v2'
  };
  _exports.DEFAULT_AKS_NODE_POOL_CONFIG = DEFAULT_AKS_NODE_POOL_CONFIG;

  var _default = _resource.default.extend(_grafana.default, _resourceUsage.default, {
    globalStore: Ember.inject.service(),
    growl: Ember.inject.service(),
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    scope: Ember.inject.service(),
    clusterRoleTemplateBindings: (0, _denormalize.hasMany)('id', 'clusterRoleTemplateBinding', 'clusterId'),
    etcdbackups: (0, _denormalize.hasMany)('id', 'etcdbackup', 'clusterId'),
    namespaces: (0, _denormalize.hasMany)('id', 'namespace', 'clusterId'),
    nodePools: (0, _denormalize.hasMany)('id', 'nodePool', 'clusterId'),
    nodes: (0, _denormalize.hasMany)('id', 'node', 'clusterId'),
    projects: (0, _denormalize.hasMany)('id', 'project', 'clusterId'),
    clusterScans: (0, _denormalize.hasMany)('id', 'clusterScan', 'clusterId'),
    expiringCerts: null,
    grafanaDashboardName: 'Cluster',
    isMonitoringReady: false,
    _cachedConfig: null,
    canHaveLabels: true,
    clusterTemplate: (0, _denormalize.reference)('clusterTemplateId'),
    clusterTemplateRevision: (0, _denormalize.reference)('clusterTemplateRevisionId'),
    machines: Ember.computed.alias('nodes'),
    roleTemplateBindings: Ember.computed.alias('clusterRoleTemplateBindings'),
    isAKS: Ember.computed.equal('driver', 'azureKubernetesService'),
    isGKE: Ember.computed.equal('driver', 'googleKubernetesEngine'),
    runningClusterScans: Ember.computed.filterBy('clusterScans', 'isRunning', true),
    isRKE: Ember.computed.equal('configName', 'rancherKubernetesEngineConfig'),
    conditionsDidChange: Ember.on('init', Ember.observer('enableClusterMonitoring', 'conditions.@each.status', function () {
      if (!this.enableClusterMonitoring) {
        return false;
      }

      const conditions = this.conditions || [];
      const ready = conditions.findBy('type', 'MonitoringEnabled');
      const status = ready && Ember.get(ready, 'status') === 'True';

      if (status !== this.isMonitoringReady) {
        Ember.set(this, 'isMonitoringReady', status);
      }
    })),
    clusterTemplateDisplayName: Ember.computed('clusterTemplate.{displayName,name}', 'clusterTemplateId', function () {
      const displayName = Ember.get(this, 'clusterTemplate.displayName');
      const clusterTemplateId = (this.clusterTemplateId || '').replace(CLUSTER_TEMPLATE_ID_PREFIX, '');
      return displayName || clusterTemplateId;
    }),
    clusterTemplateRevisionDisplayName: Ember.computed('clusterTemplateRevision.{displayName,name}', 'clusterTemplateRevisionId', function () {
      const displayName = Ember.get(this, 'clusterTemplateRevision.displayName');
      const revisionId = (this.clusterTemplateRevisionId || '').replace(CLUSTER_TEMPLATE_ID_PREFIX, '');
      return displayName || revisionId;
    }),
    isClusterTemplateUpgradeAvailable: Ember.computed('clusterTemplate.latestRevision', 'clusterTemplate.latestRevision.id', 'clusterTemplateRevision.id', function () {
      const latestClusterTemplateRevisionId = Ember.get(this, 'clusterTemplate.latestRevision.id');
      const currentClusterTemplateRevisionId = Ember.get(this, 'clusterTemplateRevision.id');
      return latestClusterTemplateRevisionId && currentClusterTemplateRevisionId && currentClusterTemplateRevisionId !== latestClusterTemplateRevisionId;
    }),
    getAltActionDelete: Ember.computed('action.remove', function () {
      // eslint-disable-line
      return this.canBulkRemove ? 'delete' : null;
    }),
    hasSessionToken: Ember.computed('annotations', function () {
      const sessionTokenLabel = `${(this.annotations || {})[_constants.default.LABEL.EKS_SESSION_TOKEN]}`;
      let hasSessionToken = false;

      if (sessionTokenLabel === 'undefined' || sessionTokenLabel === 'false') {
        hasSessionToken = false;
      } else {
        hasSessionToken = true;
      }

      return hasSessionToken;
    }),
    canRotateCerts: Ember.computed('actionLinks.rotateCertificates', function () {
      return !!this.actionLinks.rotateCertificates;
    }),
    canRotateEncryptionKey: Ember.computed('actionLinks.rotateEncryptionKey', 'etcdbackups.@each.created', 'rancherKubernetesEngineConfig.rotateEncryptionKey', 'rancherKubernetesEngineConfig.services.kubeApi.secretsEncryptionConfig.enabled', 'transitioning', 'isActive', function () {
      const acceptableTimeFrame = 360;
      const {
        actionLinks: {
          rotateEncryptionKey
        },
        etcdbackups,
        rancherKubernetesEngineConfig
      } = this;
      const lastBackup = !Ember.isEmpty(etcdbackups) ? Ember.get(etcdbackups, 'lastObject') : undefined;
      let diffInMinutes = 0;

      if (this.transitioning !== 'no' || !this.isActive) {
        return false;
      }

      if (Ember.isEmpty(rancherKubernetesEngineConfig)) {
        return false;
      } else {
        const {
          rotateEncryptionKey = false,
          services: {
            kubeApi: {
              secretsEncryptionConfig = null
            }
          }
        } = rancherKubernetesEngineConfig;

        if (!!rotateEncryptionKey || Ember.isEmpty(secretsEncryptionConfig) || !Ember.get(secretsEncryptionConfig, 'enabled')) {
          return false;
        }
      }

      if (lastBackup) {
        diffInMinutes = (0, _moment.default)().diff(lastBackup.created, 'minutes');
      }

      return rotateEncryptionKey && diffInMinutes <= acceptableTimeFrame;
    }),
    canBulkRemove: Ember.computed('action.remove', function () {
      // eslint-disable-line
      return this.hasSessionToken ? false : true;
    }),
    canSaveAsTemplate: Ember.computed('actionLinks.saveAsTemplate', 'isReady', 'clusterTemplateRevisionId', 'clusterTemplateId', function () {
      let {
        actionLinks,
        isReady,
        clusterTemplateRevisionId,
        clusterTemplateId
      } = this;

      if (!isReady) {
        return false;
      }

      if (clusterTemplateRevisionId || clusterTemplateId) {
        return false;
      }

      return !!actionLinks.saveAsTemplate;
    }),
    hasPublicAccess: Ember.computed('aksConfig.privateCluster', 'aksStatus.upstreamSpec.privateCluster', 'eksConfig.publicAccess', 'eksStatus.upstreamSpec.publicAccess', 'gkeStatus.privateClusterConfig.enablePrivateNodes', 'gkeStatus.upstreamSpec.privateClusterConfig.enablePrivateNodes', function () {
      var _this$eksStatus, _this$eksStatus$upstr, _this$eksConfig, _this$gkeStatus, _this$gkeStatus$upstr, _this$gkeStatus$upstr2, _this$gkeStatus2, _this$gkeStatus2$priv, _this$aksStatus, _this$aksStatus$upstr, _this$aksConfig;

      const {
        clusterProvider
      } = this;

      switch (clusterProvider) {
        case 'amazoneksv2':
          return (this === null || this === void 0 ? void 0 : (_this$eksStatus = this.eksStatus) === null || _this$eksStatus === void 0 ? void 0 : (_this$eksStatus$upstr = _this$eksStatus.upstreamSpec) === null || _this$eksStatus$upstr === void 0 ? void 0 : _this$eksStatus$upstr.publicAccess) || (this === null || this === void 0 ? void 0 : (_this$eksConfig = this.eksConfig) === null || _this$eksConfig === void 0 ? void 0 : _this$eksConfig.publicAccess) || true;

        case 'googlegkev2':
          return !(this !== null && this !== void 0 && (_this$gkeStatus = this.gkeStatus) !== null && _this$gkeStatus !== void 0 && (_this$gkeStatus$upstr = _this$gkeStatus.upstreamSpec) !== null && _this$gkeStatus$upstr !== void 0 && (_this$gkeStatus$upstr2 = _this$gkeStatus$upstr.privateClusterConfig) !== null && _this$gkeStatus$upstr2 !== void 0 && _this$gkeStatus$upstr2.enablePrivateNodes) || !(this !== null && this !== void 0 && (_this$gkeStatus2 = this.gkeStatus) !== null && _this$gkeStatus2 !== void 0 && (_this$gkeStatus2$priv = _this$gkeStatus2.privateClusterConfig) !== null && _this$gkeStatus2$priv !== void 0 && _this$gkeStatus2$priv.enablePrivateNodes) || true;

        case 'azureaksv2':
          return !(this !== null && this !== void 0 && (_this$aksStatus = this.aksStatus) !== null && _this$aksStatus !== void 0 && (_this$aksStatus$upstr = _this$aksStatus.upstreamSpec) !== null && _this$aksStatus$upstr !== void 0 && _this$aksStatus$upstr.privateCluster) || !(this !== null && this !== void 0 && (_this$aksConfig = this.aksConfig) !== null && _this$aksConfig !== void 0 && _this$aksConfig.privateCluster) || true;

        default:
          return true;
      }
    }),
    hasPrivateAccess: Ember.computed('aksConfig.privateCluster', 'aksStatus.upstreamSpec.privateCluster', 'eksConfig.privateAccess', 'eksStatus.upstreamSpec.privateAccess', 'gkeConfig.privateClusterConfig.enablePrivateNodes', 'gkeStatus.upstreamSpec.privateClusterConfig.enablePrivateNodes', function () {
      var _this$eksStatus2, _this$eksStatus2$upst, _this$eksConfig2, _this$gkeStatus3, _this$gkeStatus3$upst, _this$gkeStatus3$upst2, _this$gkeConfig, _this$gkeConfig$priva, _this$aksStatus2, _this$aksStatus2$upst, _this$aksConfig2;

      const {
        clusterProvider
      } = this;

      switch (clusterProvider) {
        case 'amazoneksv2':
          return (this === null || this === void 0 ? void 0 : (_this$eksStatus2 = this.eksStatus) === null || _this$eksStatus2 === void 0 ? void 0 : (_this$eksStatus2$upst = _this$eksStatus2.upstreamSpec) === null || _this$eksStatus2$upst === void 0 ? void 0 : _this$eksStatus2$upst.privateAccess) || (this === null || this === void 0 ? void 0 : (_this$eksConfig2 = this.eksConfig) === null || _this$eksConfig2 === void 0 ? void 0 : _this$eksConfig2.privateAccess) || false;

        case 'googlegkev2':
          return (this === null || this === void 0 ? void 0 : (_this$gkeStatus3 = this.gkeStatus) === null || _this$gkeStatus3 === void 0 ? void 0 : (_this$gkeStatus3$upst = _this$gkeStatus3.upstreamSpec) === null || _this$gkeStatus3$upst === void 0 ? void 0 : (_this$gkeStatus3$upst2 = _this$gkeStatus3$upst.privateClusterConfig) === null || _this$gkeStatus3$upst2 === void 0 ? void 0 : _this$gkeStatus3$upst2.enablePrivateNodes) || (this === null || this === void 0 ? void 0 : (_this$gkeConfig = this.gkeConfig) === null || _this$gkeConfig === void 0 ? void 0 : (_this$gkeConfig$priva = _this$gkeConfig.privateClusterConfig) === null || _this$gkeConfig$priva === void 0 ? void 0 : _this$gkeConfig$priva.enablePrivateNodes);

        case 'azureaksv2':
          return (this === null || this === void 0 ? void 0 : (_this$aksStatus2 = this.aksStatus) === null || _this$aksStatus2 === void 0 ? void 0 : (_this$aksStatus2$upst = _this$aksStatus2.upstreamSpec) === null || _this$aksStatus2$upst === void 0 ? void 0 : _this$aksStatus2$upst.privateCluster) || (this === null || this === void 0 ? void 0 : (_this$aksConfig2 = this.aksConfig) === null || _this$aksConfig2 === void 0 ? void 0 : _this$aksConfig2.privateCluster);

        default:
          return false;
      }
    }),
    displayImportLabel: Ember.computed('aksDisplayImport', 'clusterProvider', 'eksDisplayEksImport', 'gkeDisplayImport', function () {
      const {
        clusterProvider
      } = this;

      switch (clusterProvider) {
        case 'amazoneksv2':
          return this.eksDisplayEksImport ? true : false;

        case 'googlegkev2':
          return this.gkeDisplayImport ? true : false;

        case 'azureaksv2':
          return this.aksDisplayImport ? true : false;

        case 'import':
          return true;

        default:
          return false;
      }
    }),
    aksDisplayImport: Ember.computed('clusterProvider', 'hasPrivateAccess', 'imported', function () {
      const {
        clusterProvider
      } = this;

      if (clusterProvider !== 'azureaksv2') {
        return false;
      }

      if (this.hasPrivateAccess) {
        return true;
      }

      return false;
    }),
    gkeDisplayImport: Ember.computed('clusterProvider', 'hasPrivateAccess', 'imported', function () {
      const {
        clusterProvider
      } = this;

      if (clusterProvider !== 'googlegkev2') {
        return false;
      }

      if (this.hasPrivateAccess) {
        return true;
      }

      return false;
    }),
    eksDisplayEksImport: Ember.computed('hasPrivateAccess', 'hasPublicAccess', function () {
      const {
        clusterProvider
      } = this;

      if (clusterProvider !== 'amazoneksv2') {
        return false;
      }

      if (!this.hasPublicAccess && this.hasPrivateAccess) {
        return true;
      }

      return false;
    }),
    canShowAddHost: Ember.computed('clusterProvider', 'hasPrivateAccess', 'hasPublicAccess', 'imported', 'nodes', 'internal', function () {
      const {
        clusterProvider
      } = this;
      const compatibleProviders = ['custom', 'import', 'amazoneksv2', 'googlegkev2', 'azureaksv2']; // internal indicates the local cluster. Rancher does not manage the local cluster, so nodes can not be added via the UI

      const internal = this.internal;

      if (!compatibleProviders.includes(clusterProvider) || internal) {
        return false;
      } // private access requires the ability to run the import command on the cluster


      if (clusterProvider === 'amazoneksv2' && !!this.hasPublicAccess && this.hasPrivateAccess) {
        return true;
      } else if (clusterProvider === 'googlegkev2' && this.hasPrivateAccess) {
        return true;
      } else if (clusterProvider === 'azureaksv2' && this.hasPrivateAccess) {
        return true;
      } else if (clusterProvider === 'custom' || clusterProvider === 'import') {
        return true;
      }

      return false;
    }),
    configName: Ember.computed('driver', 'state', function () {
      const keys = this.allKeys().filter(x => x.endsWith('Config'));

      for (let key, i = 0; i < keys.length; i++) {
        key = keys[i];

        if (Ember.get(this, key)) {
          return key;
        }
      }

      return null;
    }),
    isReady: Ember.computed('conditions.@each.status', function () {
      return this.hasCondition('Ready');
    }),
    isK8s21Plus: Ember.computed('version.gitVersion', function () {
      const version = _semver.default.coerce(Ember.get(this, 'version.gitVersion'));

      return _semver.default.satisfies(version, '>=1.21.0');
    }),
    displayLocation: Ember.computed('configName', function () {
      const configName = this.configName;

      if (configName) {
        return Ember.get(this, `${configName}.region`) || Ember.get(this, `${configName}.regionId`) || Ember.get(this, `${configName}.location`) || Ember.get(this, `${configName}.zone`) || Ember.get(this, `${configName}.zoneId`);
      }

      return '';
    }),
    clusterProvider: Ember.computed('configName', 'nodePools.@each.{driver,nodeTemplateId}', 'driver', function () {
      const pools = this.nodePools || [];
      const firstPool = pools.objectAt(0);

      switch (this.configName) {
        case 'amazonElasticContainerServiceConfig':
          return 'amazoneks';

        case 'eksConfig':
          return 'amazoneksv2';

        case 'azureKubernetesServiceConfig':
          return 'azureaks';

        case 'aksConfig':
          return 'azureaksv2';

        case 'gkeConfig':
          return 'googlegkev2';

        case 'googleKubernetesEngineConfig':
          return 'googlegke';

        case 'tencentEngineConfig':
          return 'tencenttke';

        case 'huaweiEngineConfig':
          return 'huaweicce';

        case 'okeEngineConfig':
          return 'oracleoke';

        case 'lkeEngineConfig':
          return 'linodelke';

        case 'rke2Config':
          return 'rke2';

        case 'rancherKubernetesEngineConfig':
          if (!pools.length) {
            return 'custom';
          }

          return firstPool.driver || Ember.get(firstPool, 'nodeTemplate.driver') || null;

        default:
          if (this.driver && this.configName && !Ember.isEmpty(Ember.get(this, this.configName))) {
            return this.driver;
          } else {
            return 'import';
          }

      }
    }),
    displayProvider: Ember.computed('configName', 'driver', 'intl.locale', 'nodePools.@each.displayProvider', 'provider', function () {
      const intl = this.intl;
      const pools = this.nodePools;
      const firstPool = (pools || []).objectAt(0);
      const configName = this.configName;
      const driverName = this.driver;

      switch (configName) {
        case 'amazonElasticContainerServiceConfig':
        case 'eksConfig':
          return intl.t('clusterNew.amazoneks.shortLabel');

        case 'azureKubernetesServiceConfig':
          return intl.t('clusterNew.azureaks.shortLabel');

        case 'googleKubernetesEngineConfig':
          return intl.t('clusterNew.googlegke.shortLabel');

        case 'tencentEngineConfig':
          return intl.t('clusterNew.tencenttke.shortLabel');

        case 'aliyunEngineConfig':
          return intl.t('clusterNew.aliyunack.shortLabel');

        case 'huaweiEngineConfig':
          return intl.t('clusterNew.huaweicce.shortLabel');

        case 'okeEngineConfig':
          return intl.t('clusterNew.oracleoke.shortLabel');

        case 'otccceEngineConfig':
          return intl.t('clusterNew.otccce.shortLabel');

        case 'lkeEngineConfig':
          return intl.t('clusterNew.linodelke.shortLabel');

        case 'k3sConfig':
          return intl.t('clusterNew.k3simport.shortLabel');

        case 'rke2Config':
        case 'rancherKubernetesEngineConfig':
          var shortLabel;

          if (configName === 'rancherKubernetesEngineConfig') {
            if (this.provider === 'rke.windows') {
              shortLabel = 'clusterNew.rkeWindows.shortLabel';
            } else {
              shortLabel = 'clusterNew.rke.shortLabel';
            }
          } else {
            shortLabel = 'clusterNew.rke2.shortLabel';
          }

          if (!!pools) {
            if (firstPool) {
              return Ember.get(firstPool, 'displayProvider') ? Ember.get(firstPool, 'displayProvider') : intl.t(shortLabel);
            } else {
              return intl.t(shortLabel);
            }
          } else {
            return intl.t('clusterNew.custom.shortLabel');
          }

        default:
          if (driverName) {
            switch (driverName) {
              case 'rancherd':
                return intl.t('clusterNew.rancherd.shortLabel');

              default:
                return driverName.capitalize();
            }
          } else {
            return intl.t('clusterNew.import.shortLabel');
          }

      }
    }),
    systemProject: Ember.computed('projects.@each.isSystemProject', function () {
      let projects = (this.projects || []).filterBy('isSystemProject', true);
      return Ember.get(projects, 'firstObject');
    }),
    canSaveMonitor: Ember.computed('actionLinks.{editMonitoring,enableMonitoring}', 'enableClusterMonitoring', function () {
      const action = this.enableClusterMonitoring ? 'editMonitoring' : 'enableMonitoring';
      return !!this.hasAction(action);
    }),
    canDisableMonitor: Ember.computed('actionLinks.disableMonitoring', function () {
      return !!this.hasAction('disableMonitoring');
    }),
    defaultProject: Ember.computed('projects.@each.{name,clusterOwner}', function () {
      let projects = this.projects || [];
      let out = projects.findBy('isDefault');

      if (out) {
        return out;
      }

      out = projects.findBy('clusterOwner', true);

      if (out) {
        return out;
      }

      out = projects.objectAt(0);
      return out;
    }),
    nodeGroupVersionUpdate: Ember.computed('eksStatus.upstreamSpec.kubernetesVersion', 'eksStatus.upstreamSpec.nodeGroups.@each.version', function () {
      if (Ember.isEmpty(Ember.get(this, 'eksStatus.upstreamSpec.nodeGroups'))) {
        return false;
      }

      const kubernetesVersion = Ember.get(this, 'eksStatus.upstreamSpec.kubernetesVersion');
      const nodeGroupVersions = (Ember.get(this, 'eksStatus.upstreamSpec.nodeGroups') || []).getEach('version');
      return nodeGroupVersions.any(ngv => {
        if (Ember.isEmpty(ngv)) {
          return false;
        } else {
          return _semver.default.lt(_semver.default.coerce(ngv), _semver.default.coerce(kubernetesVersion));
        }
      });
    }),
    gkeNodePoolVersionUpdate: Ember.computed('gkeStatus.upstreamSpec.kubernetesVersion', 'gkeStatus.upstreamSpec.nodePools.@each.version', function () {
      if (Ember.isEmpty(Ember.get(this, 'gkeStatus.upstreamSpec.nodePools'))) {
        return false;
      }

      const kubernetesVersion = Ember.get(this, 'gkeStatus.upstreamSpec.kubernetesVersion');
      const nodePoolVersions = (Ember.get(this, 'gkeStatus.upstreamSpec.nodePools') || []).getEach('version');
      return nodePoolVersions.any(ngv => {
        if (Ember.isEmpty(ngv)) {
          return false;
        } else {
          return _semver.default.lt(_semver.default.coerce(ngv), _semver.default.coerce(kubernetesVersion));
        }
      });
    }),
    certsExpiring: Ember.computed('certificatesExpiration', function () {
      let {
        certificatesExpiration = {},
        expiringCerts
      } = this;

      if (!expiringCerts) {
        expiringCerts = [];
      }

      if (!Ember.isEmpty(certificatesExpiration)) {
        let expKeys = Object.keys(certificatesExpiration);
        expKeys.forEach(kee => {
          let certDate = Ember.get(certificatesExpiration[kee], 'expirationDate');
          const expirey = (0, _moment.default)(certDate);
          let diff = expirey.diff((0, _moment.default)());

          if (diff < 2592000000) {
            // milliseconds in a month
            expiringCerts.pushObject({
              expiringCertName: kee,
              milliUntil: diff,
              exactDateTime: certDate
            });
          }
        });
        Ember.set(this, 'expiringCerts', expiringCerts);
        return expiringCerts.length > 0;
      }

      return false;
    }),
    availableActions: Ember.computed('actionLinks.{rotateCertificates,rotateEncryptionKey}', 'canRotateEncryptionKey', 'canSaveAsTemplate', 'canShowAddHost', 'displayImportLabel', 'isClusterScanDisabled', function () {
      const a = this.actionLinks || {};
      return [{
        label: 'action.rotate',
        icon: 'icon icon-history',
        action: 'rotateCertificates',
        enabled: !!a.rotateCertificates
      }, {
        label: 'action.rotateEncryption',
        icon: 'icon icon-key',
        action: 'rotateEncryptionKey',
        enabled: !!this.canRotateEncryptionKey // enabled: true

      }, {
        label: 'action.backupEtcd',
        icon: 'icon icon-history',
        action: 'backupEtcd',
        enabled: !!a.backupEtcd
      }, {
        label: 'action.restoreFromEtcdBackup',
        icon: 'icon icon-history',
        action: 'restoreFromEtcdBackup',
        enabled: !!a.restoreFromEtcdBackup
      }, {
        label: 'action.saveAsTemplate',
        icon: 'icon icon-file',
        action: 'saveAsTemplate',
        enabled: this.canSaveAsTemplate
      }, {
        label: this.displayImportLabel ? 'action.importHost' : 'action.registration',
        icon: 'icon icon-host',
        action: 'showCommandModal',
        enabled: this.canShowAddHost
      }, {
        label: 'action.runCISScan',
        icon: 'icon icon-play',
        action: 'runCISScan',
        enabled: !this.isClusterScanDisabled
      }];
    }),
    isVxlan: Ember.computed('rancherKubernetesEngineConfig.network.options.flannel_backend_type', function () {
      const backend = Ember.get(this, 'rancherKubernetesEngineConfig.network.options.flannel_backend_type');
      return backend === 'vxlan';
    }),
    isWindows: Ember.computed('windowsPreferedCluster', function () {
      return !!this.windowsPreferedCluster;
    }),
    isClusterScanDown: Ember.computed('systemProject', 'state', 'actionLinks.runSecurityScan', 'isWindows', function () {
      return !this.systemProject || this.state !== 'active' || !Ember.get(this, 'actionLinks.runSecurityScan') || this.isWindows;
    }),
    isAddClusterScanScheduleDisabled: Ember.computed('isClusterScanDown', 'scheduledClusterScan.enabled', 'clusterTemplateRevision', 'clusterTemplateRevision.questions.[]', function () {
      if (this.clusterTemplateRevision === null) {
        return this.isClusterScanDown;
      }

      if (this.isClusterScanDown) {
        return true;
      }

      if (Ember.get(this, 'scheduledClusterScan.enabled')) {
        return false;
      }

      return !Ember.get(this, 'clusterTemplateRevision.questions') || Ember.get(this, 'clusterTemplateRevision.questions').every(question => question.variable !== SCHEDULE_CLUSTER_SCAN_QUESTION_KEY);
    }),
    isClusterScanDisabled: Ember.computed('runningClusterScans.length', 'isClusterScanDown', function () {
      return Ember.get(this, 'runningClusterScans.length') > 0 || this.isClusterScanDown;
    }),
    unhealthyComponents: Ember.computed('componentStatuses.@each.conditions', function () {
      return (this.componentStatuses || []).filter(s => !(s.conditions || []).any(c => c.status === 'True'));
    }),
    masterNodes: Ember.computed('nodes.@each.{state,labels}', function () {
      return (this.nodes || []).filter(node => node.labels && node.labels[_constants.default.NODES.MASTER_NODE]);
    }),
    inactiveNodes: Ember.computed('nodes.@each.state', function () {
      return (this.nodes || []).filter(n => _constants.default.ACTIVEISH_STATES.indexOf(Ember.get(n, 'state')) === -1);
    }),
    unhealthyNodes: Ember.computed('nodes.@each.conditions', function () {
      const out = [];
      (this.nodes || []).forEach(n => {
        const conditions = Ember.get(n, 'conditions') || [];
        const outOfDisk = conditions.find(c => c.type === 'OutOfDisk');
        const diskPressure = conditions.find(c => c.type === 'DiskPressure');
        const memoryPressure = conditions.find(c => c.type === 'MemoryPressure');

        if (outOfDisk && Ember.get(outOfDisk, 'status') === TRUE) {
          out.push({
            displayName: Ember.get(n, 'displayName'),
            error: 'outOfDisk'
          });
        }

        if (diskPressure && Ember.get(diskPressure, 'status') === TRUE) {
          out.push({
            displayName: Ember.get(n, 'displayName'),
            error: 'diskPressure'
          });
        }

        if (memoryPressure && Ember.get(memoryPressure, 'status') === TRUE) {
          out.push({
            displayName: Ember.get(n, 'displayName'),
            error: 'memoryPressure'
          });
        }
      });
      return out;
    }),
    displayWarnings: Ember.computed('unhealthyNodes.[]', 'clusterProvider', 'inactiveNodes.[]', 'unhealthyComponents.[]', function () {
      const intl = this.intl;
      const out = [];
      const unhealthyComponents = this.unhealthyComponents || [];
      const inactiveNodes = this.inactiveNodes || [];
      const unhealthyNodes = this.unhealthyNodes || [];
      const clusterProvider = this.clusterProvider;
      const grayOut = _constants.default.GRAY_OUT_SCHEDULER_STATUS_PROVIDERS.indexOf(clusterProvider) > -1;
      unhealthyComponents.forEach(component => {
        if (grayOut && (Ember.get(component, 'name') === 'scheduler' || Ember.get(component, 'name') === 'controller-manager')) {
          return;
        }

        out.pushObject(intl.t('clusterDashboard.alert.component', {
          component: Ember.get(component, 'name')
        }));
      });
      inactiveNodes.forEach(node => {
        out.pushObject(intl.t('clusterDashboard.alert.node', {
          node: Ember.get(node, 'displayName')
        }));
      });
      unhealthyNodes.forEach(node => {
        out.pushObject(intl.t(`clusterDashboard.alert.nodeCondition.${Ember.get(node, 'error')}`, {
          node: Ember.get(node, 'displayName')
        }));
      });
      return out;
    }),
    actions: {
      backupEtcd() {
        const getBackupType = () => {
          let services = Ember.get(this, 'rancherKubernetesEngineConfig.services.etcd');

          if (Ember.get(services, 'cachedConfig')) {
            if (Ember.isEmpty(services.cachedConfig.s3BackupConfig)) {
              return 'local';
            } else if (!Ember.isEmpty(services.cachedConfig.s3BackupConfig)) {
              return 's3';
            }
          }
        };

        const backupType = getBackupType();
        const successTitle = this.intl.t('action.backupEtcdMessage.success.title');
        const successMessage = this.intl.t('action.backupEtcdMessage.success.message', {
          clusterId: this.displayName || this.id,
          backupType
        });
        this.doAction('backupEtcd').then(() => this.growl.success(successTitle, successMessage)).catch(err => this.growl.fromError(err));
      },

      restoreFromEtcdBackup(options) {
        this.modalService.toggleModal('modal-restore-backup', {
          cluster: this,
          selection: (options || {}).selection
        });
      },

      promptDelete() {
        const hasSessionToken = this.canBulkRemove ? false : true; // canBulkRemove returns true of the session token is set false

        if (hasSessionToken) {
          Ember.set(this, `${this.configName}.accessKey`, null);
          this.modalService.toggleModal('modal-delete-eks-cluster', {
            model: this
          });
        } else {
          this.modalService.toggleModal('confirm-delete', {
            escToClose: true,
            resources: [this]
          });
        }
      },

      edit(additionalQueryParams = {}) {
        let provider = this.clusterProvider || this.driver;
        let queryParams = {
          queryParams: {
            provider,
            ...additionalQueryParams
          }
        };

        if (provider === 'import' && Ember.isEmpty(this.eksConfig) && Ember.isEmpty(this.gkeConfig) && Ember.isEmpty(this.aksConfig)) {
          Ember.set(queryParams, 'queryParams.importProvider', 'other');
        }

        if (provider === 'amazoneks' && !Ember.isEmpty(this.eksConfig)) {
          Ember.set(queryParams, 'queryParams.provider', 'amazoneksv2');
        }

        if (provider === 'gke' && !Ember.isEmpty(this.gkeConfig)) {
          Ember.set(queryParams, 'queryParams.provider', 'googlegkev2');
        }

        if (provider === 'aks' && !Ember.isEmpty(this.aksConfig)) {
          Ember.set(queryParams, 'queryParams.provider', 'azureaksv2');
        }

        if (this.clusterTemplateRevisionId) {
          Ember.set(queryParams, 'queryParams.clusterTemplateRevision', this.clusterTemplateRevisionId);
        }

        this.router.transitionTo('authenticated.cluster.edit', this.id, queryParams);
      },

      scaleDownPool(id) {
        const pool = (this.nodePools || []).findBy('id', id);

        if (pool) {
          pool.incrementQuantity(-1);
        }
      },

      scaleUpPool(id) {
        const pool = (this.nodePools || []).findBy('id', id);

        if (pool) {
          pool.incrementQuantity(1);
        }
      },

      saveAsTemplate() {
        this.modalService.toggleModal('modal-save-rke-template', {
          cluster: this
        });
      },

      runCISScan(options) {
        this.modalService.toggleModal('run-scan-modal', {
          closeWithOutsideClick: true,
          cluster: this,
          onRun: (options || {}).onRun
        });
      },

      rotateCertificates() {
        const model = this;
        this.modalService.toggleModal('modal-rotate-certificates', {
          model,
          serviceDefaults: this.globalStore.getById('schema', 'rotatecertificateinput').optionsFor('services')
        });
      },

      rotateEncryptionKey() {
        const model = this;
        this.modalService.toggleModal('modal-rotate-encryption-key', {
          model
        });
      },

      showCommandModal() {
        this.modalService.toggleModal('modal-show-command', {
          cluster: this
        });
      }

    },

    clearConfigFieldsForClusterTemplate() {
      let clearedNull = ['localClusterAuthEndpoint', 'rancherKubernetesEngineConfig', 'enableNetworkPolicy'];
      let clearedDelete = ['defaultClusterRoleForProjectMembers'];
      let {
        localClusterAuthEndpoint,
        rancherKubernetesEngineConfig,
        enableNetworkPolicy,
        defaultClusterRoleForProjectMembers
      } = this;
      let cachedConfig = {
        localClusterAuthEndpoint,
        rancherKubernetesEngineConfig,
        enableNetworkPolicy,
        defaultClusterRoleForProjectMembers
      }; // set this incase we fail to save the cluster;

      Ember.set(this, '_cachedConfig', cachedConfig);
      clearedDelete.forEach(c => delete this[c]);
      clearedNull.forEach(c => Ember.set(this, c, null));
    },

    clearProvidersExcept(keep) {
      const keys = this.allKeys().filter(x => x.endsWith('Config'));

      for (let key, i = 0; i < keys.length; i++) {
        key = keys[i];

        if (key !== keep && Ember.get(this, key)) {
          Ember.set(this, key, null);
        }
      }
    },

    delete()
    /* arguments*/
    {
      const promise = this._super.apply(this, arguments);

      return promise.then(() =>
      /* resp */
      {
        if (Ember.get(this, 'scope.currentCluster.id') === this.id) {
          this.router.transitionTo('global-admin.clusters');
        }
      });
    },

    getOrCreateToken() {
      const globalStore = this.globalStore;
      const id = this.id;
      return globalStore.findAll('clusterRegistrationToken', {
        forceReload: true
      }).then(tokens => {
        let token = tokens.filterBy('clusterId', id)[0];

        if (token) {
          return Ember.RSVP.resolve(token);
        } else {
          token = this.globalStore.createRecord({
            type: 'clusterRegistrationToken',
            clusterId: id
          });
          return token.save();
        }
      });
    },

    waitForClusterTemplateToBeAttached() {
      return this._waitForTestFn(() => {
        return this.hasClusterTemplate();
      }, `Wait for Cluster Template to be attached`);
    },

    hasClusterTemplate() {
      const {
        clusterTemplateId,
        clusterTemplateRevisionId
      } = this;

      if (Ember.isEmpty(clusterTemplateId) && Ember.isEmpty(clusterTemplateRevisionId)) {
        return false;
      }

      return true;
    },

    compareStringArrays(a, b) {
      let aStr = '';
      let bStr = '';
      Object.keys(a || {}).sort().forEach(key => {
        aStr += `${key}=${a[key]},`;
      });
      Object.keys(b || {}).sort().forEach(key => {
        bStr += `${key}=${b[key]},`;
      });
      return aStr !== bStr;
    },

    save(opt, originalModel) {
      const {
        eksConfig,
        gkeConfig,
        aksConfig
      } = this;
      let options = null;

      if (this.driver === 'EKS' || this.isObject(eksConfig) && !this.isEmptyObject(eksConfig)) {
        options = this.syncEksConfigs(opt);
      } else if (this.isObject(gkeConfig) && !this.isEmptyObject(gkeConfig)) {
        options = this.syncGkeConfigs(opt);
      } else if (this.isObject(aksConfig) && !this.isEmptyObject(aksConfig)) {
        options = this.syncAksConfigs(opt);
      }

      if (!Ember.isEmpty(options)) {
        if (originalModel && originalModel.model && originalModel.model.originalCluster) {
          // Check to see if the labels have changed and send them, if they have
          if (this.compareStringArrays(originalModel.model.originalCluster.labels, this.labels)) {
            options.data.labels = this.labels;
          } // Check to see if the annotations have changed and send them, if they have


          if (this.compareStringArrays(originalModel.model.originalCluster.annotations, this.annotations)) {
            options.data.annotations = this.annotations;
          }

          const {
            clusterAgentDeploymentCustomization = {},
            fleetAgentDeploymentCustomization = {}
          } = originalModel.model.originalCluster;
          const {
            clusterAgentDeploymentCustomization: newClusterAgentDeploymentCustomization = {},
            fleetAgentDeploymentCustomization: newFleetAgentDeploymentCustomization = {}
          } = this;

          if (JSON.stringify(clusterAgentDeploymentCustomization) !== JSON.stringify(newClusterAgentDeploymentCustomization)) {
            options.data.clusterAgentDeploymentCustomization = this.addDeletedKeysAsNull(clusterAgentDeploymentCustomization, newClusterAgentDeploymentCustomization);
          }

          if (JSON.stringify(fleetAgentDeploymentCustomization) !== JSON.stringify(newFleetAgentDeploymentCustomization)) {
            options.data.fleetAgentDeploymentCustomization = this.addDeletedKeysAsNull(fleetAgentDeploymentCustomization, newFleetAgentDeploymentCustomization);
          }
        }

        return this._super(options);
      }

      return this._super(...arguments);
    },

    /**
    * When editing EKS, GKE, AKS imported clusters, only properties that have changed are sent in the request.
    * This is part of a strategy to avoid overwriting properties that have changed in the aws/google cloud/azure console
    * Unfortunately this creates issues when editing agent config customizations: when the user clears a previously-defined field the cluster save request omits that key and the previous value is preserved
    * Sending null instead of removing the key properly overwrites the old value
    * We're adding null here instead of changing the key-removing functionality in agent config components to avoid poluting RKE1 'edit as yaml' view & save request
    */
    addDeletedKeysAsNull(original, toSave = {}) {
      Object.keys(original).forEach(key => {
        if (!toSave[key]) {
          toSave[key] = null;
        } else if (original[key] && typeof original[key] === 'object' && !Array.isArray(original[key])) {
          Ember.set(toSave, key, this.addDeletedKeysAsNull(original[key], toSave[key]));
        }
      });
      return toSave;
    },

    syncAksConfigs(opt) {
      const {
        aksConfig,
        globalStore,
        id
      } = this;
      const options = { ...opt,
        data: {
          name: this.name,
          aksConfig: {}
        }
      };
      const aksClusterConfigSpec = globalStore.getById('schema', 'aksclusterconfigspec');
      const aksNodePoolConfigSpec = globalStore.getById('schema', 'aksnodepool');

      if (Ember.isEmpty(id)) {
        this.sanitizeConfigs(aksConfig, aksClusterConfigSpec, aksNodePoolConfigSpec, 'nodePools');

        if (!Ember.get(this, 'aksConfig.imported') && this.name !== Ember.get(this, 'aksConfig.clusterName')) {
          Ember.set(this, 'aksConfig.clusterName', this.name);
        }

        return;
      } else {
        const config = _jsondiffpatch.default.clone(aksConfig);

        const upstreamSpec = _jsondiffpatch.default.clone(Ember.get(this, 'aksStatus.upstreamSpec'));

        if (Ember.isEmpty(upstreamSpec)) {
          this.sanitizeConfigs(aksConfig, aksClusterConfigSpec, aksNodePoolConfigSpec, 'nodePools');
          return;
        }

        Ember.set(options, 'data.aksConfig', this.diffUpstreamSpec(upstreamSpec, config));

        if (!Ember.isEmpty(Ember.get(options, 'data.aksConfig.nodePools'))) {
          Ember.get(options, 'data.aksConfig.nodePools').forEach(np => {
            this.replaceNullWithEmptyDefaults(np, Ember.get(aksNodePoolConfigSpec, 'resourceFields'));
          });
        }

        if (Ember.get(options, 'qp._replace')) {
          delete options.qp['_replace'];
        }

        return options;
      }
    },

    syncGkeConfigs(opt) {
      const {
        gkeConfig,
        globalStore,
        id
      } = this;
      const options = { ...opt,
        data: {
          name: this.name,
          gkeConfig: {}
        }
      };
      const gkeClusterConfigSpec = globalStore.getById('schema', 'gkeclusterconfigspec');
      const gkeNodePoolConfigSpec = globalStore.getById('schema', 'gkenodepoolconfig'); // will be gkeNodeConfig

      if (Ember.isEmpty(id)) {
        this.sanitizeConfigs(gkeConfig, gkeClusterConfigSpec, gkeNodePoolConfigSpec, 'nodePools');

        if (!Ember.get(this, 'gkeConfig.imported') && this.name !== Ember.get(this, 'gkeConfig.clusterName')) {
          Ember.set(this, 'gkeConfig.clusterName', this.name);
        }

        return;
      } else {
        const config = _jsondiffpatch.default.clone(gkeConfig);

        const upstreamSpec = _jsondiffpatch.default.clone(Ember.get(this, 'gkeStatus.upstreamSpec'));

        if (Ember.isEmpty(upstreamSpec)) {
          this.sanitizeConfigs(gkeConfig, gkeClusterConfigSpec, gkeNodePoolConfigSpec, 'nodePools');
          return;
        }

        Ember.set(options, 'data.gkeConfig', this.diffUpstreamSpec(upstreamSpec, config));

        if (!Ember.isEmpty(Ember.get(options, 'data.gkeConfig.nodePools'))) {
          Ember.get(options, 'data.gkeConfig.nodePools').forEach(ng => {
            this.replaceNullWithEmptyDefaults(ng, Ember.get(gkeNodePoolConfigSpec, 'resourceFields'));
          });
        }

        if (Ember.get(options, 'qp._replace')) {
          delete options.qp['_replace'];
        }

        return options;
      }
    },

    syncEksConfigs(opt) {
      const {
        eksConfig,
        globalStore
      } = this;
      const options = { ...opt,
        data: {
          name: this.name,
          eksConfig: {}
        }
      };
      const eksClusterConfigSpec = globalStore.getById('schema', 'eksclusterconfigspec');
      const nodeGroupConfigSpec = globalStore.getById('schema', 'nodegroup');

      if (Ember.isEmpty(this.id)) {
        this.sanitizeConfigs(eksConfig, eksClusterConfigSpec, nodeGroupConfigSpec);

        if (!Ember.get(this, 'eksConfig.imported') && this.name !== Ember.get(this, 'eksConfig.displayName')) {
          Ember.set(this, 'eksConfig.displayName', this.name);
        }

        return;
      } else {
        const config = _jsondiffpatch.default.clone(this.eksConfig);

        const upstreamSpec = _jsondiffpatch.default.clone(Ember.get(this, 'eksStatus.upstreamSpec'));

        if (Ember.isEmpty(upstreamSpec)) {
          this.sanitizeConfigs(eksConfig, eksClusterConfigSpec, nodeGroupConfigSpec);
          return;
        }

        Ember.set(options, 'data.eksConfig', this.diffUpstreamSpec(upstreamSpec, config));

        if (!Ember.isEmpty(Ember.get(options, 'data.eksConfig.nodeGroups'))) {
          Ember.get(options, 'data.eksConfig.nodeGroups').forEach(ng => {
            this.replaceNullWithEmptyDefaults(ng, Ember.get(nodeGroupConfigSpec, 'resourceFields'));
          });
        }

        if (Ember.get(options, 'qp._replace')) {
          delete options.qp['_replace'];
        }

        return options;
      }
    },

    sanitizeConfigs(currentConfig, clusterConfigSpec, nodeGroupConfigSpec, nodeType = 'nodeGroups') {
      this.replaceNullWithEmptyDefaults(currentConfig, Ember.get(clusterConfigSpec, 'resourceFields'));

      if (!Ember.isEmpty(Ember.get(currentConfig, nodeType))) {
        Ember.get(currentConfig, nodeType).forEach(ng => {
          this.replaceNullWithEmptyDefaults(ng, Ember.get(nodeGroupConfigSpec, 'resourceFields'));
        });
      }
    },

    replaceNullWithEmptyDefaults(config, resourceFields) {
      const {
        clusterProvider
      } = this;
      Object.keys(config).forEach(ck => {
        const configValue = Ember.get(config, ck);

        if (configValue === null || typeof configValue === 'undefined') {
          const resourceField = resourceFields[ck];

          if (resourceField.type === 'string') {
            Ember.set(config, ck, '');
          } else if (resourceField.type.includes('array')) {
            Ember.set(config, ck, []);
          } else if (resourceField.type.includes('map')) {
            Ember.set(config, ck, {});
          } else if (resourceField.type.includes('boolean')) {
            if (resourceField.default) {
              Ember.set(config, ck, resourceField.default);
            } else {
              // we shouldn't get here, there are not that many fields in EKS and I've set the defaults for bools that are there
              // but if we do hit this branch my some magic case imo a bool isn't something we can default cause its unknown...just dont do anything.
              if (clusterProvider === 'amazoneksv2') {
                if (!Ember.isEmpty(Ember.get(DEFAULT_EKS_CONFIG, ck)) || !Ember.isEmpty(Ember.get(DEFAULT_NODE_GROUP_CONFIG, ck))) {
                  let match = Ember.isEmpty(Ember.get(DEFAULT_EKS_CONFIG, ck)) ? Ember.get(DEFAULT_NODE_GROUP_CONFIG, ck) : Ember.get(DEFAULT_EKS_CONFIG, ck);
                  Ember.set(config, ck, match);
                }
              } else if (clusterProvider === 'googlegkev2') {
                if (!Ember.isEmpty(Ember.get(DEFAULT_GKE_CONFIG, ck)) || !Ember.isEmpty(Ember.get(DEFAULT_GKE_NODE_POOL_CONFIG, ck))) {
                  let match = Ember.isEmpty(Ember.get(DEFAULT_GKE_CONFIG, ck)) ? Ember.get(DEFAULT_GKE_NODE_POOL_CONFIG, ck) : Ember.get(DEFAULT_GKE_CONFIG, ck);
                  Ember.set(config, ck, match);
                }
              } else if (clusterProvider === 'azureaksv2') {
                if (!Ember.isEmpty(Ember.get(DEFAULT_AKS_CONFIG, ck)) || !Ember.isEmpty(Ember.get(DEFAULT_AKS_NODE_POOL_CONFIG, ck))) {
                  let match = Ember.isEmpty(Ember.get(DEFAULT_AKS_CONFIG, ck)) ? Ember.get(DEFAULT_AKS_NODE_POOL_CONFIG, ck) : Ember.get(DEFAULT_AKS_CONFIG, ck);
                  Ember.set(config, ck, match);
                }
              }
            }
          }
        }
      });
    },

    diffUpstreamSpec(lhs, rhs) {
      // this is NOT a generic object diff.
      // It tries to be as generic as possible but it does make certain assumptions regarding nulls and emtpy arrays/objects
      // if LHS (upstream) is null and RHS (eks config) is empty we do not count this as a change
      // additionally null values on the RHS will be ignored as null cant be sent in this case
      const delta = {};
      const rhsKeys = Object.keys(rhs);
      rhsKeys.forEach(k => {
        if (k === 'type') {
          return;
        }

        const lhsMatch = Ember.get(lhs, k);
        const rhsMatch = Ember.get(rhs, k);

        if (k !== 'nodeGroups' && k !== 'nodePools') {
          try {
            if (Ember.isEqual(JSON.stringify(lhsMatch), JSON.stringify(rhsMatch))) {
              return;
            }
          } catch (e) {}
        }

        if (k === 'nodeGroups' || k === 'nodePools' || k === 'tags' || k === 'labels') {
          // Node Groups and Node Pools do not require a sync, we can safely send the entire object
          // Tags and Labels (maps) are also included by default because what is present in the config is exactly what should be used on save and any equal maps would have been caught by the JSON isEqual comparison above
          if (!Ember.isEmpty(rhsMatch)) {
            // node groups need ALL data so short circut and send it all
            Ember.set(delta, k, rhsMatch);
          } else {
            // all node groups were deleted
            Ember.set(delta, k, []);
          }

          return;
        }

        if (Ember.isEmpty(lhsMatch) || this.isEmptyObject(lhsMatch)) {
          if (Ember.isEmpty(rhsMatch) || this.isEmptyObject(rhsMatch)) {
            if (lhsMatch !== null && (Ember.isArray(rhsMatch) || this.isObject(rhsMatch))) {
              // Empty Arrays and Empty Maps must be sent as such unless the upstream value is null, then the empty array or obj is just a init value from ember
              Ember.set(delta, k, rhsMatch);
            }

            return;
          } else {
            // lhs is empty, rhs is not, just set
            Ember.set(delta, k, rhsMatch);
          }
        } else {
          if (rhsMatch !== null) {
            // entry in og obj
            if (Ember.isArray(lhsMatch)) {
              if (Ember.isArray(rhsMatch)) {
                if (!Ember.isEmpty(rhsMatch) && rhsMatch.every(m => this.isObject(m))) {
                  // You have more diffing to do
                  rhsMatch.forEach(match => {
                    // our most likely candiate for a match is node group name, but lets check the others just incase.
                    const matchId = Ember.get(match, 'name') || Ember.get(match, 'id') || false;

                    if (matchId) {
                      let lmatchIdx; // we have soime kind of identifier to find a match in the upstream, so we can diff and insert to new array

                      const lMatch = lhsMatch.find((l, idx) => {
                        const lmatchId = Ember.get(l, 'name') || Ember.get(l, 'id');

                        if (lmatchId === matchId) {
                          lmatchIdx = idx;
                          return l;
                        }
                      });

                      if (lMatch) {
                        // we have a match in the upstream, meaning we've probably made updates to the object itself
                        const diffedMatch = this.diffUpstreamSpec(lMatch, match);

                        if (!Ember.isArray(Ember.get(delta, k))) {
                          Ember.set(delta, k, [diffedMatch]);
                        } else {
                          // diff and push into new array
                          delta[k].insertAt(lmatchIdx, diffedMatch);
                        }
                      } else {
                        // no match in upstream, new entry
                        if (!Ember.isArray(Ember.get(delta, k))) {
                          Ember.set(delta, k, [match]);
                        } else {
                          delta[k].pushObject(match);
                        }
                      }
                    } else {
                      // no match id, all we can do is dumb add
                      if (!Ember.isArray(Ember.get(delta, k))) {
                        Ember.set(delta, k, [match]);
                      } else {
                        delta[k].pushObject(match);
                      }
                    }
                  });
                } else {
                  Ember.set(delta, k, rhsMatch);
                }
              } else {
                Ember.set(delta, k, rhsMatch);
              }
            } else if (this.isObject(lhsMatch)) {
              if (!Ember.isEmpty(rhsMatch) && !this.isEmptyObject(rhsMatch)) {
                if ((Object.keys(lhsMatch) || []).length > 0) {
                  // You have more diffing to do
                  Ember.set(delta, k, this.diffUpstreamSpec(lhsMatch, rhsMatch));
                } else if (this.isEmptyObject(lhsMatch)) {
                  // we had a map now we have an empty map
                  Ember.set(delta, k, {});
                }
              } else if (!this.isEmptyObject(lhsMatch) && this.isEmptyObject(rhsMatch)) {
                // we had a map now we have an empty map
                Ember.set(delta, k, {});
              }
            } else {
              // lhsMatch not an array or object
              Ember.set(delta, k, rhsMatch);
            }
          }
        }
      });
      return delta;
    },

    /**
     * True if obj is a plain object, an instantiated function/class
     * @param {anything} obj
     */
    isObject(obj) {
      return obj // Eliminates null/undefined
      && obj instanceof Object // Eliminates primitives
      && typeof obj === 'object' // Eliminates class definitions/functions
      && !Array.isArray(obj); // Eliminates arrays
    },

    isEmptyObject(obj) {
      return this.isObject(obj) && Object.keys(obj).length === 0;
    }

  });

  _exports.default = _default;
});