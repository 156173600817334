define("ui/components/container/form-volume-row/component", ["exports", "ui/components/container/form-volume-row/template", "ui/components/container/form-volumes/component"], function (_exports, _template, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    modalService: Ember.inject.service('modal'),
    layout: _template.default,
    tagName: '',
    editing: true,
    pvcs: null,
    workload: null,

    init() {
      this._super(...arguments);

      Ember.set(this, 'pvcs', this.store.all('persistentVolumeClaim'));
    },

    didReceiveAttrs() {
      const mode = Ember.get(this, 'model.mode');

      if (mode === _component.NEW_VOLUME) {
        Ember.run.next(() => {
          this.send('defineNewVolume');
        });
      } else if (mode === _component.NEW_VCT) {
        Ember.run.next(() => {
          this.send('defineNewVct');
        });
      } else if (mode === _component.NEW_PVC) {
        Ember.run.next(() => {
          this.send('defineNewPvc');
        });
      }
    },

    actions: {
      defineNewVolume() {
        this.modalService.toggleModal('modal-new-volume', {
          model: Ember.get(this, 'model.volume').clone(),
          callback: volume => {
            Ember.set(this, 'model.volume', volume);
          }
        });
      },

      defineNewPvc() {
        this.modalService.toggleModal('modal-new-pvc', {
          model: Ember.get(this, 'model.pvc'),
          namespace: this.namespace,
          callback: pvc => {
            Ember.set(this, 'model.pvc', pvc);

            if (!Ember.get(this, 'model.volume.name')) {
              Ember.set(this, 'model.volume.name', Ember.get(pvc, 'name'));
            }
          }
        });
      },

      defineNewVct() {
        const {
          modalService
        } = this;
        modalService.toggleModal('modal-new-vct', {
          model: Ember.get(this, 'model.vct'),
          namespace: this.namespace,
          callback: vct => {
            Ember.set(this, 'model.vct', vct);

            if (!Ember.get(this, 'model.name')) {
              Ember.set(this, 'model.name', Ember.get(vct, 'name'));
            }
          }
        });
      },

      remove() {
        if (this.remove) {
          this.remove(this.model);
        }
      },

      addMount() {
        const mount = this.store.createRecord({
          type: 'volumeMount'
        });
        Ember.get(this, 'model.mounts').pushObject(mount);
      },

      removeMount(mount) {
        Ember.get(this, 'model.mounts').removeObject(mount);
      }

    },
    pvcChoices: Ember.computed('pvcs.@each.{name,state}', 'namespace.id', function () {
      return this.pvcs.filterBy('namespaceId', Ember.get(this, 'namespace.id')).map(v => {
        let label = Ember.get(v, 'displayName');
        const state = Ember.get(v, 'state');
        const disabled = false;

        if (disabled) {
          label += ` (${state})`;
        }

        return {
          label,
          disabled,
          value: Ember.get(v, 'id')
        };
      }).sortBy('label');
    })
  });

  _exports.default = _default;
});