define("ui/authenticated/cluster/cis/scan/detail/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    scope: Ember.inject.service(),

    model(params) {
      const scan = this.globalStore.find('clusterScan', params.scan_id);

      const report = (async () => {
        return (await scan).loadReport('report');
      })();

      return Ember.RSVP.hash({
        clusterScans: this.globalStore.findAll('clusterScan'),
        scan,
        report,
        nodes: Ember.get(this, 'scope.currentCluster.nodes')
      });
    }

  });

  _exports.default = _default;
});