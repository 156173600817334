define("ui/authenticated/project/registries/detail/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      const all = this.modelFor('authenticated.project.registries');
      let registry = all.projectDockerCredentials.findBy('id', params.registry_id);

      if (registry) {
        return registry;
      }

      registry = all.namespacedDockerCredentials.findBy('id', params.registry_id);

      if (registry) {
        return registry;
      }

      return this.store.find('dockerCredential', params.registry_id);
    }

  });

  _exports.default = _default;
});