define("ui/components/new-catalog/component", ["exports", "shared/mixins/new-or-edit", "ui/utils/constants", "ui/utils/util", "ember-concurrency", "yamljs", "ui/components/new-catalog/template", "shared/mixins/catalog-app", "shared/utils/util", "shared/utils/convert-yaml", "shared/mixins/child-hook", "shared/utils/flat-map", "shared/mixins/lazy-icon"], function (_exports, _newOrEdit, _constants, _util, _emberConcurrency, _yamljs, _template, _catalogApp, _util2, _convertYaml, _childHook, _flatMap, _lazyIcon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_newOrEdit.default, _catalogApp.default, _childHook.default, _lazyIcon.default, {
    catalog: Ember.inject.service(),
    intl: Ember.inject.service(),
    scope: Ember.inject.service(),
    router: Ember.inject.service(),
    settings: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    layout: _template.default,
    namespaceErrors: null,
    templateResource: null,
    namespaceResource: null,
    versionsArray: null,
    versionsLinks: null,
    namespaces: null,
    actuallySave: true,
    showHeader: true,
    showPreview: true,
    customizeNamespace: false,
    decoding: false,
    forceUpgrade: false,
    istio: false,
    titleAdd: 'newCatalog.titleAdd',
    titleUpgrade: 'newCatalog.titleUpgrade',
    selectVersionAdd: 'newCatalog.selectVersionAdd',
    selectVersionUpgrade: 'newCatalog.selectVersionUpgrade',
    saveUpgrade: 'newCatalog.saveUpgrade',
    saveNew: 'newCatalog.saveNew',
    sectionClass: 'box mb-20',
    showDefaultVersionOption: false,
    classNames: ['launch-catalog'],
    catalogApp: null,
    detailExpanded: false,
    previewOpen: false,
    previewTab: null,
    questionsArray: null,
    selectedTemplateUrl: null,
    selectedTemplateModel: null,
    catalogTemplate: null,
    readmeContent: null,
    appReadmeContent: null,
    pastedAnswers: null,
    noAppReadme: null,
    selectedFileContetnt: null,
    editable: {
      selectedTemplateUrl: null
    },
    isGKE: Ember.computed.alias('scope.currentCluster.isGKE'),
    primaryResource: Ember.computed.alias('namespaceResource'),
    editing: Ember.computed.notEmpty('catalogApp.id'),
    requiredNamespace: Ember.computed.alias('selectedTemplateModel.requiredNamespace'),

    init() {
      this._super(...arguments);

      Ember.set(this, 'selectedTemplateModel', null);
      Ember.run.scheduleOnce('afterRender', this, 'setupComponent');
    },

    didRender() {
      this.initAppIcon();
    },

    actions: {
      toogleDetailedDescriptions() {
        Ember.set(this, 'detailExpanded', true);
      },

      toogleNamespace() {
        Ember.set(this, 'customizeNamespace', true);
      },

      cancel() {
        if (this.istio) {
          const projectId = Ember.get(this, 'scope.currentProject.id');
          this.router.transitionTo('authenticated.project.istio.project-istio.rules', projectId);
        } else if (this.cancel) {
          this.cancel();
        }
      },

      togglePreview() {
        this.toggleProperty('previewOpen');
      },

      selectPreviewTab(tab) {
        Ember.set(this, 'previewTab', tab);
      }

    },
    answersArray: Ember.computed('selectedTemplateModel.questions', 'selectedTemplateModel.customAnswers', 'catalogApp.answers', function () {
      let model = this.selectedTemplateModel;

      if (Ember.get(model, 'questions')) {
        const questions = [];
        (Ember.get(this, 'selectedTemplateModel.questions') || []).forEach(q => {
          questions.push(q);
          const subquestions = Ember.get(q, 'subquestions');

          if (subquestions) {
            questions.pushObjects(subquestions);
          }
        });
        const customAnswers = Ember.get(this, 'selectedTemplateModel.customAnswers') || {};
        Object.keys(customAnswers).forEach(key => {
          questions.push({
            variable: key,
            answer: customAnswers[key]
          });
        });
        return questions;
      } else {
        return Ember.get(this, 'catalogApp.answers');
      }
    }),
    answersString: Ember.computed('answersArray.@each.{variable,answer}', 'selectedTemplateModel.valuesYaml', function () {
      let model = this.selectedTemplateModel;

      if (Ember.get(model, 'questions')) {
        let neu = {};

        if (model.valuesYaml && model.valuesYaml.length > 0) {
          neu = _yamljs.default.parse(model.valuesYaml);
          neu = (0, _flatMap.default)(neu);
        } else {
          (this.answersArray || []).forEach(a => {
            neu[a.variable] = Ember.isEmpty(a.answer) ? a.default : a.answer;
          });
        }

        const customAnswers = Ember.get(model, 'customAnswers') || {};
        Object.keys(customAnswers).forEach(key => {
          neu[key] = customAnswers[key];
        });
        return _yamljs.default.stringify(neu);
      } else {
        return JSON.stringify(this.answersArray);
      }
    }),
    getTemplate: (0, _emberConcurrency.task)(function* () {
      var url = Ember.get(this, 'editable.selectedTemplateUrl');

      if (url === 'default') {
        let defaultUrl = this.defaultUrl;

        if (defaultUrl) {
          url = defaultUrl;
        } else {
          url = null;
        }
      }

      if (url) {
        var version = Ember.get(this, 'settings.rancherVersion');

        if (version) {
          url = _util.default.addQueryParam(url, 'rancherVersion', version);
        }

        var current = Ember.get(this, 'catalogApp.answers');

        if (!current) {
          current = {};
          Ember.set(this, 'catalogApp.answers', current);
        }

        var selectedTemplateModel = yield this.catalog.fetchByUrl(url).then(response => {
          if (response.questions) {
            this.parseQuestionsAndAnswers(response, current);
          }

          return response;
        });

        if (selectedTemplateModel && selectedTemplateModel.requiredNamespace) {
          Ember.set(this, 'primaryResource.name', selectedTemplateModel.requiredNamespace);
        }

        Ember.set(this, 'selectedTemplateModel', selectedTemplateModel);
        this.initPreviewTab(selectedTemplateModel);
      } else {
        Ember.setProperties(this, {
          selectedTemplateModel: null,
          readmeContent: null,
          appReadmeContent: null,
          noAppReadme: false
        });
      }

      this.updateReadme();
    }),

    initTemplateModel(templateModel) {
      let currentAnswers = Ember.get(this, 'catalogApp.answers') || {};
      this.parseQuestionsAndAnswers(templateModel, currentAnswers);
      this.initPreviewTab(templateModel);
      Ember.set(this, 'selectedTemplateModel', templateModel);
      this.updateReadme();
    },

    initPreviewTab(selectedTemplateModel) {
      const files = Object.keys(selectedTemplateModel.get('files')) || [];

      if (files.length > 0) {
        const valuesYaml = files.find(file => file.endsWith('/values.yaml'));
        Ember.set(this, 'previewTab', valuesYaml ? valuesYaml : files[0]);
      }
    },

    parseQuestionsAndAnswers(template, currentAnswers) {
      const questions = [];
      const customAnswers = {};
      (template.questions || []).forEach(q => {
        questions.push(q);
        const subquestions = Ember.get(q, 'subquestions');

        if (subquestions) {
          questions.pushObjects(subquestions);
        }
      });
      questions.forEach(item => {
        // This will be the component that is rendered to edit this answer
        item.inputComponent = `schema/input-${item.type}`; // Only types marked supported will show the component, Ember will explode if the component doesn't exist

        item.supported = _constants.default.SUPPORTED_SCHEMA_INPUTS.indexOf(item.type) >= 0;

        if (typeof currentAnswers[item.variable] !== 'undefined') {
          // If there's an existing value, use it (for upgrade)
          item.answer = currentAnswers[item.variable];
        } else if (item.type === 'service' || item.type === 'certificate') {// Loaded async and then the component picks the default
        } else if (item.type === 'boolean') {
          // Coerce booleans
          item.answer = item.default === 'true' || item.default === true;
        } else {
          // Everything else
          item.answer = item.default || null;
        }
      });
      Object.keys(currentAnswers).forEach(key => {
        const q = questions.findBy('variable', key);

        if (!q) {
          customAnswers[key] = currentAnswers[key];
        }
      });
      Ember.set(template, 'customAnswers', customAnswers);
    },

    validate() {
      this._super();

      const errors = this.errors || [];
      errors.pushObjects(this.namespaceErrors || []);
      errors.pushObjects(this.selectedTemplateModel.validationErrors(this.answers) || []);

      if (errors.length) {
        Ember.set(this, 'errors', errors.uniq());
        return false;
      }

      return true;
    },

    doSave() {
      const requiredNamespace = this.requiredNamespace;

      if (requiredNamespace && (this.namespaces || []).findBy('id', requiredNamespace)) {
        return Ember.RSVP.resolve(this.primaryResource);
      }

      return this._super(...arguments);
    },

    willSave() {
      Ember.set(this, 'errors', null);
      var ok = this.validate();

      if (!ok) {
        // Validation failed
        return false;
      }

      if (this.actuallySave) {
        if (!Ember.get(this, 'selectedTemplateModel.valuesYaml') && Ember.get(this, 'selectedTemplateModel.questions')) {
          Ember.set(this.catalogApp, 'answers', this.answers);
        }

        return this.applyHooks('_beforeSaveHooks').catch(err => {
          Ember.set(this, 'errors', [err.message]);
          return false;
        });
      } else {
        // TODO 2.0 this is part of the volumes stuff so we need to investigate if this still works
        // let versionId = null;
        // if ( get(this, 'selectedTemplateUrl') !== 'default' && get(this, 'selectedTemplateModel') ) {
        //   versionId = get(this, 'selectedTemplateModel.id');
        // }
        // if (this.doSave) {
        //   this.doSave({
        //     answers:           get(this, 'answers'),
        //     externalId:        get(this, 'newExternalId'),
        //     templateId:        get(this, 'templateResource.id'),
        //     templateVersionId: versionId,
        //   });
        // }
        return false;
      }
    },

    didSave(neu) {
      let app = this.catalogApp;
      let yaml = Ember.get(this, 'selectedTemplateModel.valuesYaml');

      if (!yaml && this.shouldFallBackToYaml()) {
        const questions = Ember.get(this, 'selectedTemplateModel.allQuestions') || [];
        const input = {};
        questions.forEach(q => {
          if (q.answer !== undefined && q.answer !== null) {
            input[q.variable] = q.answer;
          } else if (q.default !== undefined && q.default !== null) {
            input[q.variable] = q.default;
          } else {
            input[q.variable] = '';
          }
        });
        yaml = (0, _convertYaml.default)(input);
      }

      if (Ember.get(app, 'id')) {
        return app.doAction('upgrade', {
          externalId: Ember.get(this, 'selectedTemplateModel.externalId'),
          answers: yaml ? {} : Ember.get(app, 'answers'),
          valuesYaml: yaml ? yaml : '',
          forceUpgrade: this.forceUpgrade
        }).then(resp => resp).catch(err => err);
      } else {
        const requiredNamespace = this.requiredNamespace;
        Ember.setProperties(app, {
          targetNamespace: requiredNamespace ? requiredNamespace : neu.name,
          externalId: Ember.get(this, 'selectedTemplateModel.externalId'),
          projectId: Ember.get(neu, 'projectId'),
          answers: yaml ? {} : Ember.get(app, 'answers'),
          valuesYaml: yaml ? yaml : ''
        });
        return app.save().then(() => this.primaryResource);
      }
    },

    doneSaving() {
      var projectId = Ember.get(this, 'scope.currentProject.id');

      if (this.istio) {
        return this.router.transitionTo('authenticated.project.istio.project-istio.rules', projectId);
      } else {
        return this.router.transitionTo('apps-tab.index', projectId);
      }
    },

    shouldFallBackToYaml() {
      const questions = Ember.get(this, 'selectedTemplateModel.allQuestions') || [];
      return !!questions.some(question => Ember.get(question, 'type') === 'password' && !!(0, _util2.isNumeric)(Ember.get(question, 'answer')) && Ember.get(question, 'answer') !== '');
    },

    setupComponent() {
      if (this.selectedTemplateUrl) {
        if (this.catalogTemplate) {
          this.initTemplateModel(this.catalogTemplate);
        } else {
          this.templateChanged();
        }
      } else {
        var def = Ember.get(this, 'templateResource.defaultVersion');
        var links = this.versionLinks;
        var app = this.catalogApp;

        if (Ember.get(app, 'id') && !this.upgrade) {
          def = Ember.get(app, 'externalIdInfo.version');
        }

        if (links[def]) {
          Ember.set(this, 'selectedTemplateUrl', links[def]);
        } else {
          Ember.set(this, 'selectedTemplateUrl', null);
        }
      }

      Ember.set(this, 'editable.selectedTemplateUrl', this.selectedTemplateUrl);
    }

  });

  _exports.default = _default;
});